import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ModalsActions from '../../../../models/Modals/Modals.actions';
import GroupMembersComponent from './GroupMembers.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      closeAllModals: ModalsActions.closeAllModals,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(GroupMembersComponent);
