import { PREVIEW } from '../Player/Player.constants';
import * as App from './App.constants';

/*
 * Initial State.
 */
const initialState = {
  sizes: {
    width: null,
    height: null,
  },
  playerOpen: false,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case App.RESIZE:
      return {
        ...state,
        ...action.payload,
      };

    case App.PLAYER_OPEN:
    case PREVIEW:
      return {
        ...state,
        playerOpen: true,
      };

    case App.PLAYER_CLOSE:
      return {
        ...state,
        playerOpen: false,
      };

    default:
      return state;
  }
};

export default Reducer;
