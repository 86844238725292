import React, { Component, Fragment } from 'react';
import Player from './../Main';
import OpenPlayerButton from './../Open';
import Listener from './../Listener';

class Indicator extends Component {
  render() {
    const { playerOpen, logged } = this.props;

    if (!logged) {
      return null;
    }

    return (
      <Fragment>
        <Player visible={playerOpen} />
        <OpenPlayerButton visible={!playerOpen} />
        <Listener />
      </Fragment>
    );
  }
}

export default Indicator;
