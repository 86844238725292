import { connect } from 'react-redux';
import * as FiltersActions from '../../../models/Filters/Filters.actions';
import { bindActionCreators } from 'redux';
import { openModal } from '../../../models/Modals/Modals.actions';
import NoGroupsComponent from './NoGroups.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      resetFilters: FiltersActions.resetFilters,
      openModal,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(NoGroupsComponent);
