import { createSelector } from 'reselect';
import { USER } from './Filters.constants';

const getSelected = (state) => state.Filters.selected;

export const getSelectedEmail = createSelector(
  [getSelected],
  (selected) => {
    const filter = selected.find((filter) => filter.type === USER);

    if (filter) {
      return filter.value;
    }

    return null;
  }
);
