import React from 'react';
import { TAG, PHRASE, USER, HOST } from '../../../models/Filters/Filters.constants';
import styles from './List.module.scss';

/*
 * Prefixs
 */
const b = {
  [TAG]: '#',
  [PHRASE]: '/',
  [USER]: '@: ',
  [HOST]: '://',
};

/*
 * Postfixs
 */
const e = {
  [TAG]: '',
  [PHRASE]: '/',
  [USER]: '',
  [HOST]: '',
};

const List = ({ filters, group }) => {
  const dom = [];

  if (group) {
    dom.push(
      <div key="group" className={styles.group}>
        {`<${group.name}>`}
      </div>
    );
  }

  filters.forEach((f) => {
    dom.push(
      <div key={`${f.type}-${f.value}`} className={styles[f.type]}>
        {b[f.type]}
        {typeof f.value === 'object' ? f.value.name : f.value}
        {e[f.type]}
      </div>
    );
  });

  return dom;
};

export default List;
