/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import settings from '../../../config/settings';

/**
 * Private Route Component.
 */
const PrivateRoute = ({ component: Component, isAuthenticated, redirect, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={redirect || settings.auth.loginRoute} />
      )
    }
  />
);

export default PrivateRoute;
