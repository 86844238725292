import React from 'react';
import styles from './NewFeature.module.scss';

const NewFeature = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.feature}>
        <h2>
          <span>Deck - new feature!</span>
        </h2>
        <p>
          We have pleasure to announce that <strong>AS</strong> has new feature. In{' '}
          <strong>Deck</strong> you can list selected groups. It should make that browsing links
          will be much easier.
        </p>
        <p>
          Now you don't have any group selected. So, open <strong>settings</strong> by clicking on
          tool icon on left side and choose group you want to follow in Deck.
        </p>
      </div>
    </div>
  );
};

export default NewFeature;
