import React, { Fragment } from 'react';
import Moment from 'react-moment';
import Menu, { Button, Content } from '../../template/Menu';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Group.module.scss';

/**
 * Group Component.
 */
const Group = ({
  data,
  openedGroupId,
  ownerId,
  handleAddMembers,
  handleEditName,
  handleShowMembers,
  handleDelete,
  handleLeave,
}) => {
  return (
    <div className={styles.group}>
      <h3>
        <Link to={`/dashboard/grouped/${data.id}`}>{data.name}</Link>
      </h3>
      {data.users.length > 0 && (
        <div className={cn(styles.users, openedGroupId === data.id && styles.hidden)}>
          {data.users.map((user) => (
            <div key={user.id} className={styles.user}>
              <img
                src={`https://www.gravatar.com/avatar/${user.email_hash}?d=identicon`}
                alt={user.full_name}
              />
              <div className={styles.userName}>{user.full_name}</div>
            </div>
          ))}

          <button className={styles.more} onClick={() => handleShowMembers(data.id)}>
            {data.user_counter}
          </button>
        </div>
      )}

      <div className={styles.info}>
        <div className={styles.left}>
          <div className={styles.menuWrapper}>
            <Menu>
              <Button className={styles.button}>
                <div className={styles.hamburger} />
              </Button>
              <Content className={styles.menu}>
                <ul>
                  {ownerId === data.user_id && (
                    <Fragment>
                      <li>
                        <button onClick={(event) => handleDelete(event, data.id)}>Delete</button>
                      </li>
                      <li>
                        <button onClick={() => handleAddMembers(data.id)}>Add members</button>
                      </li>
                      <li>
                        <button onClick={() => handleEditName(data.id)}>Edit</button>
                      </li>
                    </Fragment>
                  )}
                  {ownerId !== data.user_id && (
                    <li>
                      <button onClick={(event) => handleLeave(event, data.id)}>Leave group</button>
                    </li>
                  )}
                </ul>
              </Content>
            </Menu>
          </div>

          {data.users.length < 2 && (
            <div className={styles.noUsers}>
              <div>Only you are here</div>
              <div>
                <button
                  onClick={() => {
                    handleAddMembers(data.id);
                  }}
                >
                  Add members
                </button>
              </div>
            </div>
          )}
        </div>

        <div className={styles.right}>
          <div>
            Member since{' '}
            <strong>
              <Moment fromNow ago>
                {data.relation.created_at}
              </Moment>
            </strong>
          </div>
          <div>
            Links <strong>{data.link_counter}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Group;
