import epic from '../../abstracts/Epic';
import config from '../../config/config';
import qs from 'query-string';
import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { concat, of, race } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import getHeaders from '../../globals/helpers/headers';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';
import settings from '../../config/settings';
import { closeAllModals } from '../Modals/Modals.actions';
import { addAsSuccess } from '../Alert/Alert.actions';
import * as Adding from './Adding.routines';

/**
 * Get snap Epic.
 */
export const getSnap = (action$, state$) => {
  const routine = Adding.getSnap;
  const loading = !state$.value.Adding.loading;

  const timeout = settings.api.fetchTimeout;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            method: 'GET',
            timeout,
            url: `${config.apiHost}snap/fetch?link=${encodeURIComponent(action.payload.link)}`,
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine, timeout)
        )
      );
    })
  );
};

/**
 *  Add link Epic.
 */
export const addLink = epic(Adding.addLink, `${config.apiHost}add`, true, 'Adding');

/**
 *  Get details Epic.
 */
export const getDetails = epic(
  Adding.getDetails,
  (action) => `${config.apiHost}links/details?${qs.stringify(action.payload)}`,
  false
);

export const bulkAdding = (action$, state$) => {
  const routine = Adding.bulkAdding;
  const loading = !state$.value.Adding.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        ajax({
          method: 'POST',
          url: `${config.apiHost}waitings/bulk_adding`,
          headers: getHeaders(),
          body: qs.stringify(action.payload),
        }).pipe(
          mergeMap(
            whenSuccess(
              routine,
              action,
              of(closeAllModals()),
              of(addAsSuccess('Correct data has been saved in Waitings.'))
            )
          ),
          catchError(whenFailure(routine))
        )
      );
    })
  );
};
