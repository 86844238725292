import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../models/User/User.actions';
import { bindActionCreators } from 'redux';
import LogoutComponent from './Logout.component';

/**
 * Logout Container.
 */
const Logout = ({ reduxActions: { logout: action }, ...rest }) => (
  <LogoutComponent handleClick={action} {...rest} />
);

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      logout,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(Logout);
