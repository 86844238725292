import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal } from '../../../models/Modals/Modals.actions';
import * as App from '../../../models/App/App.constants';
import MainMenuComponent from './MainMenu.component';

/**
 * Main Menu Container.
 */
const MainMenu = ({ sizes, reduxActions, ...rest }) => {
  return (
    <MainMenuComponent
      {...rest}
      small={sizes.height === App.SMALL}
      openModal={reduxActions.openModal}
    />
  );
};

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  sizes: state.App.sizes,
  statistics: state.Statistics,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      openModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);
