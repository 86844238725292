import React from 'react';
import { Modal, Title, Body } from '../../Modal';
import cn from 'classnames';
import FieldError from '../../../errors/Field';
import styles from '../../Modal/Modal.module.scss';
import { formClassName } from '../../../../abstracts/Form/Utils';
import viewStyles from '../../../account/View/View.module.scss';
import Friends from '../../../adding/Friends';
import fixStyles from '../../../adding/styles/Common.module.scss';
import { FOLLOW } from '../../../../models/Followers/Followers.constants';

/**
 * Followers Component.
 */
const Followers = ({
  fields,
  loading,
  errors,
  handleCloseModal,
  handleChangeEmails,
  handlers,
  type,
}) => {
  const { emails } = fields;
  return (
    <Modal handleClose={handleCloseModal}>
      <Title>{type === FOLLOW ? 'Following' : 'Inviting'}</Title>

      <Body>
        <div className={viewStyles.form}>
          {type === FOLLOW && (
            <div className={styles.paragraph}>Which users would you like to follow?</div>
          )}
          {type !== FOLLOW && (
            <div className={styles.paragraph}>
              Type emails which you want to invite into <strong>AS</strong>.
            </div>
          )}

          <div className={fixStyles.adding}>
            <Friends change={handleChangeEmails} useChildAsError>
              <div className={formClassName(emails, errors, viewStyles, [fixStyles.row])}>
                <FieldError
                  data={emails}
                  apiData={errors}
                  names="Emails field"
                  className={viewStyles.error}
                />
              </div>
            </Friends>
          </div>

          <div className={cn(styles.controls, fixStyles.withMarginTop)}>
            <button
              type="button"
              className={cn(styles.success, loading && styles.loading)}
              onClick={handlers.submit}
            >
              {type === FOLLOW ? 'Follow' : 'Invite'}
            </button>
          </div>
        </div>
      </Body>
    </Modal>
  );
};

export default Followers;
