import React, { Component } from 'react';
import { Modal, Title, Body } from '../../../modals/Modal';
import Url from '../../../adding/Url';
import FulFill from '../../../adding/FulFill';

/**
 * Add Link Component.
 */
class LinkAdd extends Component {
  constructor(props) {
    super(props);

    this.setCallback = this.setCallback.bind(this);
  }

  setCallback(fn) {
    this.callback = fn;
  }

  render() {
    const { window, reduxActions } = this.props;

    return (
      <Modal
        handleClose={(event) => {
          const isDirty = this.callback();

          reduxActions.closeModalWithConfirm(event, window === 'fulfill' || isDirty);
        }}
      >
        {window === 'fulfill' && <Title>Add new link</Title>}

        <Body>
          {window === 'start' && <Url setCallback={this.setCallback} />}
          {window === 'fulfill' && <FulFill />}
        </Body>
      </Modal>
    );
  }
}

export default LinkAdd;
