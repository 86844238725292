const cutDataForPlaylist = (video) => {
  return {
    base: video.base,
    title: video.title,
    image: video.image,
    details: video.details,
  };
};

export default cutDataForPlaylist;
