import * as APP from './App.constants';

export const resize = (sizes) => ({
  type: APP.RESIZE,
  payload: {
    sizes,
  },
});

export const playerOpen = () => ({
  type: APP.PLAYER_OPEN,
});

export const playerClose = () => ({
  type: APP.PLAYER_CLOSE,
});
