import React, { Component } from 'react';
import cn from 'classnames';
import Navigation from '../Navigation';
import styles from './Fetch.module.scss';
import detailsStyles from './../Details/Details.module.scss';
import Button from '../../../shared/Button';
import { Link } from 'react-router-dom';

/**
 * Fetch Component.
 */
class Fetch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hostname: '',
    };

    this.getHost = this.getHost.bind(this);
  }

  getHost(ref) {
    if (ref) {
      this.setState({
        hostname: ref.hostname,
      });
    }
  }

  render() {
    const {
      data,
      loading,
      handleMakeScroll,
      handleSetInnerRef,
      handleFetch,
      handleScrollToTop,
    } = this.props;
    const { index, link } = data;

    return (
      <div className={cn(detailsStyles.detail, 'S', styles.fetch)} ref={handleSetInnerRef}>
        <div className={detailsStyles.controlsWrapper}>
          <div className={detailsStyles.controls}>
            <div className={detailsStyles.left} onWheel={handleMakeScroll}>
              <Navigation index={index} endpoint="waitings" handleScrollToTop={handleScrollToTop} />
            </div>
            <div className={detailsStyles.right}>
              <Button onClick={this.props.handleRemove}>Remove</Button>
            </div>
          </div>
        </div>
        <div className={cn(detailsStyles.inner, styles.inner)}>
          <div className={styles.header}>
            <h2>
              <a href={link.link} target="_blank">
                {link.title}
              </a>
            </h2>
          </div>

          <div className={styles.url}>
            <a ref={this.getHost} href={link.link} target="_blank" rel="noopener noreferrer">
              {link.link}
            </a>
          </div>

          <div className={styles.info}>
            We {"don't"} have snapshot of this url and according to your plan we {"didn't"} fetch
            data automatically. You can
          </div>

          <div className={styles.control}>
            <button className={cn(styles.grey, loading && styles.loading)} onClick={handleFetch}>
              Get manually from <strong>{this.state.hostname}</strong>
            </button>
            or
            <Link to="/upgrade" className={styles.blue}>
              Upgrade your plan
            </Link>
          </div>

          <div className={styles.remove}>
            You {"don't"} really need this link?
            <button onClick={this.props.handleRemove}>Remove from waitings</button>.
          </div>
        </div>
      </div>
    );
  }
}

export default Fetch;
