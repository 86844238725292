const mapStateToLoading = (state) => ({
  loading: [
    state.Account.loading,
    state.Adding.loading,
    state.Comments.loading,
    state.DarkList.loading,
    state.Fetch.loading,
    state.Filters.loading,
    state.Followers.loading,
    state.Groups.loading,
    state.Links.editLoading,
    state.Links.loading,
    state.Links.removeLoading,
    state.Thanks.loading,
    state.User.loading,
    state.Deck.loading,
  ].reduce((previousValue, currentValue) => previousValue || currentValue, false),
});

export default mapStateToLoading;
