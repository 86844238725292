const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Get date.
 */
const getDate = (time) => {
  const dd = new Date();
  dd.setTime(time * 1000);
  return `${dd.getDate()} ${MONTHS[dd.getMonth()]} ${dd.getFullYear()}`;
};

export default getDate;
