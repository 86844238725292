import React, { Component } from 'react';
import hydrate from '../../../globals/helpers/hydrate';
import styles from './Loader.module.scss';

/**
 * Loader Row Component.
 */
const LoaderRow = () => {
  const users = hydrate(2, 6);

  return (
    <div className={styles.loader}>
      <div className={styles.name} style={{ width: `${40 + Math.random() * 30}%` }} />
      <div className={styles.users}>
        {users.map((number) => (
          <div key={number} className={styles.user} />
        ))}
      </div>
      <div className={styles.row1} style={{ width: `${20 + Math.random() * 30}%` }} />
      <div className={styles.row2} style={{ width: `${10 + Math.random() * 20}%` }} />
    </div>
  );
};

/**
 * Loader Component.
 */
class Loader extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <LoaderRow />
        <LoaderRow />
        <LoaderRow />
      </div>
    );
  }
}

export default Loader;
