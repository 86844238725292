import React from 'react';
import styles from './Tags.module.scss';

const Tags = ({ loading, tags, reduxActions, isAnySelected }) => {
  if (isAnySelected || loading || tags.length < 4) {
    return null;
  }

  return (
    <div className={styles.tags}>
      {tags.slice(0, 10).map((tag) => (
        <span
          key={tag}
          onClick={() => {
            reduxActions.setFilters([
              {
                type: 'tag',
                value: tag,
              },
            ]);
          }}
        >
          #{tag}
        </span>
      ))}
      <div className={styles.more} />
    </div>
  );
};

export default Tags;
