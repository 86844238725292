import { get, add, loadMore, remove } from './Comments.routines';
import { SET, UNSET } from './Comments.constants';
import Settings from '../../config/settings';

/*
 * Initial State.
 */
const initialState = {
  enabled: null,
  loading: false,
  data: [],
  showMore: false,
  group: null,
  snap: null,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     * LOADING
     */
    case get.REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case loadMore.REQUEST:
    case remove.REQUEST:
    case add.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case get.SUCCESS:
    case loadMore.SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload],
        showMore: action.payload && action.payload.length >= Settings.comments.perPage,
      };

    case add.SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
      };

    case get.FULFILL:
    case loadMore.FULFILL:
    case remove.FULFILL:
    case add.FULFILL:
      return {
        ...state,
        loading: false,
      };

    case SET:
      return {
        ...state,
        enabled: action.payload.enabled,
        group: action.payload.group,
        snap: action.payload.snap,
      };

    case remove.SUCCESS:
      return {
        ...state,
        data: state.data.filter((comment) => comment.id !== action.options.id),
      };

    case UNSET:
      return {
        ...state,
        enabled: null,
        group: null,
        snap: null,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
