/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { closeAllModals } from '../../../models/Modals/Modals.actions';
import ModalList from './Modal.mapper';

/**
 * Modal Switch Component.
 */
class ModalSwitch extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.props.reduxActions.closeAllModals();
    }
  }

  render() {
    const { modals } = this.props;

    if (modals) {
      return modals.map((modal, i) => {
        const { type, payload } = modal;
        const SpecificModal = ModalList[type];

        return <SpecificModal key={`${type}_${i}`} payload={payload} />;
      });
    }

    return null;
  }
}

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  modals: state.Modals.data,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    dispatch,
    ...bindActionCreators(
      {
        closeAllModals,
      },
      dispatch
    ),
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalSwitch)
);
