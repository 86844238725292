import React, { Fragment } from 'react';
import * as SHARE from '../../../models/Share/Share.constants';
import Menu, { Button as ButtonWrapper, Content } from '../../template/Menu/Menu.component';
import styles from '../Details/Details.module.scss';
import cn from 'classnames';
import cutDataForPlaylist from '../../../globals/helpers/cutDataForPlaylist';
import Button from '../../../shared/Button';

/**
 * Actions Component.
 */
const Actions = ({
  link,
  type,
  userId,
  renderButton,
  handleRemove,
  handleChangeSpeedDial,
  handleChangePrivacy,
  handleEdit,
  handleAdd,
  handlePreview,
  handleOpen,
  handleBlock,
  handleReport,
}) => {
  let show = true;

  if (type === 'grouped') {
    if (userId !== link.user_id) {
      show = false;
    }
  }

  return (
    <div className={styles.right}>
      {link.type === 'video' && (
        <div className={styles.menuWrapper}>
          <Menu>
            <ButtonWrapper>
              <Button icon="play_circle_filled" component="div">
                Play
              </Button>
            </ButtonWrapper>
            <Content className={styles.menu}>
              <ul>
                <li>
                  <button
                    onClick={() => {
                      handlePreview(cutDataForPlaylist(link));
                    }}
                  >
                    Play now
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      handleAdd(cutDataForPlaylist(link));
                    }}
                  >
                    Add to default playlist
                  </button>
                </li>
              </ul>
            </Content>
          </Menu>
        </div>
      )}

      {type === 'social' && userId !== link.user_id && !link.user_has && (
        <div className={styles.buttonWrapper}>{renderButton('Save', SHARE.TYPE_SAVE)}</div>
      )}

      {(type === 'waitings' || type === 'for_you') && (
        <Fragment>
          {!link.user_has && renderButton('Save', SHARE.TYPE_SAVE)}
          <Button onClick={handleRemove}>Remove</Button>
        </Fragment>
      )}

      {type !== 'for_you' && (
        <div className={styles.menuWrapper}>
          <Menu>
            <ButtonWrapper>
              <Button icon="share" component="div">
                Share
              </Button>
            </ButtonWrapper>
            <Content className={styles.menu}>
              <ul>
                <li>
                  <button
                    onClick={() => {
                      handleOpen(SHARE.TYPE_WITH_USERS);
                    }}
                  >
                    Share with users
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      handleOpen(SHARE.TYPE_TO_GROUPS);
                    }}
                  >
                    Add to groups
                  </button>
                </li>
              </ul>
            </Content>
          </Menu>
        </div>
      )}

      {type !== 'waitings' && type !== 'for_you' && show && (
        <div className={styles.menuWrapper}>
          <Menu>
            <ButtonWrapper>
              <Button icon="more_vert" onlyIcon component="div" />
            </ButtonWrapper>
            <Content className={styles.menu}>
              <ul>
                {(type === 'saved' || show) && (
                  <Fragment>
                    {type === 'saved' && (
                      <Fragment>
                        <li className={cn(link.speed_dial && styles.selected)}>
                          <button onClick={handleChangeSpeedDial}>Speed Dial Link</button>
                        </li>
                        <li>
                          <button onClick={handleChangePrivacy}>
                            Change to {link.private ? 'public' : 'private'}
                          </button>
                        </li>
                      </Fragment>
                    )}
                    {type !== 'social' && (
                      <Fragment>
                        <li>
                          <button onClick={handleEdit}>Edit</button>
                        </li>
                        <li>
                          <button onClick={handleRemove}>Remove</button>
                        </li>
                      </Fragment>
                    )}
                  </Fragment>
                )}
                {type === 'social' && (
                  <li>
                    <button onClick={handleReport}>Report</button>
                  </li>
                )}
              </ul>
            </Content>
          </Menu>
        </div>
      )}

      {type === 'for_you' && link.from_user && (
        <div className={styles.menuWrapper}>
          <Menu>
            <ButtonWrapper>
              <Button icon="more_vert" onlyIcon component="div" />
            </ButtonWrapper>
            <Content className={styles.menu}>
              <ul>
                <li>
                  <button
                    onClick={() => {
                      handleBlock({
                        id: link.from_user.id,
                      });
                    }}
                  >
                    Block <i>@{link.from_user.full_name}</i>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      handleBlock({
                        id: link.from_user.id,
                        remove: true,
                      });
                    }}
                  >
                    Block and remove proposals
                  </button>
                </li>
              </ul>
            </Content>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Actions;
