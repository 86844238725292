import Deck from './Deck.component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openModal } from '../../../models/Modals/Modals.actions';
import * as DeckRoutines from '../../../models/Deck/Deck.routines';
import { bindRoutines } from '../../../abstracts/Routine';
import { createSelector } from 'reselect';
import { preview } from '../../../models/Player/Player.actions';

const getDeck = (state) => state.Deck;

export const getSelectedGroups = createSelector(
  [getDeck],
  (state) => {
    return state.data.filter((group) => group.selected);
  }
);

/*
 * Connection.
 */
const mapStateToProps = (state) => ({
  width: state.App.sizes.realWidth,
  groups: getSelectedGroups(state),
  loading: state.Deck.loading,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        openModal,
        preview,
      },
      dispatch
    ),
    ...bindRoutines(
      {
        getGroups: DeckRoutines.getGroups.trigger,
      },
      dispatch
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Deck);
