import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Upgrade.module.scss';

const Upgrade = () => {
  return (
    <div className={styles.upgrade}>
      <div className={styles.left}>
        Actions item by item are boring? Permium allows to share, save remove in bulk move. Without
        limits.
      </div>
      <div>
        <Link to="/upgrade">Upgrade</Link>
      </div>
    </div>
  );
};

export default Upgrade;
