import * as Alert from '../../models/Alert/Alert.constants';

/**
 * As error.
 *
 * @param action
 * @returns {*}
 */
export const asError = (action) => {
  return {
    // DATA
    response: action.payload.response.response,
    status: action.payload.response.status,
    errors: { ...action.payload.response.errors },
    // END OF DATA
    alert: {
      time: Date.now(),
      key: `${Math.random()}${Date.now()}`,
      type: Alert.TYPE_ERROR,
    },
  };
};

/**
 * As success.
 *
 * @param payload
 * @returns {*}
 */
export const asSuccess = (payload) => {
  return {
    ...payload,
    alert: {
      time: Date.now(),
      key: `${Math.random()}${Date.now()}`,
      type: Alert.TYPE_SUCCESS,
    },
  };
};

/**
 * As info.
 *
 * @param payload
 * @returns {*}
 */
export const asInfo = (payload) => {
  const alertObject = asSuccess(payload);

  alertObject.alert.type = Alert.TYPE_INFO;

  return alertObject;
};
