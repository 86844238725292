import routineStages from './stages';
import createAction from './createAction';

/**
 * Create Routine.
 *
 * @param typePrefix
 */
const createRoutine = (typePrefix) => {
  const createActionCreator = (type) => createAction(`${typePrefix}/${type}`);

  return routineStages.reduce((result, stage) => {
    const actionCreator = createActionCreator(stage);

    return Object.assign(result, {
      [stage.toLowerCase()]: actionCreator.action,
      [stage.toUpperCase()]: actionCreator.name,
    });
  }, {});
};

export default createRoutine;
