import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { add } from '../../../models/Comments/Comments.routines';
import Input from './Input.component';

const mapStateToProps = (state) => {
  const linkId = state.Links.selected;

  const found = state.Links.data.find((link) => link.id === linkId);

  let snapId = null;

  if (found) {
    snapId = found.snap_id;
  }

  return {
    snapId,
  };
};

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        addComment: add.trigger,
      },
      dispatch
    ),
    dispatch,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Input);
