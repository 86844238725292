import React from 'react';

/**
 * Prepare sections.
 */
const prepare = (...names) => {
  const obj = {};

  names.forEach((name) => {
    obj[name] = ({ children, props }) => {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          ...props,
        });
      }

      return children || null;
    };

    obj[name].displayName = name;
  });

  return obj;
};

export default prepare;
