import React from 'react';
import styles from './Preview.module.scss';

const Preview = ({ link }) => {
  return (
    <div className={styles.preview}>
      {link.image && (
        <div className={styles.image}>
          <img src={link.image.url} alt="Preview" />
        </div>
      )}
      <div className={styles.info}>
        <h3>{link.title}</h3>
        <div className={styles.description}>{link.description}</div>
      </div>
    </div>
  );
};

export default Preview;
