import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../../config/config';
import { get, prepare } from '../../../abstracts/Sections';
import styles from './View.module.scss';

/*
 * Sections
 */
export const { Body, Menu } = prepare('Body', 'Menu');

/**
 * View Component.
 */
const View = ({ children }) => {
  const { BodySection, MenuSection } = get(children, Body, Menu);

  return (
    <div className={styles.loginPage}>
      <div className={styles.bar}>
        <div className={styles.inner}>
          <Link to="/" className={styles.logo}>
            zasejwuj.pl
          </Link>

          <div className={styles.links}>
            <ul>
              {MenuSection}
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.body}>{BodySection}</div>

      <div className={styles.copyright}>
        <Link to="/">2024 &copy; zasejwuj.pl</Link>
      </div>
    </div>
  );
};

export default View;
