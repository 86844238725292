import React, { Component, Fragment } from 'react';
import styles from './Open.module.scss';

/**
 * Open Component.
 */
class Open extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blink: false,
    };
  }

  componentDidUpdate(props) {
    if (props.blink !== this.props.blink) {
      this.setState(
        {
          blink: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              blink: false,
            });
          }, 1000);
        }
      );
    }
  }

  render() {
    const { reduxActions, visible } = this.props;

    if (!visible && !this.state.blink) {
      return null;
    }

    return (
      <Fragment>
        {this.state.blink && <div className={styles.blink} />}
        {visible && (
          <button className={styles.button} onClick={reduxActions.playerOpen}>
            <span className="icomoon icon-play_arrow" />
          </button>
        )}
      </Fragment>
    );
  }
}

export default Open;
