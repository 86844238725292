import React from 'react';
import Controller from '../../classes/Controller';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import * as FollowersRoutines from '../../../models/Followers/Followers.routines';
import * as FiltersActions from '../../../models/Filters/Filters.actions';
import { bindRoutines } from '../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import * as ModalsActions from '../../../models/Modals/Modals.actions';
import withValue from '../../../globals/helpers/withValue';
import ListComponent from './List.component';

/**
 * List Container.
 */
class List extends Controller {
  static initialState() {
    return {
      selected: [],
    };
  }

  setVars() {
    this.ref = null;
  }

  load(payload) {
    this.props.reduxActions.getFollowers(payload);
  }

  bindings() {
    this.toggleSelect = this.toggleSelect.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.innerRef = this.innerRef.bind(this);
    this.unfollow = this.unfollow.bind(this);
  }

  extendedReceiveProps(props) {
    if (props.followers !== this.props.followers) {
      this.setState(this.constructor.initialState());
    }
  }

  innerRef(ref) {
    this.ref = ref;
  }

  toggleAll() {
    if (this.props.followers.length > 0) {
      if (this.state.selected.length === this.props.followers.length) {
        // reset
        this.setState({
          selected: [],
        });
      } else {
        // select all
        const newArr = [];

        this.props.followers.forEach((user) => newArr.push(user.id));

        this.setState({
          selected: newArr,
        });
      }
    }
  }

  toggleSelect(id) {
    const index = this.state.selected.indexOf(id);

    if (index >= 0) {
      // found - remove from array
      const newArr = [...this.state.selected];
      newArr.splice(index, 1);

      this.setState({
        selected: newArr,
      });
    } else {
      // not found - add to array
      this.setState({
        selected: [...this.state.selected, id],
      });
    }
  }

  unfollow(event) {
    const query = this.props.location.search ? queryString.parse(this.props.location.search) : {};

    this.props.reduxActions.unfollow(event, true, {
      users: this.state.selected,
      reload: withValue({
        ...query,
        page: this.props.match.params.page || null,
        withReload: false,
      }),
    });
  }

  render() {
    if (this.redirect) {
      return <Redirect to="/followers/1" />;
    }

    return (
      <ListComponent
        loading={this.props.loading}
        followers={this.props.followers}
        selected={this.state.selected}
        pagination={this.props.pagination}
        handleToggleSelect={this.toggleSelect}
        handleToggleAll={this.toggleAll}
        handleInnerRef={this.innerRef}
        handleUnfollow={this.unfollow}
        handleSetFilters={this.props.reduxActions.setFilters}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        getFollowers: FollowersRoutines.getFollowers.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        resetFilters: FiltersActions.resetFilters,
        setFilters: FiltersActions.setFilters,
      },
      dispatch
    ),

    unfollow: ModalsActions.wrapWithConfirm(
      dispatch,
      FollowersRoutines.unfollow.trigger,
      'Do you want to stop following selected users?'
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Followers.loading,
  followers: state.Followers.data,
  pagination: state.Followers.pagination,
  // To refresh
  filters: state.Filters.selected,
  forcedRefreshTime: state.Filters.forcedRefreshTime,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(List)
);
