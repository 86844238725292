import { getBanned, removeFromList } from './DarkList.routines';
// import { RESET } from './Account.constants';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  errors: null,
  data: [],
  pagination: null,
  added: false,
  response: null,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     * LOADING START
     */
    case getBanned.REQUEST:
    case removeFromList.REQUEST:
      return {
        ...state,
        loading: true,
      };

    /*
     * LOADING STOP
     */
    case getBanned.FULFILL:
    case removeFromList.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * GET BANNED
     */
    case getBanned.SUCCESS:
      return {
        ...state,
        ...action.payload,
        added: true,
      };

    /*
     * REMOVE
     */
    case removeFromList.SUCCESS:
      return {
        ...state,
        response: Date.now(),
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
