import React from 'react';
import cn from 'classnames';
import styles from '../styles/Common.module.scss';

/**
 * PolicyButton Component.
 */
const PolicyButton = ({ is, type, handleSetIs, text }) => (
  <button
    className={cn(is === type && styles.selected)}
    onClick={() => {
      handleSetIs(type);
    }}
  >
    {text}
  </button>
);

export default PolicyButton;
