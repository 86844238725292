/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import ListComponent from '../../users/List';
import { bindRoutines } from '../../../abstracts/Routine';
import { connect } from 'react-redux';
import { getMembers, removeMembers, getDetails } from '../../../models/Groups/Groups.routines';
import { setMembersGroup, resetMembersGroup } from '../../../models/Groups/Groups.actions';
import { bindActionCreators } from 'redux';
import * as ModalsActions from '../../../models/Modals/Modals.actions';
import { addAsSuccess } from '../../../models/Alert/Alert.actions';
import renderUser from './Members.render';
import * as FiltersActions from '../../../models/Filters/Filters.actions';

/**
 * Members Component
 */
class Members extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
    };

    this.reloadAfterClose = false;

    this.renderPagination = this.renderPagination.bind(this);
    this.open = this.open.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    this.props.reduxActions.setMembersGroup(this.props.group.id);

    this.open(1, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.response !== this.props.response && this.props.response) {
      this.prepareAlerts();
    }
  }

  componentWillUnmount() {
    if (this.reloadAfterClose) {
      this.props.reduxActions.getDetails({
        id: this.props.group.id,
      });
    }

    this.props.reduxActions.resetMembersGroup();
  }

  prepareAlerts() {
    this.setState({
      selected: [],
    });

    let counter = 0;

    Object.keys(this.props.response).forEach((key) => {
      if (this.props.response[key].toLowerCase() === 'removed') {
        counter++;
      }
    });

    if (counter > 0) {
      this.reloadAfterClose = true;

      this.props.reduxActions.addAsSuccess(`You have removed (${counter}) members.`);

      const loadPage = this.props.pagination.current;

      if (counter === this.props.data.length) {
        this.open(Math.max(1, loadPage - 1), true);
      } else {
        this.open(loadPage, true);
      }
    }
  }

  selectChange(id) {
    const found = this.state.selected.find((_id) => _id === id);

    if (found) {
      this.setState({
        selected: this.state.selected.filter((_id) => _id !== id),
      });
    } else {
      this.setState({
        selected: [...this.state.selected, id],
      });
    }
  }

  selectAll() {
    if (this.state.selected.length === this.props.data.length - 1) {
      this.setState({
        selected: [],
      });
    } else {
      this.setState({
        selected: this.props.data.map((user) => user.id).filter((id) => id !== this.props.user.id),
      });
    }
  }

  open(page, withReload = false) {
    this.props.reduxActions.getMembers({
      id: this.props.group.id,
      page,
      withReload,
    });
  }

  remove(event) {
    const closeModalWithConfirm = ModalsActions.wrapWithConfirm(
      this.props.reduxActions.dispatch,
      removeMembers.trigger,
      'Do you want to remove members?',
      false
    );

    closeModalWithConfirm(event, true, {
      id: this.props.group.id,
      ids: this.state.selected,
    });
  }

  renderPagination(page, name) {
    return (
      <button
        onClick={() => {
          this.open(page);
        }}
      >
        {name}
      </button>
    );
  }

  render() {
    return (
      <ListComponent
        data={this.props.data}
        loading={this.props.loading}
        pagination={this.props.pagination}
        added={this.props.added}
        ownerId={this.props.group.user_id}
        render={this.renderPagination}
        renderUser={renderUser}
        selected={this.state.selected}
        handleSelectChange={this.selectChange}
        handleSelectAll={this.selectAll}
        handleRemove={this.remove}
        setFilters={this.props.reduxActions.setFilters}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    dispatch,

    ...bindActionCreators(
      {
        setMembersGroup,
        resetMembersGroup,
        addAsSuccess,
        setFilters: FiltersActions.setFilters,
      },
      dispatch
    ),

    ...bindRoutines(
      {
        getMembers: getMembers.trigger,
        getDetails: getDetails.trigger,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Groups.members.loading,
  data: state.Groups.members.data,
  pagination: state.Groups.members.pagination,
  added: state.Groups.members.added,
  response: state.Groups.members.response,
  user: state.User.data,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Members);
