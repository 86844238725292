/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';

// Import Reducers
import Account from '../models/Account/Account.reducer';
import Adding from '../models/Adding/Adding.reducer';
import Alert from '../models/Alert/Alert.reducer';
import App from '../models/App/App.reducer';
import Comments from '../models/Comments/Comments.reducer';
import DarkList from '../models/DarkList/DarkList.reducer';
import Fetch from '../models/Fetch/Fetch.reducer';
import Filters from '../models/Filters/Filters.reducer';
import Follower from '../models/Follower/Follower.reducer';
import Followers from '../models/Followers/Followers.reducer';
import Groups from '../models/Groups/Groups.reducer';
import Links from '../models/Links/Links.reducer';
import Modals from '../models/Modals/Modals.reducer';
import Player from '../models/Player/Player.reducer';
import Report from '../models/Report/Report.reducer';
import SpeedDial from '../models/SpeedDial/SpeedDial.reducer';
import Statistics from '../models/Statistics/Statistics.reducer';
import Tags from '../models/Tags/Tags.reducer';
import Thanks from '../models/Thanks/Thanks.reducer';
import User from '../models/User/User.reducer';
import Deck from '../models/Deck/Deck.reducer';

/**
 * App Reducer.
 */
const appReducer = combineReducers({
  Account,
  Adding,
  Alert,
  App,
  Comments,
  DarkList,
  Deck,
  Fetch,
  Filters,
  Follower,
  Followers,
  Groups,
  Links,
  Modals,
  Player,
  Report,
  SpeedDial,
  Statistics,
  Tags,
  Thanks,
  User,
});

/**
 * Root Reducer.
 */
const rootReducer = (state, action) => {
  if (action.type === '@@INIT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
