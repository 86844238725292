import React from 'react';
import styles from './../styles/Player.module.scss';

/**
 * Preview Component.
 */
const Preview = ({ reduxActions, video }) => {
  return (
    <div className={styles.preview}>
      <h3>You are watching:</h3>
      <h2>{video.title}</h2>

      <div className={styles.back}>
        <button
          onClick={() => {
            reduxActions.setDefaultPlaylist();
          }}
        >
          Back to playlist
        </button>
      </div>
    </div>
  );
};

export default Preview;
