import React, { Component } from 'react';
import Comment from '../Comment';
import Input from '../Input';
import Loading from '../Loading';
import More from '../More';
import styles from './../styles/Comments.module.scss';

const SCROLL_OFFSET = 80;

class Comments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markers: [],
    };

    this.addMarker = this.addMarker.bind(this);
    this.removeMarker = this.removeMarker.bind(this);
    this.setRef = this.setRef.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.resetMarkers = this.resetMarkers.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.type !== nextProps.type ||
      this.props.snapId !== nextProps.snapId ||
      this.props.groupId !== nextProps.groupId
    ) {
      let grouped;

      if (nextProps.type === 'comments') {
        grouped = false;
      } else {
        grouped = nextProps.groupId;
      }

      this.props.reduxActions.loadComments({
        id: nextProps.snapId,
        grouped,
      });
    }
  }

  addMarker(user) {
    const found = this.state.markers.find((marker) => marker.id === user.id);

    if (!found) {
      this.setState(
        (state) => ({
          markers: [...state.markers, user],
        }),
        () => {
          if (this.ref) {
            const visiblePosition = this.ref.offsetTop - SCROLL_OFFSET;
            const details = document.getElementById('details');

            const currentScrollTopPosition = details.scrollTop;

            if (currentScrollTopPosition > visiblePosition) {
              details.scrollTop = visiblePosition;
            }

            this.inputRef.focus();
          }
        }
      );
    }
  }

  resetMarkers() {
    this.setState({
      markers: [],
    });
  }

  removeMarker(id) {
    const newMarkers = this.state.markers.filter((marker) => marker.id !== id);

    if (newMarkers.length !== this.state.markers) {
      this.setState({
        markers: newMarkers,
      });
    }
  }

  setRef(elem) {
    if (elem) {
      this.ref = elem;
    }
  }

  handleRef(ref) {
    if (ref) {
      this.inputRef = ref;
    }
  }

  render() {
    const { loading, showMore, snapId } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.comments}>
          <div ref={this.setRef}>
            <Input
              markers={this.state.markers}
              handleRemoveMarker={this.removeMarker}
              setInnerRef={this.handleRef}
              handleResetMarkers={this.resetMarkers}
              user={this.props.user}
            />
          </div>

          {this.props.comments.map((comment) => (
            <Comment
              key={comment.id}
              data={comment}
              handleAddMarker={this.addMarker}
              markers={this.state.markers}
              currentUserId={this.props.currentUserId}
              handleRemove={this.props.reduxActions.remove}
            />
          ))}

          {loading && <Loading />}
        </div>

        {!loading && showMore && <More snapId={snapId} />}
      </div>
    );
  }
}

export default Comments;
