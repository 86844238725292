import {
  getGroups,
  getAllGroups,
  addGroup,
  editGroup,
  addMembers,
  getMembers,
  removeMembers,
  getDetails,
  deleteGroup,
  leave,
  getDetailsForGrouped,
} from './Groups.routines';
import { RESET, SET_MEMBERS_GROUP, RESET_MEMBERS_GROUP } from './Groups.constants';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  data: [],
  pagination: null,
  all: {
    data: [],
    added: false,
  },
  errors: null,
  response: null,
  responseReceived: false,
  members: {
    id: null,
    data: [],
    pagination: null,
    loading: false,
    added: false,
    response: null,
  },
  grouped: {
    loading: false,
    data: null,
  },
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case getGroups.TRIGGER:
      if (action.payload.withReload) {
        return {
          ...initialState,
          loading: state.loading,
        };
      }

      return state;

    case getMembers.TRIGGER:
      if (action.payload.withReload) {
        return {
          ...state,
          members: {
            ...initialState.members,
            id: state.members.id,
            loading: state.members.loading,
          },
        };
      }

      return state;

    /*
     * LOADING
     */
    case getGroups.REQUEST:
    case getAllGroups.REQUEST:
    case addGroup.REQUEST:
    case getDetails.REQUEST:
    case deleteGroup.REQUEST:
    case leave.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case getGroups.FULFILL:
    case getAllGroups.FULFILL:
    case addGroup.FULFILL:
    case getDetails.FULFILL:
    case deleteGroup.FULFILL:
    case leave.FULFILL:
      return {
        ...state,
        loading: false,
      };

    // MEMBERS
    case getMembers.REQUEST:
    case getMembers.FULFILL:
      return {
        ...state,
        members: {
          ...state.members,
          loading: action.type === getMembers.REQUEST,
        },
      };

    /*
     * GET GROUPS
     */
    case getGroups.SUCCESS:
      return {
        ...state,
        data: [...((action.payload && action.payload.data) || [])],
        pagination: { ...((action.payload && action.payload.pagination) || {}) },
      };

    case getAllGroups.SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload || [],
          added: true,
        },
      };

    /*
     * GET DETAILS
     */
    case getDetails.SUCCESS:
      return {
        ...state,
        data: state.data.map((group) => {
          if (group.id === action.payload.id) {
            return action.payload;
          }

          return group;
        }),
      };

    /*
     * GROUP FOR GROUPED
     */
    case getDetailsForGrouped.TRIGGER:
      return {
        ...state,
        grouped: {
          data: null,
          loading: state.grouped.loading,
        },
      };

    case getDetailsForGrouped.REQUEST:
      return {
        ...state,
        grouped: {
          ...state.grouped,
          loading: true,
        },
      };

    case getDetailsForGrouped.FULFILL:
      return {
        ...state,
        grouped: {
          ...state.grouped,
          loading: false,
        },
      };

    case getDetailsForGrouped.SUCCESS:
      return {
        ...state,
        grouped: {
          ...state.grouped,
          data: action.payload,
        },
      };

    /*
     * ADD GROUP
     */
    case addGroup.SUCCESS:
      return {
        ...state,
        responseReceived: true,
      };

    /*
     * EDIT GROUP
     */
    case editGroup.SUCCESS:
      return {
        ...state,
        data: state.data.map((group) => {
          if (group.id === action.payload.id) {
            return {
              ...group,
              ...action.payload,
            };
          }

          return group;
        }),
        responseReceived: true,
      };

    case addGroup.FAILURE:
    case editGroup.FAILURE:
      return {
        ...state,
        errors: { ...action.payload.response.errors },
      };

    /*
     * ADD MEMBERS
     */
    case addMembers.SUCCESS:
      return {
        ...state,
        response: action.payload,
        responseReceived: true,
      };

    /*
     * GET MEMBERS
     */
    case getMembers.SUCCESS:
      return {
        ...state,
        members: {
          ...state.members,
          ...action.payload,
          added: true,
        },
      };

    /*
     * REMOVE MEMBERS
     */
    case removeMembers.SUCCESS:
      return {
        ...state,
        members: {
          ...state.members,
          response: action.payload,
        },
      };

    /*
     * MEMBERS GROUP
     */
    case SET_MEMBERS_GROUP:
      return {
        ...state,
        members: {
          ...state.members,
          id: action.id,
        },
      };

    case RESET_MEMBERS_GROUP:
      return {
        ...state,
        members: {
          ...initialState.members,
        },
      };

    /*
     * RESET (RESPONSE)
     */
    case RESET:
      return {
        ...state,
        errors: null,
        response: initialState.response,
        responseReceived: initialState.responseReceived,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
