import React, { Fragment } from 'react';
import cn from 'classnames';
import Navigation from '../Navigation';
import prepareAvatar from '../../../globals/helpers/prepareAvatar';
import cutDataForPlaylist from '../../../globals/helpers/cutDataForPlaylist';
import moment from 'moment';
import Image from './../Image';
import styles from './Details.module.scss';
import Tags from './../Tags';
import Actions from './../Actions';
import Extras from './../Extras';

/**
 * Details Component.
 */
const Details = ({
  data,
  type,
  handleMakeScroll,
  handleSetInnerRef,
  handleScrollToTop,
  handlePreview,
  handleSayThankYou,
  handleSetFilters,
}) => {
  const { link, index } = data;

  const time = moment.utc(link.created_at).local();

  const user = link.user || link.from_user || null;

  return (
    <div className={cn(styles.detail, 'R')} ref={handleSetInnerRef} id="details">
      <div className={styles.controlsWrapper}>
        <div className={styles.controls}>
          <div className={styles.left} onWheel={handleMakeScroll}>
            <Navigation
              index={index}
              endpoint={type}
              className={styles.button}
              handleScrollToTop={handleScrollToTop}
            />
          </div>

          <Actions type={type} link={link} />
        </div>
      </div>

      <div className={styles.inner}>
        {type === 'waitings' && link.user_has && (
          <div className={styles.alreadyHave}>You already have this link saved.</div>
        )}

        {link.hidden && (
          <div className={styles.alreadyHave}>
            Link has been changed and does not match with given filter criteria.
          </div>
        )}

        <div className={styles.about}>
          <div className={cn(styles.actions, user && styles.aboutWithMargin)}>
            <div className={styles.user}>
              {user && (
                <div className={styles.avatar}>
                  <img src={prepareAvatar(user)} alt="" />
                </div>
              )}
              <div className={styles.info}>
                {user && (
                  <div className={styles.userDetails}>
                    {type === 'social' && (
                      <button
                        onClick={() => {
                          handleSetFilters([
                            {
                              type: 'user',
                              value: {
                                name: user.full_name,
                                id: user.id,
                              },
                            },
                          ]);
                        }}
                      >
                        @{user.full_name}
                      </button>
                    )}
                    {type !== 'social' && <span>@{user.full_name}</span>}
                  </div>
                )}
                <div className={styles.info}>
                  <span className={styles.date}>
                    {time.fromNow()}
                    <em>{time.format('DD.MM.YYYY, HH:mm:ss')}</em>
                  </span>
                  <span>
                    <a href={`http://${link.base}`} target="_blank" rel="noreferrer noopener">
                      {link.base}
                    </a>
                  </span>

                  {type === 'saved' && (
                    <Fragment>
                      <span>is {link.private ? 'private' : 'public'}</span>
                      <span>{link.visit_counter} visits</span>
                      {link.speed_dial && <span>in Speed Dial</span>}
                    </Fragment>
                  )}
                </div>
              </div>

              {link.from_user && (
                <div className={styles.thanks}>
                  {!link.thanks && (
                    <button
                      onClick={() => {
                        handleSayThankYou({
                          id: link.id,
                          user: `@${user.full_name}`,
                        });
                      }}
                    >
                      Say thank you!
                    </button>
                  )}
                  {link.thanks && <span>You have said thanks</span>}
                </div>
              )}
            </div>
          </div>
        </div>

        {link.image && (
          <Image
            key={link.image.url}
            src={link.image.url}
            width={link.image.width}
            height={link.image.height}
            styles={styles}
            withBackground={link.type === 'video'}
          >
            {link.type === 'video' && (
              <div
                className={styles.play}
                onClick={() => {
                  handlePreview(cutDataForPlaylist(link));
                }}
              />
            )}
          </Image>
        )}

        <div className={styles.title}>
          <h2>
            <a href={link.link} target="_blank" rel="noopener noreferrer">
              {link.title}
            </a>
          </h2>
        </div>

        <div className={styles.about}>
          <div className={styles.description}>{link.description}</div>

          <Tags data={link.tags} />
        </div>

        <Extras type={type} snapId={link.snap_id} groupId={link.group_id} />
      </div>
    </div>
  );
};

export default Details;
