import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import * as KEYS from '../../../globals/constants/Menu';
import * as MODALS from '../../../models/Modals/Modals.types';
import styles from './MainMenu.module.scss';

/**
 * Menu object.
 */
const MenuObject = {
  [KEYS.SAVED]: { name: 'Stored' },
  [KEYS.WAITING_ROOM]: { name: 'Waitings' },
  [KEYS.GROUPED]: {
    actionType: MODALS.GROUP_SELECT,
    name: 'Grouped',
  },
  [KEYS.FOR_YOU]: { name: 'For you' },
  [KEYS.SOCIAL]: { name: 'Browse' },
};

const renderDashboardMenu = (key, openModal, statistics) => {
  return Object.keys(MenuObject).map((itemKey) => (
    <li
      className={cn(
        key === `dashboard/${itemKey}` && styles.selected,
        statistics[itemKey] && styles.ready
      )}
      key={itemKey}
    >
      {MenuObject[itemKey].actionType && (
        <button
          onClick={() => {
            openModal({
              type: MenuObject[itemKey].actionType,
            });
          }}
        >
          {MenuObject[itemKey].name}
        </button>
      )}
      {!MenuObject[itemKey].actionType && (
        <Link to={`/dashboard/${itemKey}`}>{MenuObject[itemKey].name}</Link>
      )}
    </li>
  ));
};

/**
 * Render Menu.
 */
const renderMenu = (page, openModal, statistics) => {
  return (
    <div className={styles.menu}>
      <ul className={styles.deckMenu}>
        <li className={cn(page === 'deck' && styles.selected)}>
          <Link to={`/deck`}>Deck</Link>
        </li>
      </ul>

      <ul className={styles.firstMenu}>{renderDashboardMenu(page, openModal, statistics)}</ul>

      <div className={styles.separator} />
      <ul>
        <li className={cn(page === 'followers' && styles.selected, styles.page)}>
          <Link to="/followers">Followers</Link>
        </li>
        <li className={cn(page === 'groups' && styles.selected, styles.page)}>
          <Link to="/groups">Groups</Link>
        </li>
      </ul>
    </div>
  );
};

/**
 * Menu Component.
 */
const MainMenu = ({ small, page, openModal, statistics }) => {
  return <div className={styles.bigHeight}>{renderMenu(page, openModal, statistics)}</div>;
};

export const BackMenu = () => (
  <div className={cn(styles.bigHeight, styles.back)}>
    <ul>
      <li className={styles.page}>
        <Link to="/dashboard/saved">Back to app</Link>
      </li>
    </ul>
  </div>
);

export default MainMenu;
