import { check, fetch } from './Fetch.routines';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     * LOADING
     */
    case check.REQUEST:
    case fetch.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case check.FULFILL:
    case fetch.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
