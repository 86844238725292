import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ModalsActions from '../../../../models/Modals/Modals.actions';
import * as AddingActions from '../../../../models/Adding/Adding.actions';
import { Modal, Title, Body } from '../../Modal';
import FulFill from '../../../adding/FulFill';

/**
 * Edit Link Component.
 */
class LinkEdit extends Component {
  constructor(props) {
    super(props);

    this.setDirty = this.setDirty.bind(this);
  }

  setDirty(fcn) {
    this.dirty = fcn;
  }

  render() {
    const { reduxActions, payload: snap } = this.props;

    return (
      <Modal
        handleClose={(event) => {
          reduxActions.closeModalWithConfirm(event, this.dirty());
        }}
      >
        <Title>Edit link</Title>

        <Body>
          <FulFill asEdit handleDirty={this.setDirty} origin={snap} />
        </Body>
      </Modal>
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    closeModalWithConfirm: ModalsActions.wrapWithConfirm(
      dispatch,
      AddingActions.reset, // original action
      'Do you want to abort editing this link?',
      true
    ),
  },
});

export default connect(
  null,
  mapDispatchToProps
)(LinkEdit);
