import React from 'react';

/**
 * Find section.
 */
const find = (name) => (e) => e.type.displayName === name;

/**
 * Map children.
 *
 * @param fcn
 * @param postfix
 * @param children
 * @param components
 */
const map = (fcn, postfix, children, components) => {
  const sections = {};

  const type = Object.prototype.toString.call(children);

  if (type === '[object Array]') {
    components.forEach((component) => {
      const findName = component.displayName;
      let obj = null;

      const preparedFind = find(findName);

      React.Children.forEach(children, (e) => {
        if (React.isValidElement(e) && preparedFind(e)) {
          obj = e;
        }
      });

      if (obj) {
        sections[`${findName}${postfix}`] = fcn(obj);
      }
    });
  } else if (type === '[object Object]') {
    const findName = children.type.displayName;

    sections[`${findName}${postfix}`] = fcn(children);
  }

  return sections;
};

export default map;
