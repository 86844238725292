export const CONFIRM = '@@MODALS/CONFIRM';

export const FOLLOWERS = '@@MODALS/FOLLOWERS';

export const GROUP_ADD = '@@MODALS/GROUP_ADD';
export const GROUP_ADD_MEMBER = '@@MODALS/GROUP_ADD_MEMBER';
export const GROUP_MEMBERS = '@@MODALS/GROUP_MEMBERS';
export const GROUP_SELECT = '@@MODALS/GROUP_SELECT';

export const LINK_ADD = '@@MODALS/LINK_ADD';
export const LINK_SHARE = '@@MODALS/LINK_SHARE';
export const LINK_EDIT = '@@MODALS/LINK_EDIT';

export const ADDED = '@@MODALS/ADDED';

export const SPEED_DIAL = '@@MODALS/SPEED_DIAL';

export const THANKS_LIST = '@@MODALS/THANKS_LIST';

export const DECK_SETTINGS = '@@MODALS/DECK_SETTINGS';
