import React, { Fragment } from 'react';
import cn from 'classnames';
import styles from '../../group/Menu/Menu.module.scss';

/**
 * RenderMenu Component.
 */
const RenderMenu = ({ handleOpenFollowersInvite, handleOpenFollowersFollow }) => {
  return (
    <Fragment>
      <li>
        <button onClick={handleOpenFollowersInvite}>Invite</button>
      </li>
      <li>
        <button onClick={handleOpenFollowersFollow} className={styles.other}>
          Follow
        </button>
      </li>
    </Fragment>
  );
};

const Menu = ({ small, ...rest }) => {
  return (
    <div className={cn(styles.menu, styles.big)}>
      <ul className={styles.buttonsMenu}>
        <RenderMenu {...rest} />
      </ul>
    </div>
  );
};

export default Menu;
