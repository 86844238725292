/* eslint-disable import/prefer-default-export */
import { concat, of, race } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';
import config from '../../config/config';
import getHeaders from '../../globals/helpers/headers';
import { addAsSuccess } from '../Alert/Alert.actions';
import { login } from '../Account/Account.routines';
import * as User from './User.routines';
import epic from '../../abstracts/Epic';

export const getDetails = (action$) => {
  const routine = User.getDetails;

  return action$.pipe(
    ofType(login.SUCCESS, routine.TRIGGER),
    mergeMap(() => {
      return concat(
        of(routine.request()),
        race(
          ajax.get(`${config.apiHost}user`, getHeaders()).pipe(
            mergeMap(whenSuccess(routine)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const changeName = (action$) => {
  const routine = User.changeName;

  return action$.pipe(
    ofType(routine.TRIGGER),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax.post(`${config.apiHost}user/name`, action.payload, getHeaders()).pipe(
            mergeMap(
              whenSuccess(routine, action, of(addAsSuccess('Your name have been changed.')))
            ),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const changePassword = (action$) => {
  const routine = User.changePassword;

  return action$.pipe(
    ofType(routine.TRIGGER),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax.post(`${config.apiHost}user/password`, action.payload, getHeaders()).pipe(
            mergeMap(
              whenSuccess(routine, action, of(addAsSuccess('Your password have been changed.')))
            ),
            catchError(whenFailure(routine, undefined, true))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const markAsInterested = epic(
  User.markAsInterested,
  `${config.apiHost}user/interested`,
  true,
  'User'
);
