import React from 'react';
import { connect } from 'react-redux';
import mapStateToProps from './../helpers/mapStateToProps';
import mapDispatchToProps from './../helpers/mapDispatchToProps';
import Component from './Phrase.component';
import Main from './../Main';

/**
 * Phrase Container.
 */
class Phrase extends Main {
  render() {
    return (
      <Component
        phrase={this.state.phrase}
        handleClose={this.props.handleClose}
        handleChange={this.onChange}
        handleClear={this.clear}
        handleSetFilter={this.setFilters}
        handleSetFiltersByEnter={this.setFiltersByEnter}
        handleSetRef={this.setRef}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Phrase);
