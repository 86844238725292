import { bindActionCreators } from 'redux';
import { bindRoutines } from '../../../abstracts/Routine';
import { setFilters, setWhere } from '../../../models/Filters/Filters.actions';
import * as Tags from '../../../models/Tags/Tags.routines';

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        getTags: Tags.getTags.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        setFilters,
        setWhere,
      },
      dispatch
    ),
  },
});

export default mapDispatchToProps;
