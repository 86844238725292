import React, { Component } from 'react';
import FieldError from '../../errors/Field';
import ApiError from '../../errors/Api';
import { formClassName } from '../../../abstracts/Form/Utils';
import cn from 'classnames';
import viewStyles from '../../account/View/View.module.scss';
import AutoSizeInput from '../../template/AutoSizeInput';
import inputStyles from '../../template/AutoSizeInput/AutoSizeInput.module.scss';
import styles from './../styles/Common.module.scss';
import urlStyles from './Url.module.scss';

/**
 * Url Component.
 */
class Url extends Component {
  renderOneLink() {
    const {
      handlers,
      fields: { link },
      errors,
      loading,
      handleSetFocus,
      handleChange,
    } = this.props;

    return (
      <form onSubmit={handlers.submit}>
        <div className={viewStyles.form}>
          <div className={formClassName(link, errors, viewStyles)}>
            <AutoSizeInput
              type="text"
              className={cn(viewStyles.input, inputStyles.input)}
              placeholder="Url address: http://..."
              name={link.name}
              onChange={handleChange}
              value={link.value}
              handleSetFocus={handleSetFocus}
            />
            <FieldError data={link} apiData={errors} names="Url" className={viewStyles.error} />
          </div>

          <div className={cn(viewStyles.row, viewStyles.lastRow)}>
            <button
              onClick={handlers.submit}
              className={cn(styles.getLink, loading && styles.loading)}
              disabled={loading}
            >
              Get link
            </button>
            <ApiError data={errors} className={viewStyles.error} />
          </div>
        </div>
      </form>
    );
  }

  renderBulkAdding() {
    const {
      fields: { links },
      errors,
      loading,
      handleSetFocus,
      handleBulkChange,
      handleSaveBulk,
    } = this.props;

    return (
      <form>
        <div className={cn(viewStyles.form, urlStyles.form)}>
          <div className={formClassName(links, errors, viewStyles)}>
            <textarea
              name={links.name}
              onChange={handleBulkChange}
              className={cn(urlStyles.textarea, viewStyles.textarea)}
              value={links.value}
              ref={handleSetFocus}
              placeholder="http://example.com This is an optional title [new line]"
            />
            <FieldError data={links} apiData={errors} names="Urls" className={urlStyles.error} />
          </div>

          <div className={cn(viewStyles.row, viewStyles.lastRow)}>
            <button
              onClick={handleSaveBulk}
              className={cn(styles.getLink, loading && styles.loading)}
              disabled={loading}
            >
              Add to waitings
            </button>
            <ApiError data={errors} className={viewStyles.error} />
          </div>
        </div>
      </form>
    );
  }

  render() {
    const { handleChangeOption, isSavingOneLink } = this.props;

    return (
      <div>
        <div className={urlStyles.buttons}>
          <button
            onClick={() => {
              handleChangeOption(true);
            }}
            className={cn(isSavingOneLink && urlStyles.selected)}
          >
            Get & add
          </button>
          <button
            onClick={() => {
              handleChangeOption(false);
            }}
            className={cn(!isSavingOneLink && urlStyles.selected)}
          >
            Bulk adding
          </button>
        </div>

        {isSavingOneLink && this.renderOneLink()}
        {!isSavingOneLink && this.renderBulkAdding()}
      </div>
    );
  }
}

export default Url;
