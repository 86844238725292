import React from 'react';
import ViewComponent, { Left, Right, Filters } from '../../dashboard/View';
import FiltersSaved from '../../filters/Saved';
import Links from '../../links/Links';
import Switch from '../../details/Switch';

/**
 * Saved Component.
 */
const Saved = () => {
  return (
    <ViewComponent page="dashboard/saved" name="stored">
      <Filters>
        <FiltersSaved />
      </Filters>
      <Left>
        <Links
          displayOptions={{
            showUser: false,
          }}
          endpoint="saved"
        />
      </Left>
      <Right>
        <Switch fetched type="saved" />
      </Right>
    </ViewComponent>
  );
};

export default Saved;
