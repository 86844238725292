import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FormClass from '../../../abstracts/Form/Class';
import Validator from '../../../abstracts/Form/Validator';
import { bindRoutines } from '../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import * as Account from '../../../models/Account/Account.routines';
import { reset } from '../../../models/Account/Account.actions';
import PasswordComponent from './Password.component';
import PasswordChanged from './../PasswordChanged';

/**
 * Password Container.
 */
class Password extends FormClass {
  static initialState() {
    return {
      fields: {
        password: {
          name: 'password',
          value: '',
          error: null,
          focus: false,
        },
        rePassword: {
          name: 'rePassword',
          value: '',
          error: null,
          focus: false,
        },
      },
    };
  }

  componentWillUnmount() {
    this.props.reduxActions.reset();
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {
      password: [Validator.rules.required(), Validator.rules.length(6)],
      rePassword: [Validator.rules.required(), Validator.rules.theSame('password', this.getState)],
    };
  }

  submitForm() {
    const {
      password: { value: passwordValue },
      rePassword: { value: rePasswordValue },
    } = this.state.fields;

    const payload = {
      password: passwordValue,
      rePassword: rePasswordValue,
      token: (this.props.match && this.props.match.params && this.props.match.params.token) || null,
    };

    this.props.reduxActions.password(payload);
  }

  render() {
    return this.props.success ? (
      <PasswordChanged />
    ) : (
      <PasswordComponent
        fields={this.state.fields}
        handlers={this.handlers}
        loading={this.props.loading}
        errors={this.props.errors}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        password: Account.password.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        reset,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  errors: state.Account.password.errors,
  success: state.Account.password.success,
  loading: state.Account.loading,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Password)
);
