import React from 'react';
import { Link } from 'react-router-dom';
import View, { Body, Menu } from '../../../modules/account/View';
import FieldError from '../../../modules/errors/Field';
import ApiError from '../../../modules/errors/Api';
import { formClassName } from '../../../abstracts/Form/Utils';
import cn from 'classnames';
import viewStyles from '../../../modules/account/View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * Fields Component.
 */
const Fields = ({ handlers, fields: { fullName, password, rePassword }, errors, loading }) => {
  return (
    <View>
      <Menu>
        <li>
          <Link to="/">Log in</Link>
        </li>
      </Menu>
      <Body>
        <div className={viewStyles.form}>
          <h1>Fill your data and enjoy</h1>

          <div className={styles.description}>
            Creating your account you accept our <span>regulations</span>.
          </div>

          <form onSubmit={handlers.submit}>
            <div className={viewStyles.form}>
              <div className={formClassName(fullName, errors, viewStyles)}>
                <input
                  type="text"
                  className={viewStyles.input}
                  placeholder="Full name"
                  autoComplete="off"
                  name={fullName.name}
                  onChange={handlers.change}
                  value={fullName.value}
                />
                <FieldError
                  data={fullName}
                  apiData={errors}
                  names="Full name"
                  className={viewStyles.error}
                />
              </div>

              <div className={formClassName(password, errors, viewStyles)}>
                <input
                  type="password"
                  className={viewStyles.input}
                  placeholder="Password"
                  autoComplete="off"
                  name={password.name}
                  onChange={handlers.change}
                  value={password.value}
                />
                <FieldError
                  data={password}
                  apiData={errors}
                  names="Password"
                  className={viewStyles.error}
                />
              </div>

              <div className={formClassName(rePassword, errors, viewStyles)}>
                <input
                  type="password"
                  className={viewStyles.input}
                  placeholder="Retype password"
                  autoComplete="off"
                  name={rePassword.name}
                  onChange={handlers.change}
                  value={rePassword.value}
                />
                <FieldError
                  data={rePassword}
                  apiData={errors}
                  names={['Retyped password', 'password']}
                  className={viewStyles.error}
                />
              </div>

              <div className={cn(viewStyles.row, viewStyles.lastRow)}>
                <button onClick={handlers.submit} className={viewStyles.button} disabled={loading}>
                  Register
                </button>
                <ApiError data={errors} className={viewStyles.error} />
              </div>
            </div>
          </form>

          <div className={styles.singUp}>
            To see regulations click <Link to="/">here</Link>.
          </div>
        </div>
      </Body>
    </View>
  );
};

export default Fields;
