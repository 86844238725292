import { Component } from 'react';

const TIME = 120 * 1000;

class Statistics extends Component {
  componentDidUpdate(oldProps) {
    if (oldProps.logged !== this.props.logged && this.props.logged) {
      setInterval(() => {
        this.props.reduxActions.getStats();
      }, TIME);

      this.props.reduxActions.getStats();
    }
  }

  render() {
    return null;
  }
}

export default Statistics;
