import React, { Component } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import gravatar from '../../../globals/helpers/gravatar';
import ownStyles from './Input.module.scss';

/**
 * Input Component.
 */
class Input extends Component {
  constructor(props) {
    super(props);

    this.change = this.change.bind(this);
    this.trySend = this.trySend.bind(this);
    this.send = this.send.bind(this);
    this.setRef = this.setRef.bind(this);

    this.state = {
      showComment: false,
      comment: '',
    };
  }

  setRef(ref) {
    if (ref) {
      this.ref = ref;

      this.props.setInnerRef(this.ref);
    }
  }

  change(e) {
    this.setState({
      comment: e.target.value,
      showComment: e.target.value.length > 0,
    });
  }

  trySend(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.send();
    }
  }

  send() {
    this.props.reduxActions.addComment({
      snapId: this.props.snapId,
      comment: this.state.comment,
      markers: this.props.markers,
    });

    this.setState(
      {
        comment: '',
        showComment: false,
      },
      () => {
        this.props.handleResetMarkers();
      }
    );

    if (this.ref) {
      this.ref.value = '';
    }
  }

  render() {
    return (
      <div className={ownStyles.input}>
        <div className={ownStyles.user}>
          <img src={gravatar(this.props.user.email_hash)} alt="Add comment" />
        </div>

        <div className={ownStyles.text}>
          {this.props.markers.length > 0 && (
            <div className={ownStyles.reply}>
              <ul>
                {this.props.markers.map((marker) => (
                  <li key={marker.id}>
                    <button
                      onClick={() => {
                        this.props.handleRemoveMarker(marker.id);
                      }}
                    >
                      @{marker.full_name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <TextareaAutosize
            innerRef={this.setRef}
            maxRows={8}
            placeholder="Write comment"
            className="T"
            onChange={this.change}
            onKeyPress={this.trySend}
          />

          {this.state.showComment && (
            <button onClick={this.send} className={ownStyles.submit}>
              Comment
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default Input;
