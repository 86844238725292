import React from 'react';
import { dictionary } from '../../../globals/errors/dictionary';

/**
 * Error Api Component.
 */
const ErrorApi = ({ data, className, translate = {}, returnDefault = false }) => {
  const KEY = '@@API';

  if (data && Object.hasOwnProperty.call(data, KEY)) {
    const error = data[KEY][0];

    if (error) {
      let text = error;

      if (Object.hasOwnProperty.call(translate, error)) {
        text = translate[error];
      } else if (Object.hasOwnProperty.call(dictionary, error)) {
        text = dictionary[error];
      }

      return <div className={className}>{text}</div>;
    }
  }

  if (returnDefault) {
    return <div className={className}>Oops, something went wrong. Sorry :(</div>;
  }

  return null;
};

export default ErrorApi;
