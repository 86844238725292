import React from 'react';
import cn from 'classnames';
import styles from './../Main/Main.module.scss';
import Placeholder from './../Placeholder';

/**
 * Filters Grouped Component.
 */
const FiltersGrouped = ({
  selectedTags,
  phrase,
  newTag,
  where,
  handleClose,
  handleAddNewTag,
  handleRemoveTag,
  handleChange,
  handleClear,
  handleSetFilter,
  handleAddNewTagByEnter,
  handleSetFiltersByEnter,
  handleSetRef,
  handleChangeWhere,
}) => {
  return (
    <div className={styles.wrapper}>
      <Placeholder />
      <div className={styles.group}>
        <input
          type="text"
          name="phrase"
          placeholder="Phrase"
          value={phrase}
          onChange={handleChange}
          onKeyPress={handleSetFiltersByEnter}
          ref={handleSetRef}
        />
      </div>

      {selectedTags.length > 0 && (
        <div className={styles.tags}>
          <ul>
            <li className={styles.where}>
              <button onClick={handleChangeWhere}>{where === 'or' ? 'One of' : 'Each'}:</button>
            </li>
            {selectedTags.map((tag) => (
              <li key={tag} className={styles.tagButton}>
                {`#${tag}`}
                <button onClick={() => handleRemoveTag(tag)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={styles.group}>
        <input
          type="text"
          name="newTag"
          placeholder="Tags"
          value={newTag}
          onChange={handleChange}
          onKeyPress={handleAddNewTagByEnter}
        />
        <button onClick={handleAddNewTag} className={cn(styles.add, styles.withTooltip)}>
          <div className={styles.tooltip}>Space to add tag</div>
        </button>
      </div>

      <div className={styles.controls}>
        <div className={styles.left}>
          <button onClick={handleClear}>Clear</button>
        </div>
        <div className={styles.right}>
          <button onClick={handleClose}>Cancel</button>
          <button onClick={handleSetFilter} className={styles.blue}>
            {selectedTags.length > 0 || phrase.length > 0 ? 'Filter' : 'Show all'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FiltersGrouped;
