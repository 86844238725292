import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import View, { Body, Menu } from '../../account/View';
import viewStyles from '../View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * Password Changed Component.
 */
const PasswordChanged = () => {
  return (
    <View>
      <Menu>
        <li>
          <Link to="/">Log in</Link>
        </li>
      </Menu>
      <Body>
        <div className={cn(viewStyles.form, styles.createdBox)}>
          <h1>Password changed</h1>

          <div className={styles.description}>
            Now you can login with your email and new password.
          </div>

          <Link className={viewStyles.button} to="/">
            Sign in
          </Link>
        </div>
      </Body>
    </View>
  );
};

export default PasswordChanged;
