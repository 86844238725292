import { Component } from 'react';
import { songEnded, next } from '../../../models/Player/Player.actions';

class Listener extends Component {
  constructor(props) {
    super(props);

    this.interval = null;

    // eslint-disable-next-line consistent-return
    window.addEventListener('AS_CHANGE_STATUS', (event) => {
      const detail = event.detail;

      switch (detail.status) {
        case 0: // ended
          this.kill();

          return props.dispatch(songEnded());

        case -1:
          clearTimeout(this.interval);

          this.interval = setTimeout(() => {
            this.makeNext();
          }, 5000);

          return;

        default:
          this.kill();
      }
    });
  }

  makeNext() {
    this.props.dispatch(next());
  }

  kill() {
    clearTimeout(this.interval);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return null;
  }
}

export default Listener;
