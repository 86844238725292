import React, { Component } from 'react';
import { get, prepare, getProps } from '../../../abstracts/Sections';
import cn from 'classnames';
import styles from './Modal.module.scss';

/*
 * Sections
 */
export const { Title, Body, Footer } = prepare('Title', 'Body', 'Footer');

const emptyAction = () => {};

/**
 * Modal Component.
 */
class Modal extends Component {
  componentDidMount() {
    this.overflow = document.body.style.overflowY;

    document.body.style.overflowY = 'hidden';
    document.body.style.marginRight = '9px';
  }

  componentWillUnmount() {
    document.body.style.overflowY = this.overflow;
    document.body.style.marginRight = 0;
  }

  render() {
    const { children, handleClose = null, className, wide } = this.props;

    const { TitleSection, BodySection, FooterSection } = get(children, Title, Body, Footer);
    const { BodyProps, TitleProps } = getProps(children, Body, Title);

    return (
      <div className={styles.overlay} onClick={handleClose || emptyAction}>
        <div className={styles.close} />
        <div className={styles.logo}>AS</div>
        <div className={cn(styles.modal, className, 'R')}>
          <div
            className={cn(styles.contentWrapper, !wide && styles.short)}
            onClick={(e) => {
              e.stopPropagation();

              if (e.nativeEvent) {
                e.nativeEvent.stopImmediatePropagation();
              }
            }}
          >
            {TitleSection && (
              <div className={cn(TitleProps.variant ? styles.headerVariant : styles.header)}>
                <div className={styles.title}>
                  <div>{TitleSection}</div>
                </div>
              </div>
            )}
            <div className={cn(styles.body, BodyProps.className)}>{BodySection}</div>
            {FooterSection && <div className={styles.footer}>{FooterSection}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
