import React, { Component } from 'react';
import cn from 'classnames';
import settings from '../../../config/settings';
import Error from '../../errors/Api';
import * as AlertConstants from '../../../models/Alert/Alert.constants';
import styles from './Alert.module.scss';

/**
 * Alert Component.
 */
class Alert extends Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.setInterval = this.setInterval.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  componentDidMount() {
    this.setInterval();
  }

  setInterval() {
    this.interval = setTimeout(this.close, settings.alerts.duration);
  }

  clearInterval() {
    clearTimeout(this.interval);
  }

  close() {
    this.clearInterval();

    this.props.handleClose(this.props.data.alert.key);
  }

  render() {
    const { type } = this.props.data.alert;

    const isError = type === AlertConstants.TYPE_ERROR;
    const isSuccess = type === AlertConstants.TYPE_SUCCESS;
    const isInfo = type === AlertConstants.TYPE_INFO;

    const AlertClasses = {
      [styles.error]: isError,
      [styles.success]: isSuccess,
      [styles.info]: isInfo,
    };

    return (
      <div
        className={cn(styles.alert, AlertClasses)}
        onMouseEnter={this.clearInterval}
        onMouseLeave={this.setInterval}
      >
        <button className={styles.close} onClick={this.close} />
        {isError && (
          <Error className={styles.content} data={this.props.data.errors} returnDefault />
        )}
        {(isSuccess || isInfo) && <div className={styles.content}>{this.props.data.info}</div>}
      </div>
    );
  }
}

export default Alert;
