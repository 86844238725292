import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { preview } from '../../../models/Player/Player.actions';
import LinkComponent from './Link.component';

/*
 * Connection
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      preview,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(LinkComponent);
