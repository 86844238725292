import * as MODALS from '../../../models/Modals/Modals.types';

// Import Modals
import Confirm from '../types/Confirm';
import Followers from '../types/Followers';
import Group from '../types/Group';
import Members from '../types/Members';
import GroupMembers from '../types/GroupMembers';
import LinkAdd from '../types/LinkAdd';
import LinkEdit from '../types/LinkEdit';
import Added from '../types/Added';
import SpeedDial from '../types/SpeedDial';
import SelectGroup from '../types/SelectGroup';
import Thanks from '../types/Thanks';
import Share from '../types/Share';
import DeckSettings from '../types/DeckSettings';

/**
 * Modal List.
 */
const ModalList = {
  [MODALS.CONFIRM]: Confirm,

  [MODALS.FOLLOWERS]: Followers,

  [MODALS.GROUP_ADD]: Group,
  [MODALS.GROUP_ADD_MEMBER]: Members,
  [MODALS.GROUP_MEMBERS]: GroupMembers,
  [MODALS.GROUP_SELECT]: SelectGroup,

  [MODALS.LINK_ADD]: LinkAdd,
  [MODALS.LINK_EDIT]: LinkEdit,
  [MODALS.LINK_SHARE]: Share,

  [MODALS.ADDED]: Added,

  [MODALS.SPEED_DIAL]: SpeedDial,

  [MODALS.THANKS_LIST]: Thanks,

  [MODALS.DECK_SETTINGS]: DeckSettings,
};

export default ModalList;
