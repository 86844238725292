import React from 'react';
import cn from 'classnames';
import NoGroups from '../../noItems/NoGroups';
import Pagination from '../../template/Pagination';
import styles from './Groups.module.scss';
import Group from './../Group';
import Loader from './../Loader';

/**
 * Groups Component.
 */
const Groups = ({
  loading,
  groups,
  pagination,
  openedGroupId,
  ownerId,
  handleInnerRef,
  handleAddMembers,
  handleEditName,
  handleShowMembers,
  handleDelete,
  handleLeave,
  onlyOneGroup,
}) => {
  if (groups.length < 1 && loading) {
    return <Loader />;
  }

  if (groups.length < 1 && !loading) {
    return <NoGroups />;
  }

  return (
    <div className={cn(styles.scroller, 'S')} ref={handleInnerRef}>
      <div className={cn(styles.groups, loading && styles.loading)}>
        <ul className={cn(styles.ul, onlyOneGroup && styles.bigUl)}>
          {groups.map((group) => (
            <li key={group.id}>
              <Group
                data={group}
                openedGroupId={openedGroupId}
                ownerId={ownerId}
                handleAddMembers={handleAddMembers}
                handleEditName={handleEditName}
                handleShowMembers={handleShowMembers}
                handleDelete={handleDelete}
                handleLeave={handleLeave}
              />
            </li>
          ))}
        </ul>
        {!loading && (
          <div className={styles.pagination}>
            <Pagination pages={pagination.pages} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Groups;
