import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import settings from '../../../config/settings';

/**
 * Public Route Component.
 */
const PublicRoute = ({ component: Component, isAuthenticated, redirect, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={redirect || settings.auth.mainRoute} />
      )
    }
  />
);

export default PublicRoute;
