import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFilters } from '../../../models/Filters/Filters.actions';
import { TAG } from '../../../models/Filters/Filters.constants';
import TagsComponent from './Tags.component';

/**
 * Tags Container.
 */
class Tags extends Component {
  constructor(props) {
    super(props);

    this.setFilters = this.setFilters.bind(this);
  }

  setFilters(tag) {
    this.props.reduxActions.setFilters([
      {
        type: TAG,
        value: tag,
      },
    ]);
  }

  render() {
    if (!this.props.data) {
      return null;
    }

    return <TagsComponent data={this.props.data} handleClick={this.setFilters} />;
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      setFilters,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(Tags);
