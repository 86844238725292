import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as App from '../../../models/App/App.constants';
import { openModal } from '../../../models/Modals/Modals.actions';
import * as MODALS from '../../../models/Modals/Modals.types';
import { FOLLOW, INVITE } from '../../../models/Followers/Followers.constants';
import MenuComponent from './Menu.component';

/**
 * Menu Container.
 */
const Menu = ({ sizes, reduxActions, ...rest }) => {
  return (
    <MenuComponent
      {...rest}
      small={sizes.height === App.SMALL || sizes.width === App.SMALL}
      handleOpenFollowersInvite={() =>
        reduxActions.openModal({
          type: MODALS.FOLLOWERS,
          payload: {
            type: INVITE,
          },
        })
      }
      handleOpenFollowersFollow={() =>
        reduxActions.openModal({
          type: MODALS.FOLLOWERS,
          payload: {
            type: FOLLOW,
          },
        })
      }
    />
  );
};

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  sizes: state.App.sizes,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      openModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
