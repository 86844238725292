import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ThanksRoutines from '../../../models/Thanks/Thanks.routines';
import * as ThanksActions from '../../../models/Thanks/Thanks.actions';
import { bindRoutines } from '../../../abstracts/Routine';
import Item from '../Item';
import styles from '../Item/Item.module.scss';
import { bindActionCreators } from 'redux';

/**
 * List Container.
 */
class List extends Component {
  componentDidMount() {
    this.props.reduxActions.getThanks();
  }

  render() {
    const { thanks, nextPage, loading } = this.props;

    if (thanks.length < 1 && !loading) {
      return <div>Nobody say thank for you. Share links for user and be more friendly.</div>;
    }

    return (
      <Fragment>
        <div>
          {thanks.map((item) => (
            <li key={item.id}>
              <Item data={item} />
            </li>
          ))}
        </div>
        {nextPage && (
          <div className={styles.more}>
            <button
              onClick={() => {
                this.props.reduxActions.loadMore();
              }}
            >
              Load more
            </button>
          </div>
        )}
      </Fragment>
    );
  }
}

/*
 * Connection.
 */
const mapStateToProps = (state) => ({
  loading: state.Thanks.loading,
  thanks: state.Thanks.data,
  nextPage: state.Thanks.nextPage,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        getThanks: ThanksRoutines.getThanks.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        loadMore: ThanksActions.loadMore,
      },
      dispatch
    ),
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(List)
);
