import { connect } from 'react-redux';
import BarComponent from './Bar.component';

const mapStateToProps = (state) => {
  const { selected, group } = state.Filters;

  return {
    showPlaceholder: !((selected && selected.length > 0) || group),
  };
};

export default connect(mapStateToProps)(BarComponent);

export { Menu, Filters } from './Bar.component';
