import React from 'react';
import cn from 'classnames';
import styles from './../Main/Main.module.scss';
import Placeholder from './../Placeholder';

/**
 * Loader Component.
 */
const Loader = () => {
  const count = Math.random() * 5 + 5;
  const arr = [];

  for (let i = 0; i < count; i += 1) {
    arr.push(<li key={i} className={styles.fake} style={{ width: 40 + Math.random() * 40 }} />);
  }

  return arr;
};

/**
 * Filters Saved Component.
 */
const FiltersSaved = ({
  loading,
  tags,
  selectedTags,
  phrase,
  host,
  newTag,
  where,
  handleClose,
  handleAddTag,
  handleAddNewTag,
  handleRemoveTag,
  handleChange,
  handleClear,
  handleSetFilter,
  handleAddNewTagByEnter,
  handleSetFiltersByEnter,
  handleSetRef,
  handleChangeWhere,
}) => {
  const re = new RegExp(`^${newTag}`, 'i');

  return (
    <div className={styles.wrapper}>
      <Placeholder />
      <div className={styles.group}>
        <input
          type="text"
          name="phrase"
          placeholder="Phrase"
          value={phrase}
          onChange={handleChange}
          onKeyPress={handleSetFiltersByEnter}
          ref={handleSetRef}
        />
      </div>
      <div className={styles.group}>
        <input
          type="text"
          name="host"
          placeholder="Host"
          value={host}
          onChange={handleChange}
          onKeyPress={handleSetFiltersByEnter}
        />
      </div>

      {selectedTags.length > 0 && (
        <div className={styles.tags}>
          <ul>
            <li className={styles.where}>
              <button onClick={handleChangeWhere}>{where === 'or' ? 'One of' : 'Each'}:</button>
            </li>
            {selectedTags.map((tag) => (
              <li key={tag} className={styles.tagButton}>
                {`#${tag}`}
                <button onClick={() => handleRemoveTag(tag)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={styles.group}>
        <input
          type="text"
          name="newTag"
          placeholder="Tags"
          value={newTag}
          onChange={handleChange}
          onKeyPress={handleAddNewTagByEnter}
        />
        <button onClick={handleAddNewTag} className={cn(styles.add, styles.withTooltip)}>
          <div className={styles.tooltip}>Space to add tag</div>
        </button>
      </div>

      <div className={styles.controls}>
        <div className={styles.left}>
          <button onClick={handleClear}>Clear</button>
        </div>
        <div className={styles.right}>
          <button onClick={handleClose}>Cancel</button>
          <button onClick={handleSetFilter} className={styles.blue}>
            {selectedTags.length > 0 || phrase.length > 0 || host.length > 0
              ? 'Filter'
              : 'Show all'}
          </button>
        </div>
      </div>

      <div className={styles.list}>
        <h3>Used tags</h3>

        <ul>
          {loading && <Loader />}

          {!loading &&
            tags
              .filter((tag) => tag.match(re))
              .map((tag) => (
                <li key={tag}>
                  <button onClick={() => handleAddTag(tag)}>{`#${tag}`}</button>
                </li>
              ))}
        </ul>
      </div>
    </div>
  );
};

export default FiltersSaved;
