import config from '../../config/config';
import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { concat, of, race } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import getHeaders from '../../globals/helpers/headers';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api/index';
import { addAsSuccess } from '../Alert/Alert.actions';
import * as Report from './Report.routines';

export const report = (action$, state$) => {
  const routine = Report.report;
  const loading = !state$.value.Report.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      const { id } = action.payload;

      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}reports/${id}`,
            method: 'POST',
            headers: getHeaders(),
          }).pipe(
            mergeMap(
              whenSuccess(
                routine,
                action,
                of(addAsSuccess(`Thank you. Your report will be investigate as soon as possible.`))
              )
            ),
            catchError(whenFailure(routine, null, true))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};
