/* eslint-disable class-methods-use-this */
import { Component } from 'react';
import withValue from '../../../globals/helpers/withValue';
import queryString from 'query-string';

/**
 * Container.
 */
class Controller extends Component {
  static initialState() {
    return {};
  }

  constructor(props) {
    super(props);

    this.state = this.constructor.initialState();

    this.redirect = false;

    this.setVars();
    this.bindings();
  }

  componentDidMount() {
    this.props.reduxActions.resetFilters();
  }

  componentWillReceiveProps(props) {
    const redirected = this.redirect;

    this.redirect = false;

    if (props.forcedRefreshTime !== this.props.forcedRefreshTime) {
      this.prepareLoad(props, {
        withReload: true,
      });
    } else if (props.filters !== this.props.filters) {
      this.redirect = true;
    } else if (props.match !== this.props.match) {
      if (props.match.params && !props.match.params.page) {
        if (props.location.search) {
          this.prepareLoad(props, {
            withReload: true,
          });
        } else {
          this.props.reduxActions.resetFilters();
        }
      } else if (redirected) {
        this.prepareLoad(props, {
          withReload: true,
        });
      } else {
        this.prepareLoad(props);
      }
    }

    this.extendedReceiveProps(props);
  }

  setVars() {}

  prepareLoad(props, options = {}) {
    if (this.ref) {
      this.ref.scrollTop = 0;
    }

    const query = props.location.search ? queryString.parse(props.location.search) : {};

    const payload = withValue({
      ...query,
      page: props.match.params.page || null,
      ...options,
    });

    this.load(payload);
  }

  load() {}

  bindings() {}

  extendedReceiveProps() {}
}

export default Controller;
