import React from 'react';
import { connect } from 'react-redux';
import FormClass from '../../../abstracts/Form/Class';
import Validator from '../../../abstracts/Form/Validator';
import { bindRoutines } from '../../../abstracts/Routine';
import * as User from '../../../models/User/User.routines';
import PasswordComponent from './Password.component';

/**
 * Password Container.
 */
class PasswordContainer extends FormClass {
  static initialState() {
    return {
      fields: {
        password: {
          name: 'password',
          value: '',
          error: null,
          focus: false,
        },
        rePassword: {
          name: 'rePassword',
          value: '',
          error: null,
          focus: false,
        },
        currentPassword: {
          name: 'currentPassword',
          value: '',
          error: null,
          focus: false,
        },
      },
    };
  }

  setValidators() {
    return {
      currentPassword: [Validator.rules.required(), Validator.rules.length(6)],
      password: [Validator.rules.required(), Validator.rules.length(8)],
      rePassword: [Validator.rules.required(), Validator.rules.theSame('password', this.getState)],
    };
  }

  submitForm() {
    const {
      password: { value: passwordValue },
      rePassword: { value: rePasswordValue },
      currentPassword: { value: currentPasswordValue },
    } = this.state.fields;

    const payload = {
      password: passwordValue,
      rePassword: rePasswordValue,
      currentPassword: currentPasswordValue,
    };

    if (this.isDirty()) {
      this.setState(this.constructor.initialState(), () => {
        this.props.reduxActions.changePassword(payload);
      });
    }
  }

  render() {
    return (
      <PasswordComponent
        fields={this.state.fields}
        handlers={this.handlers}
        loading={this.props.loading}
        errors={this.props.errors}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      changePassword: User.changePassword.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  errors: state.User.errors,
  loading: state.User.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordContainer);
