/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import config from '../../config/config';
import { ofType } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as Thanks from './Thanks.routines';
import * as THANKS from './Thanks.constants';

export const getThanks = epic(
  Thanks.getThanks,
  (action) =>
    `${config.apiHost}thanks${
      action.payload && action.payload.from ? `?from=${action.payload.from}` : ''
    }`,
  false,
  'Thanks',
  false,
  false,
  true
);

export const loadMore = (action$, state$) => {
  return action$.pipe(
    ofType(THANKS.LOAD_MORE),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const data = state.Thanks.data;

      const from = data.reduce((prev, curr) => {
        return Math.min(prev, curr.id);
      }, Number.MAX_SAFE_INTEGER);

      return [Thanks.getThanks.trigger({ from })];
    })
  );
};
