import React, { Component } from 'react';
import { bindRoutines } from '../../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Groups from '../../../../models/Groups/Groups.routines';
import * as ModalsActions from '../../../../models/Modals/Modals.actions';
import SelectComponent from './SelectGroup.component';

/**
 * SelectGroup Container.
 */
class SelectGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
    };

    this.tryClose = this.tryClose.bind(this);
    this.setRef = this.setRef.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
  }

  componentDidMount() {
    if (!this.props.added) {
      this.props.reduxActions.getAllGroups();
    }
  }

  changeSearch(e) {
    this.setState({
      search: e.target.value,
    });
  }

  setRef(ref) {
    if (ref) {
      ref.focus();
    }
  }

  tryClose() {
    this.props.reduxActions.closeAllModals();
  }

  render() {
    return (
      <SelectComponent
        loading={this.props.loading}
        groups={this.props.groups}
        group={this.props.group}
        added={this.props.added}
        search={this.state.search}
        handleCloseModal={this.tryClose}
        handleSetRef={this.setRef}
        handleChangeSearch={this.changeSearch}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        addMembers: Groups.addMembers.trigger,
        getAllGroups: Groups.getAllGroups.trigger,
      },
      dispatch
    ),

    ...bindActionCreators(
      {
        closeAllModals: ModalsActions.closeAllModals,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Groups.loading,
  groups: state.Groups.all.data,
  added: state.Groups.all.added,
  group: state.Filters.group,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectGroup);
