import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDefaultPlaylist } from '../../../models/Player/Player.actions';
import { getPreview } from '../../../models/Player/Player.selectors';
import Preview from './Preview.component';

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  video: getPreview(state),
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      setDefaultPlaylist,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preview);
