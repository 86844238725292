import React from 'react';
import FormClass from '../../../../abstracts/Form/Class';
import { bindRoutines } from '../../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Validator from '../../../../abstracts/Form/Validator';
import * as ModalsActions from '../../../../models/Modals/Modals.actions';
import { addGroup, editGroup } from '../../../../models/Groups/Groups.routines';
import { reset } from '../../../../models/Groups/Groups.actions';
import { withRouter } from 'react-router-dom';
import mapLocationToObject from '../../../../globals/helpers/mapLocationToObject';
import GroupComponent from './Group.component';

/**
 * Group Container.
 */
class Group extends FormClass {
  static initialState(props) {
    return {
      fields: {
        name: {
          name: 'name',
          value: (props && props.payload && props.payload.group && props.payload.group.name) || '',
          error: null,
          focus: false,
        },
      },
    };
  }

  componentDidMount() {
    if (this.refName) {
      this.refName.focus();
    }
  }

  setVars(props) {
    this.editMode = !!(props && props.payload && props.payload.group);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.responseReceived !== this.props.responseReceived && this.props.responseReceived) {
      this.props.reduxActions.reset();
      this.props.reduxActions.closeAllModals();
    }
  }

  bindings() {
    this.tryClose = this.tryClose.bind(this);
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {
      name: [Validator.rules.required(), Validator.rules.length(3)],
    };
  }

  submitForm() {
    const payload = {
      id:
        this.props && this.props.payload && this.props.payload.group && this.props.payload.group.id,
      name: this.state.fields.name.value,
      reload: mapLocationToObject(this.props.location, false),
    };

    if (this.editMode) {
      this.props.reduxActions.editGroup(payload);
    } else {
      this.props.reduxActions.addGroup(payload);
    }
  }

  tryClose(event) {
    const closeModalWithConfirm = ModalsActions.wrapWithConfirm(
      this.props.reduxActions.dispatch,
      ModalsActions.closeModal,
      `Do you want to abort ${this.editMode ? 'editing' : 'adding'} group?`
    );

    closeModalWithConfirm(event, this.isDirty());
  }

  render() {
    return (
      <GroupComponent
        fields={this.state.fields}
        handlers={this.handlers}
        loading={this.props.loading}
        errors={this.props.errors}
        editMode={this.editMode}
        handleCloseModal={this.tryClose}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    dispatch,

    ...bindRoutines(
      {
        addGroup: addGroup.trigger,
        editGroup: editGroup.trigger,
      },
      dispatch
    ),

    ...bindActionCreators(
      {
        reset,
        closeAllModals: ModalsActions.closeAllModals,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Groups.loading,
  responseReceived: state.Groups.responseReceived,
  response: state.Groups.response,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Group)
);
