import React from 'react';
import DialogMenu, { Button, Content, Separator } from '../../template/Menu';
import * as MODALS from '../../../models/Modals/Modals.types';
import version from '../../../version';
import { Link } from 'react-router-dom';
import config from '../../../config/config';
import cn from 'classnames';
import Logout from './../Logout';
import User from './../User';
import styles from './RightBox.module.scss';
import Thanks from '../../thanks/List';

const showNotifications = false;

const RightBox = ({ openModal, user, newThanksReady }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.apps}>
        <button
          onClick={() => {
            openModal({
              type: MODALS.SPEED_DIAL,
            });
          }}
          className={styles.button}
        >
          <span className="icomoon icon-dashboard" />
          <em>Speed dial</em>
        </button>

        <DialogMenu>
          <Button className={cn(styles.button, newThanksReady && styles.ready)}>
            <span className="icomoon icon-thumb_up_off_alt" />
            <em>Thanks list</em>
          </Button>
          <Content className={cn(styles.thanksList, 'T')}>
            <Thanks />
          </Content>
        </DialogMenu>
      </div>

      <div className={styles.user}>
        <DialogMenu>
          <Button className={styles.userDetails}>
            <User notifications={0} />
          </Button>
          <Content className={styles.userMenu}>
            <div className={styles.userName}>Hi, {user.full_name}!</div>

            <ul>
              <li>
                <Link className={styles.link} to="/settings">
                  <span className="icomoon icon-settings" />
                  Account settings
                </Link>
              </li>
              {showNotifications && (
                <li>
                  <Link className={styles.link} to="/notification">
                    <span className="icomoon icon-notifications_none" />
                    Notifications <strong>5 new</strong>
                  </Link>
                </li>
              )}
              <li className={styles.button}>
                <Link className={styles.upgrade} to="/upgrade">
                  Upgrade your plan
                </Link>
              </li>
              <Separator />
              <li>
                <Link className={styles.link} to="/get-app">
                  <span className="icomoon icon-cloud_download" />
                  Download browser extension
                </Link>
              </li>
              <li>
                <a
                  href={`${config.mainHost}faq`}
                  rel="noreferrer noopener"
                  target="_blank"
                  className={styles.link}
                >
                  <span className="icomoon icon-help_outline" />
                  Help center
                </a>
              </li>
              <Separator />
              <li className={styles.button}>
                <Logout className={styles.logout} />
              </li>
              <li>
                <div className={styles.copyright}>
                  <div>&copy; 2020 AS</div>
                  <div>vs: {version}</div>
                </div>
              </li>
            </ul>
          </Content>
        </DialogMenu>
      </div>
    </div>
  );
};

export default RightBox;
