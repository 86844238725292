export const ADD = 'PLAYER__ADD';
export const ADD_ALL = 'PLAYER__ADD_ALL';
export const BLINK = 'PLAYER__BLINK';
export const CHANGE_REPEAT_MODE = 'PLAYER__CHANGE_REPEAT_MODE';
export const CHANGE_SHUFFLE_MODE = 'PLAYER__CHANGE_SHUFFLE_MODE';
export const CLEAR = 'PLAYER__CLEAR';
export const HIDE = 'PLAYER__HIDE';
export const NEXT = 'PLAYER__NEXT';
export const PLAY = 'PLAYER__PLAY';
export const PREV = 'PLAYER__PREV';
export const PREVIEW = 'PLAYER__PREVIEW';
export const REMOVE = 'PLAYER__REMOVE';
export const REPLACE_PLAYLIST = 'PLAYER__REPLACE_PLAYLIST';
export const SET_DEFAULT_PLAYLIST = 'PLAYER__SET_DEFAULT_PLAYLIST';
export const SET_TAG = 'PLAYER__SET_TAG';
export const SONG_ENDED = 'PLAYER__SONG_ENDED';
export const STOP = 'PLAYER__STOP';
export const TOGGLE_HEIGHT = 'PLAYER__TOGGLE_HEIGHT';
