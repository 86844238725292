import React from 'react';
import ViewComponent, { Center as CenterSection } from '../../dashboard/View';
import UpgradeComponent from '../Upgrade';

/**
 * Upgrade Component.
 */
const Upgrade = () => {
  return (
    <ViewComponent page="upgrade" simple hide={true}>
      <CenterSection>
        <UpgradeComponent />
      </CenterSection>
    </ViewComponent>
  );
};

export default Upgrade;
