/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import ListComponent from '../../users/List';
import { bindRoutines } from '../../../abstracts/Routine';
import { connect } from 'react-redux';
import { getBanned, removeFromList } from '../../../models/DarkList/DarkList.routines';
import { bindActionCreators } from 'redux';
import * as ModalsActions from '../../../models/Modals/Modals.actions';
import { addAsSuccess } from '../../../models/Alert/Alert.actions';
import renderUser from './DarkList.render';

/**
 * DarkList Component
 */
class DarkList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
    };

    this.renderPagination = this.renderPagination.bind(this);
    this.open = this.open.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    this.open(1, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.response !== this.props.response && this.props.response) {
      this.prepareAlerts();
    }
  }

  prepareAlerts() {
    const counter = this.state.selected.length;

    this.setState({
      selected: [],
    });

    if (counter > 0) {
      this.props.reduxActions.addAsSuccess(`You have removed (${counter}) users from dark list.`);

      const loadPage = this.props.pagination.current;

      if (counter === this.props.data.length) {
        this.open(Math.max(1, loadPage - 1), true);
      } else {
        this.open(loadPage, true);
      }
    }
  }

  selectChange(id) {
    const found = this.state.selected.find((_id) => _id === id);

    if (found) {
      this.setState({
        selected: this.state.selected.filter((_id) => _id !== id),
      });
    } else {
      this.setState({
        selected: [...this.state.selected, id],
      });
    }
  }

  selectAll() {
    if (this.state.selected.length === this.props.data.length) {
      this.setState({
        selected: [],
      });
    } else {
      this.setState({
        selected: this.props.data.map((row) => row.user.id),
      });
    }
  }

  open(page, withReload = false) {
    this.props.reduxActions.getBanned({
      page,
      withReload,
    });
  }

  remove(event) {
    const closeModalWithConfirm = ModalsActions.wrapWithConfirm(
      this.props.reduxActions.dispatch,
      removeFromList.trigger,
      'Do you want to remove from dark list?',
      false
    );

    closeModalWithConfirm(event, true, {
      ids: this.state.selected,
    });
  }

  renderPagination(page, name) {
    return (
      <button
        onClick={() => {
          this.open(page);
        }}
      >
        {name}
      </button>
    );
  }

  render() {
    return (
      <ListComponent
        data={this.props.data}
        loading={this.props.loading}
        pagination={this.props.pagination}
        added={this.props.added}
        ownerId={-1}
        render={this.renderPagination}
        renderUser={renderUser}
        selected={this.state.selected}
        handleSelectChange={this.selectChange}
        handleSelectAll={this.selectAll}
        handleRemove={this.remove}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    dispatch,

    ...bindActionCreators(
      {
        addAsSuccess,
      },
      dispatch
    ),

    ...bindRoutines(
      {
        getBanned: getBanned.trigger,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.DarkList.loading,
  data: state.DarkList.data,
  pagination: state.DarkList.pagination,
  added: state.DarkList.added,
  response: state.DarkList.response,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DarkList);
