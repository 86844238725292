/**
 * Validator Object.
 */
const Validator = {};

Validator.rules = {
  /**
   * Field is required.
   *
   * @returns string|null
   */
  required: () => (value) => {
    if (!Validator.get.is(value)) {
      return '%s is required';
    }

    return null;
  },

  /**
   * Field is checked.
   *
   * @returns string|null
   */
  checked: () => (value) => {
    if (value !== true) {
      return '%s is required';
    }
    return null;
  },

  /**
   * Field has to have specified length.
   *
   * @param min
   * @param max
   *
   * @returns string|null
   */
  length: (min = 1, max = null) => (value) => {
    const l = Validator.get.length(value);

    if (max) {
      if (l > max) {
        return '%s is to long';
      }
    }

    if (l < min) {
      return '%s is to short';
    }

    return null;
  },

  /**
   * Field has to be the same as other field.
   *
   * @param field
   * @param getState
   *
   * @returns string|null
   */
  theSame: (field, getState) => (value) => {
    // Check with field in state
    if (getState) {
      const state = getState();

      if (state.fields && state.fields[field]) {
        if (state.fields[field].value === value) {
          return null;
        }

        return '%s should be the same as %s';
      }

      return '%s cannot be matched with unknown field';
    }

    // Check value
    if (value === field) {
      return null;
    }

    return '%s is incorrect';
  },

  /**
   * Check if given value is correct email address.
   *
   * @returns string|null
   */
  email: () => (value) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(value).toLowerCase())) {
      return null;
    }

    return 'This is not email address';
  },

  /**
   * Check if given value is concatenation of a few emails with specified separating string.
   *
   * @param count
   * @returns {Function}
   */
  emails: (count = 5) => (value) => {
    let parts = [];

    if (value instanceof Array) {
      parts = value;
    } else {
      parts = value.split(/[\s,;]+/gi);
    }

    if (count >= 0 && parts.length > count) {
      return `Too many emails. Max number is ${count}`;
    }

    const emailValidator = Validator.rules.email();

    const result = parts.reduce((last, part) => {
      return last && emailValidator(part) === null;
    }, true);

    return result ? null : 'One of emails is incorrect';
  },

  /**
   * Check if given value is valid url.
   *
   * @returns {Function}
   */
  url: () => (value) => {
    try {
      new URL(value);

      return null;
    } catch (_) {
      return 'This is not valid URL';
    }
  },
};

Validator.get = {
  length: (value) => (value ? value.toString().trim().length : 0),

  is: (value) => Validator.get.length(value) > 0,
};

export default Validator;
