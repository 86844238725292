import { connect } from 'react-redux';
import List from './List.component';

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  videos: state.Player.defaultList,
  playingId: state.Player.playingId,
});

export default connect(mapStateToProps)(List);
