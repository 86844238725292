import { of, concat } from 'rxjs';
import mapError, { Map, DEFAULT_ERROR, RETURN, USE_VALUE, INHERIT } from '../errors/codes';

/**
 * When failure.
 */
const whenFailure = (routine, action = null, forcedAlert = false) => (response) => {
  let extendedResponse = {};
  const status = (response && response.status) || null;
  const error = mapError(status);

  const apiResponse = { ...response };

  switch (error.type) {
    case RETURN:
      return error.value;

    case USE_VALUE:
      apiResponse.response = {
        ...apiResponse,
        errors: { ...error.value },
        alert: true,
      };

      break;

    case INHERIT:
      /*
       * When we have error and response does not contain errors property,
       * we will create artificial object and use it insted native from response.
       */
      if (!(response.response && response.response.errors)) {
        extendedResponse = {
          errors: { ...Map[DEFAULT_ERROR].value },
        };
      }

      apiResponse.response = {
        ...apiResponse.response,
        ...extendedResponse,
        alert: forcedAlert,
      };

      break;

    default:
      break;
  }

  return concat(of(routine.failure(apiResponse, action)), of(routine.fulfill()));
};

export default whenFailure;
