import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// Import RxJS
import 'rxjs';

// Global CSS
import './globals/styles/body.scss';

// Portable
import Modals from './modules/modals/Modal';
import ProgressBar from './modules/ProgressBar';
import Alerts from './modules/alerts/Manager';
import Resizer from './modules/Resizer';

// Auto Auth
import AutoAuth from './modules/AutoAuth';

// Player
import Player from './modules/player/Indicator';

//
import Statistics from './modules/Statistics';

// Routing
import MainRoutes from './routes';

// Store
import configureStore from './store/configureStore';

// Configure store
const store = configureStore();

/**
 * Render DOM.
 */
render(
    <Provider store={store}>
        <Fragment>
            <BrowserRouter>
                <Fragment>
                    <AutoAuth>
                        <MainRoutes />
                    </AutoAuth>

                    <ProgressBar />
                    <Modals />
                    <Alerts />
                    <Resizer />
                </Fragment>
            </BrowserRouter>

            <Player />
            <Statistics />
        </Fragment>
    </Provider>,
    document.getElementById('app')
);

