import React from 'react';
import TagsLoader from '../TagsLoader';
import settings from '../../../config/settings';
import cn from 'classnames';
import styles from '../styles/Common.module.scss';

/**
 * Tags Component.
 */
const Tags = ({
  loading,

  is,
  tags,
  usedTags,
  tag: tagInput,
  prepareFilterFunction,

  handleChange,
  handleTryToAdd,
  handleKeyPress,
  handleKeyDown,
  handleAdd,
  handleRemove,

  handleSetRef,

  small,
}) => (
  <div className={styles.tags}>
    <div className={cn(usedTags.length > 0 && styles.tagList)}>
      <ul className={styles.basicList}>
        {usedTags.map((tag) => (
          <li key={tag}>
            <button
              onClick={() => {
                handleRemove(tag);
              }}
            >
              #{tag}
            </button>
          </li>
        ))}
      </ul>
    </div>

    <div className={styles.addTags}>
      <input
        type="text"
        className={styles.tag}
        placeholder={`${is !== 'group' ? 'Private' : 'Grouped'} tags`}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        value={tagInput}
        maxLength={32}
        ref={handleSetRef}
      />
      <button
        onClick={() => {
          handleTryToAdd();
        }}
        className={styles.addTag}
      >
        Add tag
      </button>
      <div className={styles.info}>
        <div className={styles.inner}>
          <h4>You can use tags to make special effects. List is below:</h4>
          <ul>
            <li>
              <span>#speedial</span> - add tag to speed dial
            </li>
          </ul>
        </div>
      </div>
      {usedTags.length > 0 && (
        <div className={styles.selectedTags}>
          <strong>{settings.howMany.tags - usedTags.length}</strong> tags left
        </div>
      )}
    </div>

    {!loading && tags.length > 0 && (
      <div className={cn(styles.userTags)}>
        <ul>
          {tags.filter(prepareFilterFunction()).map((tag) => (
            <li key={tag}>
              <button
                onClick={() => {
                  handleAdd(tag);
                }}
              >
                #{tag}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )}

    <TagsLoader loading={loading} />
  </div>
);

export default Tags;
