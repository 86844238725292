import React, { Component } from 'react';
import hydrate from '../../../globals/helpers/hydrate';
import cn from 'classnames';
import styles from './Loader.module.scss';

const LoaderItem = () => {
  const descs = hydrate(6, 20);

  return (
    <div className={cn(styles.wrapper, 'S')}>
      <div className={styles.loader}>
        <div className={styles.header}>
          <div />
          <div />
        </div>
        <div className={styles.title} style={{ width: `${30 + Math.random() * 40}%` }} />
        <div className={styles.info} style={{ width: `${60 + Math.random() * 30}%` }} />
        <div className={styles.image} style={{ height: 260 + Math.random() * 80 }} />
        <div className={styles.descs}>
          {descs.map((i) => (
            <div key={i} style={{ width: 50 + Math.random() * 50 }} />
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * Loader Component.
 */
class Loader extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <LoaderItem />;
  }
}

export default Loader;
