/* eslint-disable no-bitwise */
import React, { Fragment } from 'react';
import moment from 'moment';
import getDate from '../../../globals/helpers/getDate';
import cn from 'classnames';
import styles from './When.module.scss';
import Menu, { Button as ButtonWrapper, Content } from '../../template/Menu';
import Button from '../../../shared/Button';

const TEXTS = [
  'Today',
  'Yesterday',
  '2 days ago',
  '3 days ago',
  '4 days ago',
  '5 days ago',
  '6 days ago',
];

/**
 * Get days.
 *
 * @param date
 * @returns {number}
 */
const getDays = (date) => {
  const d = new Date();
  d.setTime(Date.now());
  const midnight = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1, 0, 0, 0, 0);

  const m = (midnight.getTime() / 1000) >> 0;

  const delta = m - date;

  return (delta / 86400) >> 0;
};

/**
 * When Component.
 */
const When = ({ now, last, index, refresh }) => {
  const nowUnix = moment
    .utc(now)
    .local()
    .unix();

  const days = getDays(nowUnix);
  let lastDays = null;

  if (last) {
    lastDays = getDays(
      moment
        .utc(last)
        .local()
        .unix()
    );
  }

  if (days === lastDays) {
    return null;
  }

  const realDate = getDate(nowUnix);

  return (
    <div className={cn(styles.date, 'when-date')}>
      {days > 6 && <span className={styles.big}>{realDate}</span>}

      {days <= 6 && (
        <Fragment>
          <span className={styles.big}>{TEXTS[days]}</span>
          <span className={styles.small}>{realDate}</span>
        </Fragment>
      )}

      {index === 0 && refresh && (
        <div className={styles.menuWrapper}>
          <Menu>
            <ButtonWrapper>
              <Button icon="more_vert" onlyIcon component="div" />
            </ButtonWrapper>
            <Content className={styles.menu}>
              <ul>
                <li>
                  <button
                    onClick={() => {
                      refresh();
                    }}
                  >
                    <span className="icomoon icon-loopsync" /> Refresh
                  </button>
                </li>
              </ul>
            </Content>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default When;
