import { createStore, compose, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import config from '../config/config';
import rootEpic from './rootEpic';
import rootReducer from './rootReducer';

/**
 * Create Epic Middleware.
 */
const epicMiddleware = createEpicMiddleware();

/**
 * Enhancers.
 */
/* eslint-disable no-underscore-dangle */
let composeEnhancers = compose;

if (config.env === 'development') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
}

export default function configureStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}
