import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styles from './404.module.scss';

/**
 * Error404 Component.
 */
const Error404 = ({ location }) => (
  <Fragment>
    <div className={styles.error404}>
      <div className={styles.logo}>
        <Link to="/" className={styles.logoLink}>
          AS
        </Link>
      </div>
      <div className={styles.box}>
        <div className={styles.number}>404</div>
        <div className={styles.notFound}>Page not found.</div>

        <div className={styles.text}>
          We cant find <span className={styles.path}>{location.pathname}</span> page.
        </div>

        <div className={styles.buttonWrap}>
          <Link to="/" className={styles.button}>
            Go home
          </Link>
        </div>
      </div>
    </div>
  </Fragment>
);

export default Error404;
