import { connect } from 'react-redux';
import { SMALL } from '../../../models/App/App.constants';
import View from './View.component';
import { bindActionCreators } from 'redux';
import { openModal } from '../../../models/Modals/Modals.actions';

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  smallHeight: state.App.sizes.height === SMALL,
  smallWidth: state.App.sizes.width === SMALL,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      openModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(View);
