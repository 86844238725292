/**
 * Create action for Redux.
 *
 * @param name
 */
const createAction = (name) => {
  const type = name.toLocaleUpperCase();

  return {
    action: (payload, queryAction) => {
      const action = { type };

      if (payload !== undefined) {
        action.payload = payload;
      }

      if (queryAction) {
        action.options = queryAction.payload;
      }

      return action;
    },
    name: type,
  };
};

export default createAction;
