import React, { Fragment } from 'react';
import styles from '../../adding/styles/Common.module.scss';
import PolicyButton from '../../adding/PolicyButton';
import Tags from '../../adding/Tags';
import cn from 'classnames';
import Friends from '../../adding/Friends';
import Groups from '../../adding/Groups';
import Preview from '../../adding/Preview';
import * as SHARE from '../../../models/Share/Share.constants';
import controlStyles from '../../share/Share/Share.module.scss';
import ownStyles from './FulFill.module.scss';

/**
 * FulFill Component.
 */
const FulFill = ({
  loading,
  errors,

  originSnap,
  handlers,
  fields: { title, description, users, groups: groupsField },
  is,
  handleSetIs,

  editMode,
  handleChangeEditMode,
  handleChangeImage,
  imageUrl,
  send,
  share,
  added,
  handleCloseModalAndReset,
  handleCloseModal,
  handleRevert,
  handleSwitchToFulFill,
  handleChangeRemove,

  shouldLoadTags,
  markTagsAsLoaded,

  remove,

  handleChangeData,
  emails,
  tags,
  groups,

  type,
}) => {
  let addedFrom = originSnap.is;

  if (!addedFrom) {
    addedFrom = 'waitings';
  }

  addedFrom = addedFrom.toLowerCase();

  return (
    <Fragment>
      <div className={cn(styles.adding, styles.smallWrapper)}>
        <div className={styles.link}>{originSnap.link}</div>

        {type !== SHARE.TYPE_SAVE && <Preview link={originSnap} />}

        {type === SHARE.TYPE_SAVE && (
          <Fragment>
            <div className={ownStyles.wrapper}>
              {originSnap.image && (
                <div className={ownStyles.left}>
                  <img src={originSnap.image.url} />
                </div>
              )}
              <div className={ownStyles.right}>
                <div className={styles.columns}>
                  <div className={styles.infoWrapper}>
                    <input
                      type="text"
                      value={title.value}
                      className={cn(styles.input, ownStyles.input)}
                      onChange={handlers.change}
                      name={title.name}
                      placeholder="Title"
                    />
                  </div>
                </div>

                <div className={ownStyles.gotToFullFill}>
                  Not enough fields?
                  <button onClick={handleSwitchToFulFill}>Go to full filling</button>
                </div>
              </div>
            </div>

            <div className={styles.type}>
              <ul>
                <li>
                  <PolicyButton
                    is={is}
                    type="private"
                    text="Save as private"
                    handleSetIs={handleSetIs}
                  />
                </li>
                <li>
                  <PolicyButton
                    is={is}
                    type="public"
                    text="Save as public"
                    handleSetIs={handleSetIs}
                  />
                </li>
              </ul>
            </div>
          </Fragment>
        )}

        {type !== SHARE.TYPE_SAVE && (
          <div className={styles.social}>
            {type === SHARE.TYPE_WITH_USERS && (
              <Friends change={handleChangeData} initialData={emails} />
            )}
            {type === SHARE.TYPE_TO_GROUPS && (
              <Groups change={handleChangeData} initialData={groups} />
            )}
          </div>
        )}

        {type !== SHARE.TYPE_WITH_USERS && (
          <Tags
            shouldLoadTags={shouldLoadTags}
            markTagsAsLoaded={markTagsAsLoaded}
            change={handleChangeData}
            initialData={tags}
            small
          />
        )}
      </div>

      {(addedFrom === 'waitings' || addedFrom === 'for_you') && (
        <div className={ownStyles.removeAfter}>
          <button className={cn(remove && ownStyles.selected)} onClick={handleChangeRemove}>
            Remove after {type === SHARE.TYPE_SAVE ? 'save' : 'share'}
          </button>
        </div>
      )}

      <div className={controlStyles.controls}>
        <button
          className={cn(controlStyles.share, loading && controlStyles.loading)}
          onClick={share}
        >
          {type === SHARE.TYPE_SAVE ? 'Save' : 'Share'}
        </button>
        <button
          className={controlStyles.cancel}
          onClick={() => {
            handleCloseModal();
          }}
        >
          Cancel
        </button>
      </div>
    </Fragment>
  );
};

export default FulFill;
