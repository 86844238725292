/* eslint-disable class-methods-use-this */
import { Component } from 'react';
import { PHRASE, TAG, USER, HOST } from '../../../models/Filters/Filters.constants';
import prepareQueryObject from '../../../globals/helpers/queryObject';

/**
 * Main Container.
 */
class Main extends Component {
  static initialState() {
    return {
      tags: [],
      phrase: '',
      newTag: '',
      user: '',
      host: '',
    };
  }

  constructor(props) {
    super(props);

    this.ref = null;

    // Map store to state
    const prepared = prepareQueryObject(props.selected);

    this.state = {
      ...this.constructor.initialState(),
      tags: prepared.tags,
      phrase: prepared.phrase,
      user: prepared.user,
      host: prepared.host,
    };

    this.addTag = this.addTag.bind(this);
    this.addNewTag = this.addNewTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.onChange = this.onChange.bind(this);
    this.clear = this.clear.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.addNewTagByEnter = this.addNewTagByEnter.bind(this);
    this.setFiltersByEnter = this.setFiltersByEnter.bind(this);
    this.setRef = this.setRef.bind(this);
    this.changeWhere = this.changeWhere.bind(this);
    this.setUser = this.setUser.bind(this);
  }

  componentDidMount() {
    this.getData();

    if (this.ref) {
      this.ref.focus();
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  setUser(_, users) {
    if (users.length === 1) {
      this.setState({
        user: users[0],
      });
    } else {
      this.setState({
        user: '',
      });
    }
  }

  getData() {}

  setRef(ref) {
    this.ref = ref;
  }

  setFiltersByEnter(e) {
    if (e.key === 'Enter') {
      this.setFilters();
    }
  }

  setFilters() {
    const filters = [];

    const phrase = this.state.phrase.trim();
    const host = this.state.host.trim();
    const user = this.state.user.trim();

    if (phrase.length > 0) {
      filters.push({
        value: phrase,
        type: PHRASE,
      });
    }

    if (host.length > 0) {
      filters.push({
        value: host,
        type: HOST,
      });
    }

    if (user.length > 0) {
      filters.push({
        value: user,
        type: USER,
      });
    }

    this.state.tags.forEach((tag) => {
      filters.push({
        value: tag,
        type: TAG,
      });
    });

    this.props.reduxActions.setFilters(filters);

    this.props.handleClose();
  }

  changeWhere() {
    this.props.reduxActions.setWhere(this.props.where === 'or' ? 'and' : 'or');
  }

  addNewTagByEnter(e) {
    if (e.key === ' ') {
      this.addNewTag();
    } else if (e.key === 'Enter') {
      this.addNewTag(null, () => {
        this.setFilters();
      });
    }
  }

  addTag(tag) {
    const normalizedTag = tag.toLowerCase();

    if (
      tag.length > 0 &&
      this.state.tags.findIndex((item) => item.toLowerCase() === normalizedTag) < 0
    ) {
      this.setState({
        tags: [...this.state.tags, tag],
        newTag: '',
      });
    }
  }

  removeTag(tag) {
    const index = this.state.tags.findIndex((item) => item === tag);
    const tags = [...this.state.tags];

    tags.splice(index, 1);

    if (index >= 0) {
      this.setState({
        tags,
      });
    }
  }

  clear() {
    this.setState(this.constructor.initialState);
  }

  addNewTag(e, callback = null) {
    const tag = this.state.newTag.trim();

    const tags = tag.split(' ').filter((t) => {
      t = t.trim(); // eslint-disable-line

      return t.length > 0 && this.state.tags.indexOf(t.toLowerCase()) < 0;
    });

    this.setState(
      {
        newTag: '',
        tags: [...this.state.tags, ...tags],
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  }

  render() {
    return null;
  }
}

export default Main;
