import React from 'react';
import Controller from '../../classes/Controller';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import * as GroupsRoutines from '../../../models/Groups/Groups.routines';
import * as FiltersActions from '../../../models/Filters/Filters.actions';
import { bindRoutines } from '../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import { openModal } from '../../../models/Modals/Modals.actions';
import * as MODALS from '../../../models/Modals/Modals.types';
import GroupsComponent from './Groups.component';
import mapLocationToObject from '../../../globals/helpers/mapLocationToObject';
import * as ModalsActions from '../../../models/Modals/Modals.actions';

/**
 * Groups Container.
 */
class Groups extends Controller {
  setVars() {
    this.ref = null;
  }

  bindings() {
    this.innerRef = this.innerRef.bind(this);
    this.addMembers = this.addMembers.bind(this);
    this.editName = this.editName.bind(this);
    this.showMembers = this.showMembers.bind(this);
    this.delete = this.delete.bind(this);
    this.leave = this.leave.bind(this);
  }

  load(payload) {
    this.props.reduxActions.getGroups(payload);
  }

  innerRef(ref) {
    this.ref = ref;
  }

  addMembers(id) {
    this.openModal(MODALS.GROUP_ADD_MEMBER, id);
  }

  editName(id) {
    this.openModal(MODALS.GROUP_ADD, id);
  }

  showMembers(id) {
    this.openModal(MODALS.GROUP_MEMBERS, id);
  }

  openModal(type, id) {
    const selectedGroup = this.props.groups.find((group) => group.id === id);

    if (selectedGroup) {
      this.props.reduxActions.openModal({
        type,
        payload: {
          group: selectedGroup,
        },
      });
    }
  }

  delete(event, id) {
    this.doAction(
      event,
      id,
      GroupsRoutines.deleteGroup.trigger,
      'Do you want to delete this group?'
    );
  }

  leave(event, id) {
    this.doAction(event, id, GroupsRoutines.leave.trigger, 'Do you want to leave this group?');
  }

  doAction(event, id, action, title) {
    const payload = {
      id,
      reload: mapLocationToObject(this.props.location, false),
    };

    const actionWrapper = ModalsActions.wrapWithConfirm(
      this.props.reduxActions.dispatch,
      action,
      title
    );

    actionWrapper(event, true, payload);
  }

  render() {
    if (this.redirect) {
      return <Redirect to="/groups/1" />;
    }

    return (
      <GroupsComponent
        loading={this.props.loading}
        groups={this.props.groups}
        pagination={this.props.pagination}
        openedGroupId={this.props.openedGroupId}
        ownerId={this.props.ownerId}
        handleInnerRef={this.innerRef}
        handleAddMembers={this.addMembers}
        handleEditName={this.editName}
        handleShowMembers={this.showMembers}
        handleDelete={this.delete}
        handleLeave={this.leave}
        onlyOneGroup={this.props.location.search.search('groupId') >= 0}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    dispatch,

    ...bindRoutines(
      {
        getGroups: GroupsRoutines.getGroups.trigger,
      },
      dispatch
    ),

    ...bindActionCreators(
      {
        resetFilters: FiltersActions.resetFilters,
        openModal,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Groups.loading,
  groups: state.Groups.data,
  pagination: state.Groups.pagination,
  ownerId: state.User.data.id,
  // To refresh
  filters: state.Filters.selected,
  forcedRefreshTime: state.Filters.forcedRefreshTime,
  openedGroupId: state.Groups.members.id,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Groups)
);
