import { connect } from 'react-redux';
import * as FiltersActions from '../../../models/Filters/Filters.actions';
import { bindActionCreators } from 'redux';
import { openModal } from '../../../models/Modals/Modals.actions';
import { withRouter } from 'react-router-dom';
import NoLinksComponent from './NoLinks.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      resetFilters: FiltersActions.resetFilters,
      openModal,
    },
    dispatch
  ),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(NoLinksComponent)
);
