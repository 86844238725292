import React, { Component } from 'react';
import cn from 'classnames';
import styles from './../styles/Player.module.scss';

/**
 * Song Component.
 */
class Song extends Component {
  constructor(props) {
    super(props);

    this.state = {
      removeState: 0,
    };

    this.tryRemove = this.tryRemove.bind(this);
    this.remove = this.remove.bind(this);
    this.removeFromList = this.removeFromList.bind(this);
    this.cancelRemove = this.cancelRemove.bind(this);
  }

  remove() {
    this.setState({
      removeState: 1,
    });

    this.interval = setTimeout(this.cancelRemove, 2000);
  }

  tryRemove() {
    this.setState({
      removeState: 1,
    });
  }

  removeFromList() {
    this.clear(99);

    this.props.reduxActions.remove(this.props.data.details.videoId);
  }

  clear(removeState) {
    clearTimeout(this.interval);

    this.setState({
      removeState,
    });
  }

  cancelRemove() {
    this.clear(0);
  }

  render() {
    const selected = this.props.playingId === this.props.data.details.videoId;

    const imageStyles = {
      backgroundImage: `url('${this.props.data.image.url}')`,
    };

    return (
      <div className={cn(styles.song, selected && styles.selected)}>
        <div className={styles.image} style={imageStyles} />
        <div className={styles.about}>
          <div
            className={styles.title}
            onClick={() => {
              this.props.reduxActions.play(this.props.data.details.videoId);
            }}
          >
            {this.props.data.title}
          </div>
          <div className={styles.menuWrapper}>
            {this.state.removeState === 0 && (
              <button className={styles.remove} onClick={this.remove} />
            )}
            {this.state.removeState === 1 && (
              <button className={styles.confirm} onClick={this.removeFromList} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Song;
