import React from 'react';
import * as MODALS from '../../../models/Modals/Modals.types';
import { FOLLOW } from '../../../models/Followers/Followers.constants';
import styles from './../styles/Common.module.scss';

/**
 * No Follower Component.
 */
const NoFollower = ({ reduxActions }) => {
  return (
    <div className={styles.noLinks}>
      <h4>Follower not found</h4>

      <div className={styles.info}>
        <span>Try to use solutions:</span>

        <ul>
          <li>
            <button
              onClick={() => {
                reduxActions.resetFilters();
              }}
            >
              Clear filters and go to basic list
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                reduxActions.openModal({
                  type: MODALS.FOLLOWERS,
                  payload: {
                    type: FOLLOW,
                  },
                });
              }}
            >
              Maybe you cannot follow any user. Start it
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NoFollower;
