import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeAllModals } from '../../../../models/Modals/Modals.actions';
import { reset } from '../../../../models/Adding/Adding.actions';
import AddedComponent from './Added.component';

/**
 * Added Container.
 */
class Added extends Component {
  constructor(props) {
    super(props);

    this.closeModalAndReset = this.closeModalAndReset.bind(this);
  }

  closeModalAndReset() {
    this.props.reduxActions.closeAllModals();
    this.props.reduxActions.reset();
  }

  render() {
    return (
      <AddedComponent
        title={this.props.payload.title}
        type={this.props.payload.type}
        data={this.props.payload.data}
        close={this.closeModalAndReset}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      closeAllModals,
      reset,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(Added);
