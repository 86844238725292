import { connect } from 'react-redux';
import Listener from './Listener.component';

/*
 * Connection
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  null,
  mapDispatchToProps
)(Listener);
