import { connect } from 'react-redux';
import * as ModalsActions from '../../../models/Modals/Modals.actions';
import * as LinksRoutines from '../../../models/Links/Links.routines';
import SelectedComponent from './Selected.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    remove: ModalsActions.wrapWithConfirm(
      dispatch,
      LinksRoutines.massRemoveFromWaitings.trigger,
      'Do you want to remove selected links?'
    ),
  },
});

export default connect(
  null,
  mapDispatchToProps
)(SelectedComponent);
