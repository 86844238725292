import React, { Component } from 'react';
import { connect } from 'react-redux';
import Settings from '../../config/settings';
import ProgressBarComponent from './ProgressBar.component';
import mapStateToProps from './ProgressBar.loading';

/*
 * ApiProgressBar Container
 */
class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.timestamp = null;

    this.state = {
      show: false,
      status: 0,
    };

    this.check = this.check.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { loading } = newProps;

    if (loading) {
      // start
      if (this.state.status === 0) {
        this.setState({
          show: true,
          status: 1, // go to 75%
        });
      } else if (this.state.status === 2) {
        // finishing
        let delta = Settings.progressBar.finishing;

        if (this.timestamp) {
          const min = 100;
          delta = Math.max(delta - (Date.now() - this.timestamp), 0) + min;

          this.timestamp = null;
        }

        setTimeout(this.check, delta);
      }
    } else if (this.state.status === 1) {
      // stop
      this.setState(
        {
          show: true,
          status: 2, // go to 100%
        },
        () => {
          setTimeout(() => {
            this.setState({
              show: false,
              status: 0,
            });
          }, Settings.progressBar.finishing);

          this.timestamp = Date.now();
        }
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.show !== this.state.show || nextState.status !== this.state.status;
  }

  check() {
    if (this.props.loading && this.state.status === 0) {
      this.setState({
        show: true,
        status: 1, // go to 75%
      });
    }
  }

  render() {
    return <ProgressBarComponent show={this.state.show} status={this.state.status} />;
  }
}

export default connect(mapStateToProps)(ProgressBar);
