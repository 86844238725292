import React from 'react';
import cn from 'classnames';
import Pagination from '../../template/Pagination';
import NoFollowers from '../../noItems/NoFollowers';
import User from './../User/User.component';
import styles from './List.module.scss';
import userStyles from './../User/User.module.scss';
import Loader from './../Loader';
import Sort from './../Sort';

/**
 * List Component.
 */
const List = ({
  followers,
  loading,
  selected,
  pagination,
  handleToggleSelect,
  handleToggleAll,
  handleInnerRef,
  handleUnfollow,
  handleSetFilters,
}) => {
  if (followers.length < 1 && loading) {
    return <Loader />;
  }

  if (followers.length < 1 && !loading) {
    return <NoFollowers />;
  }

  return (
    <div className={cn(styles.scroller, 'S')} ref={handleInnerRef}>
      <div className={cn(styles.wrapper, loading && styles.loading)}>
        <ul className={styles.list}>
          <div className={cn(userStyles.user, userStyles.header)}>
            <div className={userStyles.checkbox}>
              <button onClick={handleToggleAll} />
            </div>
            <div className={userStyles.info}>
              <div className={userStyles.about}>
                <div className={userStyles.avatar} />
                <div className={userStyles.name}>
                  <Sort name="username" url="/followers">
                    Name
                  </Sort>
                </div>
              </div>
              <div className={userStyles.date}>
                <Sort name="date" url="/followers">
                  Date
                </Sort>
              </div>
            </div>
          </div>

          {followers.map((user) => (
            <li key={user.id}>
              <User
                data={user}
                handleToggleSelect={handleToggleSelect}
                handleSetFilters={handleSetFilters}
                selected={selected.indexOf(user.id) >= 0}
              />
            </li>
          ))}
        </ul>

        {!loading && (
          <div className={styles.menu}>
            <div className={styles.left}>
              {selected.length > 0 && (
                <button onClick={handleUnfollow}>
                  Unfollow <strong>({selected.length})</strong>
                </button>
              )}
            </div>
            <div className={styles.right}>
              <Pagination pages={pagination.pages} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default List;
