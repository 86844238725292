import {
  getLinks,
  removeFromWaitings,
  removeLink,
  addToSpeedDial,
  removeFromSpeedDial,
  changePrivacy,
  massRemoveFromWaitings,
  sayThankYou,
  block,
} from './Links.routines';
import { RESET, SET } from '../Filters/Filters.constants';
import Settings from '../../config/settings';
import { check, fetch } from '../Fetch/Fetch.routines';
import { getDetails } from '../Adding/Adding.routines';
import { MARK_AS_SEEN, SET_USER_HAS, REFRESH } from './Links.constants';
import * as LINKS from './Links.constants';
import {
  removeIncorrectLinkFromPayload,
  removePropsFromLink,
  removeFromLinks,
  modifyLink,
  massRremoveFromLinks,
  chnageSpeedDial,
} from './Links.helpers';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  removeLoading: false,
  removeCounter: 0,
  editLoading: false,
  data: [],
  selected: null,
  selectionIterator: 0,
  showMore: false,
  added: false,
  errors: null,
  removeStamp: false,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  let elem;
  let newState;
  let index;
  let newData;
  let newLinksPackage;

  switch (action.type) {
    case /* Filters. */ RESET:
    case /* Filters. */ SET:
      return {
        ...initialState,
      };

    case REFRESH:
      return {
        ...initialState,
        loading: state.loading,
      };

    /*
     * LOADING
     */
    case getLinks.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case getLinks.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * REMOVING
     */
    case removeLink.REQUEST:
    case removeFromWaitings.REQUEST:
      return {
        ...state,
        removeLoading: true,
      };

    case removeLink.FULFILL:
    case removeFromWaitings.FULFILL:
      return {
        ...state,
        removeLoading: false,
      };

    /*
     * EDIT
     */
    case addToSpeedDial.REQUEST:
    case removeFromSpeedDial.REQUEST:
    case changePrivacy.REQUEST:
    case sayThankYou.REQUEST:
    case block.REQUEST:
      return {
        ...state,
        editLoading: true,
      };

    case addToSpeedDial.FULFILL:
    case removeFromSpeedDial.FULFILL:
    case changePrivacy.FULFILL:
    case sayThankYou.FULFILL:
    case block.FULFILL:
      return {
        ...state,
        editLoading: false,
      };

    /*
     * GET LINKS
     */
    case getLinks.SUCCESS:
      if (action.options.endpoint === 'waitings') {
        newLinksPackage = action.payload || [];
      } else {
        newLinksPackage = removeIncorrectLinkFromPayload(action.payload || []);
      }

      newState = {
        ...state,
        added: true,
        data: [...state.data, ...newLinksPackage],
        showMore: action.payload && action.payload.length >= Settings.links.perPage,
      };

      if (action.options.withSelection) {
        newState.selected = newLinksPackage.length > 0 ? newLinksPackage[0].id : state.selected;

        newState.selectionIterator = state.selectionIterator + 1;
      }

      return newState;

    case getLinks.FAILURE:
      return {
        ...state,
        added: true,
        errors: action.payload.response.errors,
      };

    /*
     * SELECT
     */
    case LINKS.SELECT:
      return {
        ...state,
        selected: action.payload.selected,
        selectionIterator: state.selectionIterator + 1,
      };

    /*
     * SELECT BY INDEX
     */
    case LINKS.SELECT_BY_INDEX:
      elem = state.data[action.payload.index];

      if (elem && elem.id) {
        return {
          ...state,
          selected: elem.id,
          selectionIterator: state.selectionIterator + 1,
        };
      }

      return state;

    /*
     * CHECK & FETCH
     */
    case check.SUCCESS:
    case fetch.SUCCESS:
      index = state.data.findIndex((item) => item.id === action.options.id);

      if (index >= 0) {
        newData = [...state.data];

        newData[index] = {
          ...state.data[index],
          ...removePropsFromLink(action.payload),
          checked: true,
          fetched: true,
        };

        return {
          ...state,
          data: newData,
        };
      }

      return state;

    case check.FAILURE:
      index = state.data.findIndex((item) => item.id === action.options.id);

      if (index >= 0) {
        newData = [...state.data];

        newData[index] = {
          ...state.data[index],
          checked: true,
        };

        return {
          ...state,
          data: newData,
        };
      }

      return state;

    /**
     * Add link to list at the beginning.
     */
    case getDetails.SUCCESS:
      return {
        ...state,
        data: [
          {
            ...action.payload,
            now_added: true,
          },
          ...state.data,
        ],
        selected: state.selected ? state.selected : action.payload.id,
      };

    case MARK_AS_SEEN:
      return modifyLink(state, action.payload.id, () => ({ now_added: false }));

    case SET_USER_HAS:
      return modifyLink(state, action.payload.id, () => ({ user_has: true }));

    case addToSpeedDial.SUCCESS:
      return chnageSpeedDial(state, action.options.id, true);

    case removeFromSpeedDial.SUCCESS:
      return chnageSpeedDial(state, action.options.id, false);

    case changePrivacy.SUCCESS:
      return modifyLink(state, action.options.id, () => ({ private: action.options.private }));

    case sayThankYou.SUCCESS:
      return modifyLink(state, action.options.id, () => ({ thanks: true }));

    /*
     * REMOVE FROM LINKS
     */
    case removeLink.SUCCESS:
    case removeFromWaitings.SUCCESS:
      newState = removeFromLinks(
        action,
        state.data,
        action.type === removeFromWaitings.SUCCESS ? 'link' : 'id'
      );

      if (newState) {
        return {
          ...state,
          ...newState,
          removeStamp: Date.now(),
        };
      }

      return state;

    case massRemoveFromWaitings.SUCCESS:
      newState = massRremoveFromLinks(action, state.data, state.selected);

      return {
        ...state,
        ...newState,
        removeStamp: Date.now(),
      };

    case LINKS.EDIT_LINK:
      return modifyLink(state, action.payload.id, () => action.payload);

    case LINKS.SET_PURE_LINKS:
      return {
        ...state,
        data: action.payload.links,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
