import { RESET, SET_MEMBERS_GROUP, RESET_MEMBERS_GROUP } from './Groups.constants';

export const reset = () => ({
  type: RESET,
});

export const setMembersGroup = (id) => ({
  type: SET_MEMBERS_GROUP,
  id,
});

export const resetMembersGroup = () => ({
  type: RESET_MEMBERS_GROUP,
});
