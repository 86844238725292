import React from 'react';
import cn from 'classnames';
import Members from '../../../users/Members';
import styles from '../Added/Added.module.scss';

/**
 * GroupMembers Component.
 */
const GroupMembers = ({ payload: { group }, reduxActions: { closeAllModals } }) => {
  return (
    <div className={cn(styles.added, 'S')} onClick={closeAllModals}>
      <div
        className={styles.wrapper}
        onClick={(e) => {
          e.stopPropagation();

          if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
          }
        }}
      >
        <div className={styles.name}>
          Show members of {group && group.name ? <i>{group.name}</i> : 'group'}
        </div>
        <div className={styles.list}>
          <Members group={group} />
        </div>
      </div>
    </div>
  );
};

export default GroupMembers;
