import { connect } from 'react-redux';
import * as ModalsActions from '../../../../models/Modals/Modals.actions';
import * as AddingActions from '../../../../models/Adding/Adding.actions';
import LinkAdd from './LinkAdd.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    closeModalWithConfirm: ModalsActions.wrapWithConfirm(
      dispatch,
      AddingActions.reset, // original action
      'Do you want to abort adding new link(s)?',
      true
    ),
  },
});

const mapStateToProps = (state) => ({
  window: state.Adding.window,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkAdd);
