const loadImage = (url, errorCallback) => {
  /* global Image */
  const image = new Image();
  let intervalCallback = null;

  image.onload = () => {
    intervalCallback = setTimeout(() => {
      errorCallback(null);
    }, 0);
  };

  image.onerror = () => {
    intervalCallback = setTimeout(() => {
      errorCallback(true);
    }, 0);
  };

  image.src = url;

  return {
    interval: intervalCallback,
    image,
    destroy: () => {
      if (intervalCallback) {
        clearTimeout(intervalCallback);
      }

      if (image) {
        image.onload = null;
        image.onerror = null;
      }
    },
  };
};

export default loadImage;
