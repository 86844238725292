/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { concat, of, race } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';
import config from '../../config/config';
import getHeaders from '../../globals/helpers/headers';
import { addAsSuccess } from '../Alert/Alert.actions';
import { prepareLinkWithFilters } from '../Links/Links.helpers';
import mapToArray from '../../globals/helpers/mapToArray';
import * as Followers from './Followers.routines';

export const getFollowers = epic(
  Followers.getFollowers,
  (action, state$) =>
    prepareLinkWithFilters('followers', state$.value.Filters.selected, action.payload),
  false,
  'Followers'
);

export const unfollow = (action$, state$) => {
  const routine = Followers.unfollow;
  const loading = !state$.value.Followers.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax
            .post(
              `${config.apiHost}followers/discard`,
              { ...mapToArray('ids', action.payload.users) },
              getHeaders()
            )
            .pipe(
              mergeMap(
                whenSuccess(
                  routine,
                  null,
                  of(addAsSuccess(`You have removed (${action.payload.users.length}) followers.`)),
                  of(Followers.getFollowers.trigger(action.payload.reload))
                )
              ),
              catchError(whenFailure(routine, null, true))
            ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const invite = (action$, state$) => {
  const routine = Followers.invite;
  const loading = !state$.value.Followers.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax
            .post(
              `${config.apiHost}followers/invite`,
              { ...mapToArray('emails', action.payload.emails) },
              getHeaders()
            )
            .pipe(
              mergeMap(
                whenSuccess(
                  routine
                  // null,
                  // of(Followers.getFollowers.trigger(action.payload.reload)),
                )
              ),
              catchError(whenFailure(routine))
            ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const follow = (action$, state$) => {
  const routine = Followers.follow;
  const loading = !state$.value.Followers.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax
            .post(
              `${config.apiHost}followers`,
              { ...mapToArray('emails', action.payload.emails) },
              getHeaders()
            )
            .pipe(
              mergeMap(
                whenSuccess(
                  routine,
                  null,
                  of(Followers.getFollowers.trigger(action.payload.reload))
                )
              ),
              catchError(whenFailure(routine))
            ),
          whenTimeout(routine)
        )
      );
    })
  );
};
