import React from 'react';
import { connect } from 'react-redux';
import mapStateToProps from './../helpers/mapStateToProps';
import mapDispatchToProps from './../helpers/mapDispatchToProps';
import SocialComponent from './Social.component';
import Main from './../Main';

/**
 * Social Container.
 */
class Saved extends Main {
  render() {
    return (
      <SocialComponent
        loading={this.props.loading}
        user={this.state.user}
        handleClose={this.props.handleClose}
        handleChange={this.onChange}
        handleClear={this.clear}
        handleSetFilter={this.setFilters}
        handleSetFiltersByEnter={this.setFiltersByEnter}
        handleSetRef={this.setRef}
        handleChangeEmails={this.setUser}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Saved);
