/* eslint-disable import/prefer-default-export */
import * as PLAYER from './Player.constants';

export const add = (video) => ({
  type: PLAYER.ADD,
  payload: {
    video,
  },
});

export const addAll = () => ({
  type: PLAYER.ADD_ALL,
});

export const blink = () => ({
  type: PLAYER.BLINK,
});

export const next = () => ({
  type: PLAYER.NEXT,
});

export const songEnded = () => ({
  type: PLAYER.SONG_ENDED,
});

export const clear = () => ({
  type: PLAYER.CLEAR,
});

export const prev = () => ({
  type: PLAYER.PREV,
});

export const hide = () => ({
  type: PLAYER.HIDE,
});

export const stop = () => ({
  type: PLAYER.STOP,
});

export const toggleHeight = () => ({
  type: PLAYER.TOGGLE_HEIGHT,
});

export const changeRepeatMode = () => ({
  type: PLAYER.CHANGE_REPEAT_MODE,
});

export const changeShuffleMode = () => ({
  type: PLAYER.CHANGE_SHUFFLE_MODE,
});

export const setDefaultPlaylist = () => ({
  type: PLAYER.SET_DEFAULT_PLAYLIST,
});

export const setTag = (tag) => ({
  type: PLAYER.SET_TAG,
  payload: {
    tag,
  },
});

export const replacePlaylist = (list) => ({
  type: PLAYER.REPLACE_PLAYLIST,
  payload: {
    list,
  },
});

export const remove = (id) => ({
  type: PLAYER.REMOVE,
  payload: {
    id,
  },
});

export const play = (id) => ({
  type: PLAYER.PLAY,
  payload: {
    id,
  },
});

export const preview = (video) => ({
  type: PLAYER.PREVIEW,
  payload: {
    video,
  },
});
