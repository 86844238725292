import React from 'react';
import { Modal, Title, Body } from '../../Modal';
import cn from 'classnames';
import FieldError from '../../../errors/Field';
import { formClassName } from '../../../../abstracts/Form/Utils';
import styles from '../../Modal/Modal.module.scss';
import viewStyles from '../../../account/View/View.module.scss';
import fixStyles from '../../../adding/styles/Common.module.scss';
import Friends from '../../../adding/Friends';
import Loader from '../../../adding/TagsLoader';

/**
 * Members Component.
 */
const Members = ({
  fields,
  loading,
  errors,
  groups,
  selectedGroup,
  added,
  groupAsProps,
  handlers,
  handleCloseModal,
  handleChangeEmails,
  handleChangeGroup,
}) => {
  const { emails } = fields;

  return (
    <Modal handleClose={handleCloseModal}>
      <Title>Adding members</Title>

      <Body>
        <div className={viewStyles.form}>
          <div className={styles.paragraph}>
            Select one of groups and add users using their emails.
          </div>

          {(errors || selectedGroup.error) && (
            <div className={formClassName(selectedGroup, errors, viewStyles, [fixStyles.center])}>
              <FieldError
                data={selectedGroup}
                apiData={errors}
                names="Group"
                className={viewStyles.error}
              />
            </div>
          )}

          <div className={fixStyles.adding}>
            <div className={cn(fixStyles.groups, groupAsProps && fixStyles.withOpacity)}>
              <ul>
                {groups.map((group) => (
                  <li key={group.id}>
                    <button
                      className={cn({
                        [fixStyles.selected]: selectedGroup.value === group.id,
                      })}
                      onClick={() => {
                        handleChangeGroup(group.id);
                      }}
                    >
                      {group.name}
                    </button>
                  </li>
                ))}
              </ul>
              <Loader loading={loading} />
              {added && groups.length < 1 && (
                <div className={fixStyles.noGroup}>You {"don't"} have any group.</div>
              )}
            </div>
          </div>

          <div className={fixStyles.adding}>
            <Friends change={handleChangeEmails} useChildAsError withoutLimit>
              <div className={formClassName(emails, errors, viewStyles, [fixStyles.row])}>
                <FieldError
                  data={emails}
                  apiData={errors}
                  names="Emails field"
                  className={viewStyles.error}
                />
              </div>
            </Friends>
          </div>

          <div className={cn(styles.controls, fixStyles.withMarginTop)}>
            <button
              type="button"
              className={cn(styles.success, loading && styles.loading)}
              onClick={handlers.submit}
            >
              Add members
            </button>
          </div>
        </div>
      </Body>
    </Modal>
  );
};

export default Members;
