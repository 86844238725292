import { getThanks } from './Thanks.routines';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  added: false,
  data: [],
  nextPage: false,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case getThanks.TRIGGER:
      return action.payload && action.payload.from
        ? state
        : {
            ...state,
            data: [],
          };

    /*
     * LOADING
     */
    case getThanks.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case getThanks.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * GET TAGS
     */
    case getThanks.SUCCESS:
      return {
        ...state,
        data: [...state.data, ...(action.payload.data || [])],
        added: true,
        nextPage: action.payload.about.nextPage,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
