import { connect } from 'react-redux';
import * as CommentsRoutines from '../../../models/Comments/Comments.routines';
import { bindRoutines } from '../../../abstracts/Routine';
import MoreComponent from './More.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      loadMore: (snapId) => CommentsRoutines.loadMore.trigger({ snapId }),
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(MoreComponent);
