import React from 'react';
import { connect } from 'react-redux';
import FormClass from '../../../abstracts/Form/Class';
import Validator from '../../../abstracts/Form/Validator';
import { bindRoutines } from '../../../abstracts/Routine';
import * as Account from '../../../models/Account/Account.routines';
import EmailComponent from './Email.component';

/**
 * Email Container.
 */
class Email extends FormClass {
  static initialState() {
    return {
      fields: {
        email: {
          name: 'email',
          value: '',
          error: null,
          focus: false,
        },
      },
    };
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {
      email: [Validator.rules.required(), Validator.rules.email()],
    };
  }

  submitForm() {
    const {
      email: { value: emailValue },
    } = this.state.fields;

    const payload = {
      email: emailValue,
    };

    this.props.reduxActions.checkEmail(payload);
  }

  render() {
    return (
      <EmailComponent
        fields={this.state.fields}
        handlers={this.handlers}
        loading={this.props.loading}
        errors={this.props.errors}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      checkEmail: Account.checkEmail.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  errors: state.Account.register.errors,
  loading: state.Account.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Email);
