import React from 'react';
import styles from './FirstTime.module.scss';

const FirstTime = () => (
  <div className={styles.first}>
    <h2>Hello!</h2>
    <h3>
      It is very nice to see that you sign up into <b>AS</b>. Thanks a lot :)
    </h3>
  </div>
);

export default FirstTime;
