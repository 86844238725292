import React from 'react';
import { Link } from 'react-router-dom';
import View, { Body, Menu } from '../../account/View';
import FieldError from '../../errors/Field';
import ApiError from '../../errors/Api';
import { formClassName } from '../../../abstracts/Form/Utils';
import cn from 'classnames';
import viewStyles from '../View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * Reset Component.
 */
const Reset = ({ handlers, fields: { email }, errors, loading }) => {
  return (
    <View>
      <Menu>
        <li>
          <Link to="/">Log in</Link>
        </li>
      </Menu>
      <Body>
        <div className={viewStyles.form}>
          <h1>Reset password</h1>

          <div className={styles.description}>
            Type your email and click <span>Send</span>. We will send you reset link.
          </div>

          <form onSubmit={handlers.submit}>
            <div className={viewStyles.form}>
              <div className={formClassName(email, errors, viewStyles)}>
                <input
                  type="text"
                  className={viewStyles.input}
                  placeholder="Email"
                  autoComplete="off"
                  name={email.name}
                  onChange={handlers.change}
                  value={email.value}
                />
                <FieldError
                  data={email}
                  apiData={errors}
                  names="Email address"
                  className={viewStyles.error}
                />
              </div>

              <div className={cn(viewStyles.row, viewStyles.lastRow)}>
                <button onClick={handlers.submit} className={viewStyles.button} disabled={loading}>
                  Send email
                </button>
                <ApiError
                  data={errors}
                  className={viewStyles.error}
                  translate={{
                    INVALID_DATA: "We can't reset password for this email",
                  }}
                />
              </div>
            </div>
          </form>

          <div className={cn(styles.singUp, styles.withMarginTop)}>
            You can always back to <Link to="/">Log in</Link>.
          </div>
        </div>
      </Body>
    </View>
  );
};

export default Reset;
