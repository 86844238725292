import React, { Fragment } from 'react';
import ViewComponent, { Left, Right, Filters } from '../../dashboard/View';
import Links from '../../links/Links';
import Switch from '../../details/Switch';
import FiltersForGrouped from '../../filters/Grouped';
import GroupLoader from '../../filters/GroupLoader';

/**
 * Grouped Component.
 */
const Grouped = () => {
  return (
    <ViewComponent page="dashboard/grouped">
      <Filters>
        <FiltersForGrouped />
      </Filters>
      <Left>
        <Fragment>
          <GroupLoader />
          <Links endpoint="grouped" />
        </Fragment>
      </Left>
      <Right>
        <Switch fetched type="grouped" />
      </Right>
    </ViewComponent>
  );
};

export default Grouped;
