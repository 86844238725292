import React from 'react';
import { connect } from 'react-redux';
import FormClass from '../../../abstracts/Form/Class';
import Validator from '../../../abstracts/Form/Validator';
import { bindRoutines } from '../../../abstracts/Routine';
import * as User from '../../../models/User/User.routines';
import UserNameComponent from './UserName.component';

/**
 * User Name Container.
 */
class UserNameContainer extends FormClass {
  static initialState(props) {
    return {
      fields: {
        name: {
          name: 'name',
          value: (props.user && props.user.full_name) || '',
          error: null,
          focus: false,
        },
      },
    };
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {
      name: [Validator.rules.required(), Validator.rules.length(6)],
    };
  }

  submitForm() {
    const {
      name: { value: nameValue },
    } = this.state.fields;

    const payload = {
      fullName: nameValue,
    };

    if (this.isDirty()) {
      this.props.reduxActions.changeName(payload);
    }
  }

  render() {
    return (
      <UserNameComponent
        fields={this.state.fields}
        handlers={this.handlers}
        loading={this.props.loading}
        errors={this.props.errors}
        user={this.props.user}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      changeName: User.changeName.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  errors: state.User.errors,
  loading: state.User.loading,
  user: state.User.data,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNameContainer);
