import React, { Component } from 'react';
import styles from '../../group/Group/Group.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import localStyles from './Group.module.scss';

class Group extends Component {
  componentDidMount() {
    if (this.props.id) {
      this.props.reduxActions.getDetails(this.props.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id && this.props.id) {
      this.props.reduxActions.getDetails(this.props.id);
    }
  }

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return (
      <div className={cn(styles.group, localStyles.group)}>
        <div className={localStyles.row}>
          <div className={localStyles.users}>
            {data.users.length > 0 && (
              <div className={styles.users}>
                {data.users.map((user) => {
                  const filters = [
                    {
                      value: {
                        name: user.full_name,
                        id: user.id,
                        setAfterRefresh: true,
                      },
                      type: 'user',
                    },
                  ];

                  return (
                    <Link
                      key={user.id}
                      className={styles.user}
                      onClick={() => {
                        this.props.reduxActions.setFilters(filters);
                      }}
                      to="/dashboard/social"
                    >
                      <img
                        src={`https://www.gravatar.com/avatar/${user.email_hash}?d=identicon`}
                        alt={user.full_name}
                      />
                      <div className={styles.userName}>{user.full_name}</div>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
          <div className={localStyles.info}>
            <div>
              <Link to={`/groups?groupId=${data.id}`}>
                <span className="icomoon icon-arrow_back" />
                <em>Manage group</em>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Group;
