/* eslint-disable import/prefer-default-export */
import { createRoutine } from '../../abstracts/Routine';

/*
 * Routines.
 */
export const getFollowers = createRoutine('FOLLOWERS__GET_FOLLOWERS');
export const unfollow = createRoutine('FOLLOWERS__UNFOLLOW');
export const invite = createRoutine('FOLLOWERS__INVITE');
export const follow = createRoutine('FOLLOWERS__FOLLOW');
