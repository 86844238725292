import React from 'react';
import { displayError } from '../../../abstracts/Form/Utils';
import { dictionary } from '../../../globals/errors/dictionary';

/**
 * Error Field Component.
 */
const ErrorField = ({ data, apiData, names, className }) => {
  if (data.error) {
    return <div className={className}>{displayError(data.error, names)}</div>;
  }

  if (apiData && Object.hasOwnProperty.call(apiData, data.name)) {
    const error = apiData[data.name][0];

    if (error) {
      let text = error;

      if (Object.hasOwnProperty.call(dictionary, error)) {
        text = dictionary[error];
      }

      return <div className={className}>{text}</div>;
    }
  }

  return null;
};

export default ErrorField;
