/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import cn from 'classnames';
import Settings from '../../../config/settings';
import imageStyles from './Image.module.scss';

/**
 * Image Loader Component.
 */
class ImageLoader extends Component {
  static calculateHeight(width, height) {
    const scale = Settings.template.detailsWidth / width;

    return Math.min(height * scale, Settings.template.detailsImageMaxHeight);
  }

  constructor(props) {
    super(props);

    let height = null;

    if (props.width && props.height) {
      height = this.constructor.calculateHeight(props.width, props.height);
    }

    this.image = null;

    this.state = {
      loading: true,
      height,
      error: false,
      small: true,
    };

    this.checkDimensions = null;
    this.intervalSetState = null;

    this.toggleSize = this.toggleSize.bind(this);
  }

  componentDidMount() {
    /* global Image */
    this.image = new Image();

    this.image.onload = () => {
      this.intervalSetState = setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 0);
    };

    this.image.onerror = () => {
      this.intervalSetState = setTimeout(() => {
        this.setState({
          error: true,
          loading: false,
        });
      }, 0);
    };

    this.image.src = this.props.src;

    if (!this.state.height) {
      this.getDimensions(this.image);
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkDimensions);
    clearTimeout(this.intervalSetState);

    this.image.onload = null;
    this.image.onerror = null;
  }

  getDimensions(image) {
    let counter = 1;

    this.checkDimensions = setInterval(() => {
      if (image.naturalWidth && image.naturalHeight) {
        clearInterval(this.checkDimensions);
        this.setDimensions(image.naturalWidth, image.naturalHeight);
      }

      if (counter === 3) {
        clearInterval(this.checkDimensions);

        console.log('I cant get dimensions.');
      }

      ++counter;
    }, 50);
  }

  setDimensions(width, height) {
    this.setState({
      height: this.constructor.calculateHeight(width, height),
    });
  }

  toggleSize() {
    this.setState({
      small: !this.state.small,
    });
  }

  render() {
    const { src, styles } = this.props;
    const objectStyles = {};

    if (this.state.loading && this.state.height) {
      objectStyles.minHeight = this.state.height;
    }

    return (
      <div
        className={cn(
          styles.cover,
          this.state.small && styles.small,
          !this.state.error && this.props.withBackground && styles.withBackground
        )}
        style={objectStyles}
      >
        <div className={styles.image} onClick={this.toggleSize} role="button">
          {!this.state.error && <img src={src} alt="Image preview" />}
          {!this.state.error && !this.state.loading && this.props.children}
          {this.state.loading && (
            <div className={imageStyles.loader}>Image is downloading from original source...</div>
          )}
          {this.state.error && (
            <div className={styles.imageError}>
              We {"can't"} get image:{' '}
              <a href={src} target="_blank" rel="noreferrer noopener">
                {src}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ImageLoader;
