import cx from 'classnames';

/**
 * Prepare class name.
 *
 * @param field
 * @param errors
 * @param styles
 * @param extra
 * @returns {*}
 */
const formClassName = (field, errors, styles, extra = {}) => {
  return cx(
    styles.row,
    {
      [styles.focused]: field.focus,
      // [styles.filled]: Validator.get.is(field.value), // check if field is not empty
      [styles.incorrect]: field.error || Object.hasOwnProperty.call(errors || {}, field.name),
    },
    extra
  );
};

/**
 * Display errors.
 *
 * @param str
 * @param names
 */
const displayError = (str, names) => {
  if (Object.prototype.toString.call(names) === '[object Array]') {
    const parts = str.split('%s');
    const length = parts.length - 1;
    let s = '';

    for (let i = 0; i < parts.length; i++) {
      s += parts[i];

      if (i < length) {
        s += names[i];
      }
    }

    return s;
  }

  return str.replace(/%s/, names);
};

export { formClassName, displayError };
