import { getGroups } from './Deck.routines';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  data: [],
  errors: null,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     * LOADING
     */
    case getGroups.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case getGroups.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * GET LINKS
     */
    case getGroups.SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case getGroups.FAILURE:
      return {
        ...state,
        errors: action.payload.response.errors,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
