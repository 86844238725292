import React from 'react';
import { connect } from 'react-redux';
import * as LinksRoutines from '../../../models/Links/Links.routines';
import { bindRoutines } from '../../../abstracts/Routine';
import MoreComponent from './More.component';

/**
 * More Container.
 */
const More = ({ reduxActions, endpoint }) => {
  return (
    <MoreComponent
      handleClick={() => {
        reduxActions.getLinks({ endpoint, withSelection: false, loadMore: true });
      }}
    />
  );
};

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      getLinks: LinksRoutines.getLinks.trigger,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(More);
