import React, { useEffect, useState } from 'react';
import ViewComponent, { Center as CenterSection } from '../../dashboard/View';
import styles from './Deck.module.scss';
import cn from 'classnames';
import Group from './Group';
import Details from './Details';
import * as MODALS from '../../../models/Modals/Modals.types';
import NewFeature from './NewFeature';

const Close = ({ handleClick }) => {
  return (
    <div className={styles.button} onClick={handleClick}>
      <div></div>
    </div>
  );
};

/**
 * Deck Component.
 */
const Deck = ({ location, width, reduxActions, groups, loading }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const close = () => {
    setSelectedItem(null);
  };

  useEffect(() => {
    reduxActions.getGroups();
  }, []);

  return (
    <ViewComponent page="deck" name="Deck">
      <CenterSection>
        <div className={styles.wrapper}>
          <div className={cn(styles.deck, 'T')} style={{ width }}>
            {groups.map((group) => (
              <Group
                data={group}
                key={group.id}
                preview={reduxActions.preview}
                setSelectedItem={setSelectedItem}
              />
            ))}
            <div
              className={styles.settings}
              onClick={() => {
                reduxActions.openModal({
                  type: MODALS.DECK_SETTINGS,
                });
              }}
            >
              <span className="icomoon icon-settings" />
            </div>

            {!loading && groups.length < 1 && <NewFeature />}
          </div>

          {selectedItem && (
            <div className={styles.selected}>
              <Close handleClick={close} />
              <div className={cn(styles.content, 'T')}>
                <Details
                  handlePreview={reduxActions.preview}
                  link={selectedItem}
                  handleClose={close}
                />
              </div>
              <Close handleClick={close} />
            </div>
          )}
        </div>
      </CenterSection>
    </ViewComponent>
  );
};

export default Deck;
