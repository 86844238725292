import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ModalsActions from '../../../../models/Modals/Modals.actions';
import ThanksComponent from './Thanks.component';

const mapDispatchToProps = (dispatch) => {
  const toBind = {
    closeModal: ModalsActions.closeModal,
    closeAllModals: ModalsActions.closeAllModals,
  };

  return {
    reduxActions: bindActionCreators(toBind, dispatch),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ThanksComponent);
