/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import config from '../../config/config';
import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { concat, of, race } from 'rxjs';
import mapToArray from '../../globals/helpers/mapToArray';
import { ajax } from 'rxjs/ajax';
import getHeaders from '../../globals/helpers/headers';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api/index';
import { addAsInfo } from '../Alert/Alert.actions';
import * as Comments from './Comments.routines';

const prepareRouteHelper = (stateFragment, addId = true) => {
  let url = 'grouped/';
  if (stateFragment.enabled === 'grouped-comments') {
    if (addId) {
      url += `${stateFragment.group}/`;
    }

    return url;
  }

  return '';
};

export const get = epic(
  Comments.get,
  (action) => {
    return `${config.apiHost}comments/${
      action.payload.grouped ? `grouped/${action.payload.grouped}/` : ''
    }${action.payload.id}/show`;
  },
  false,
  'Comments',
  false,
  false,
  true
);

export const add = (action$, state$) => {
  const routine = Comments.add;
  const loading = !state$.value.Comments.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      const { snapId, markers, ...rest } = action.payload;
      const userIds = markers.map((marker) => marker.id);

      const url = prepareRouteHelper(state$.value.Comments);

      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}comments/${url}${snapId}/add`,
            method: 'POST',
            headers: getHeaders(),
            body: {
              ...rest,
              ...mapToArray('markers', userIds),
            },
          }).pipe(
            mergeMap(whenSuccess(routine)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const loadMore = (action$, state$) => {
  const routine = Comments.loadMore;
  const loading = !state$.value.Comments.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      const { snapId } = action.payload;
      const comments = state$.value.Comments.data;
      const lastComment = comments[comments.length - 1];

      const url = prepareRouteHelper(state$.value.Comments);

      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}comments/${url}${snapId}/show?from=${lastComment.id}`,
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const remove = (action$, state$) => {
  const routine = Comments.remove;
  const loading = !state$.value.Comments.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      const { id } = action.payload;

      const url = prepareRouteHelper(state$.value.Comments, false);

      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}comments/${url}${id}`,
            method: 'DELETE',
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine, action, of(addAsInfo(`Comment has been removed.`)))),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};
