import { combineEpics } from 'redux-observable';

// Import Epics
import * as Account from '../models/Account/Account.epics';
import * as Adding from '../models/Adding/Adding.epics';
import * as Comments from '../models/Comments/Comments.epics';
import * as DarkList from '../models/DarkList/DarkList.epics';
import * as Fetch from '../models/Fetch/Fetch.epics';
import * as Follower from '../models/Follower/Follower.epics';
import * as Followers from '../models/Followers/Followers.epics';
import * as Groups from '../models/Groups/Groups.epics';
import * as Links from '../models/Links/Links.epics';
import * as Report from '../models/Report/Report.epics';
import * as SpeedDial from '../models/SpeedDial/SpeedDial.epics';
import * as Statistics from '../models/Statistics/Statistics.epics';
import * as Tags from '../models/Tags/Tags.epics';
import * as Thanks from '../models/Thanks/Thanks.epics';
import * as User from '../models/User/User.epics';
import * as Deck from '../models/Deck/Deck.epics';

// Combined epic
import Player from '../models/Player/Player.epics';

/**
 * Root Epic.
 */
const rootEpic = combineEpics(
  // Account
  Account.login,
  Account.checkEmail,
  Account.register,
  Account.confirm,
  Account.reset,
  Account.password,

  // Adding
  Adding.addLink,
  Adding.bulkAdding,
  Adding.getDetails,
  Adding.getSnap,

  // Comments
  Comments.add,
  Comments.get,
  Comments.loadMore,
  Comments.remove,

  // Dark List
  DarkList.getBanned,
  DarkList.removeFromList,

  // Fetch
  Fetch.check,
  Fetch.fetch,

  // Follower
  Follower.getFollower,
  Follower.tryToGetFollower,

  // Followers
  Followers.getFollowers,
  Followers.unfollow,
  Followers.invite,
  Followers.follow,

  // Groups
  Groups.addGroup,
  Groups.addMembers,
  Groups.deleteGroup,
  Groups.editGroup,
  Groups.getAllGroups,
  Groups.getDetails,
  Groups.getGroups,
  Groups.getMembers,
  Groups.leave,
  Groups.removeMembers,
  Groups.getGroupDetailsForGrouped,

  // Links
  Links.addToSpeedDial,
  Links.blockUser,
  Links.changePrivacy,
  Links.editLink,
  Links.editLinkSuccess,
  Links.getLinks,
  Links.massRemoveFromWaitings,
  Links.refresh,
  Links.removeFromSpeedDial,
  Links.removeFromWaitings,
  Links.removeLink,
  Links.removeUserLinks,
  Links.sayThankYou,
  Links.showAlertAfterFieldChange,
  Links.showMassRemoveAlert,
  Links.showRemoveAlert,

  // Player
  Player,

  // Report
  Report.report,

  // Speed Dial
  SpeedDial.getSpeedDial,

  // Statistics
  Statistics.getStats,

  // Tags
  Tags.getTags,
  Tags.loadTagsOnStart,

  // Thanks
  Thanks.getThanks,
  Thanks.loadMore,

  // User
  User.getDetails,
  User.changeName,
  User.changePassword,
  User.markAsInterested,

  // Deck
  Deck.getGroups,
  Deck.saveGroups
);

export default rootEpic;
