import React, { Fragment } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import View, { Body, Menu } from '../../../modules/account/View';
import ApiError from '../../../modules/errors/Api';
import viewStyles from '../../../modules/account/View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * Confirm Component.
 */
const Confirm = ({ success, errors }) => {
  return (
    <View>
      <Menu>
        <li>
          <Link to="/">Log in</Link>
        </li>
      </Menu>
      <Body>
        <div className={cn(viewStyles.form, styles.createdBox)}>
          {success && (
            <Fragment>
              <h1>Account confirmed</h1>

              <div className={styles.description}>
                Your account is active now. You can use <span>AS</span>.
              </div>

              <Link className={viewStyles.button} to="/">
                Sign in
              </Link>
            </Fragment>
          )}

          {!success && (
            <Fragment>
              <h1>Oh, we tried activate your account, but...</h1>

              <div className={styles.description}>
                <ApiError
                  data={errors}
                  translate={{
                    INVALID_TOKEN:
                      '...token in invalid, perhaps value is wrong or token is expired.',
                    ACCOUNT_IS_ALREADY_ACTIVE:
                      '...this account is already active. So, confirmation process is interrupted. Please log in.',
                  }}
                />
              </div>
            </Fragment>
          )}
        </div>
      </Body>
    </View>
  );
};

export default Confirm;
