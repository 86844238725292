import {
  ADD,
  BLINK,
  CHANGE_REPEAT_MODE,
  CHANGE_SHUFFLE_MODE,
  CLEAR,
  PLAY,
  PREVIEW,
  REPLACE_PLAYLIST,
  SET_DEFAULT_PLAYLIST,
  STOP,
  SET_TAG,
  TOGGLE_HEIGHT,
} from './Player.constants';
import { PLAYER_CLOSE } from '../App/App.constants';

/*
 * Initial State.
 */
const initialState = {
  blink: 0,
  currentTag: null,
  defaultList: [],
  loading: false,
  playingId: null,
  preview: null,
  repeat: 0,
  shuffle: 0,
  status: -1,
  minimalized: false,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     * ADD
     */
    case ADD:
      const { video } = action.payload;

      if (state.defaultList.find((link) => link.details.videoId === video.details.videoId)) {
        return state;
      }

      return {
        ...state,
        defaultList: [...state.defaultList, action.payload.video],
      };

    /*
     * REPLACE_PLAYLIST
     */
    case REPLACE_PLAYLIST:
      return {
        ...state,
        defaultList: action.payload.list,
      };

    /*
     * STOP
     */
    case STOP:
      return {
        ...state,
        playingId: null,
      };

    /*
     * PLAY
     */
    case PLAY:
      return {
        ...state,
        playingId: action.payload.id,
      };

    /*
     * CHANGE_REPEAT_MODE
     */
    case CHANGE_REPEAT_MODE:
      return {
        ...state,
        repeat: (state.repeat + 1) % 3,
        shuffle: 0,
      };

    /*
     * CHANGE_SHUFFLE_MODE
     */
    case CHANGE_SHUFFLE_MODE:
      return {
        ...state,
        repeat: 0,
        shuffle: (state.shuffle + 1) % 2,
      };

    /*
     * CLEAR
     */
    case CLEAR:
      return {
        ...state,
        defaultList: [],
        currentTag: null,
      };

    /*
     * SET_DEFAULT_PLAYLIST
     */
    case SET_DEFAULT_PLAYLIST:
      return {
        ...state,
        preview: null,
      };

    /*
     * TOGGLE_HEIGHT
     */
    case TOGGLE_HEIGHT:
      return {
        ...state,
        minimalized: !state.minimalized,
      };

    /*
     * PLAYER_CLOSE
     */
    case PLAYER_CLOSE:
      return {
        ...state,
        minimalized: false,
      };

    /*
     * SET_TAG
     */
    case SET_TAG:
      return {
        ...state,
        currentTag: action.payload.tag,
      };

    /*
     * PREVIEW
     */
    case PREVIEW:
      return {
        ...state,
        preview: action.payload.video,
      };

    /*
     * BLINK
     */
    case BLINK:
      return {
        ...state,
        blink: Date.now(),
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
