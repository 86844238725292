/* eslint-disable import/prefer-default-export */
import * as LINKS from './Links.constants';

export const select = (selected) => ({
  type: LINKS.SELECT,
  payload: {
    selected,
  },
});

export const selectByIndex = (index) => ({
  type: LINKS.SELECT_BY_INDEX,
  payload: {
    index,
  },
});

export const markAsSeen = (id) => ({
  type: LINKS.MARK_AS_SEEN,
  payload: {
    id,
  },
});

export const setUserHas = (id) => ({
  type: LINKS.SET_USER_HAS,
  payload: {
    id,
  },
});

export const editLink = (action) => ({
  type: LINKS.EDIT_LINK,
  payload: action,
});

export const refresh = (endpoint) => ({
  type: LINKS.REFRESH,
  payload: {
    endpoint,
  },
});

export const setPureLinks = (links) => ({
  type: LINKS.SET_PURE_LINKS,
  payload: {
    links,
  },
});
