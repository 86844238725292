import React from 'react';
import cn from 'classnames';
import styles from '../Added/Added.module.scss';
import ThanksList from '../../../thanks/List';

/**
 * Thanks Component.
 */
const Thanks = ({ reduxActions: { closeAllModals } }) => {
  return (
    <div className={cn(styles.added, 'S')} onClick={closeAllModals}>
      <div
        className={styles.wrapper}
        onClick={(e) => {
          e.stopPropagation();

          if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
          }
        }}
      >
        <div className={styles.name}>Thanks list</div>
        <div className={styles.list}>
          <ThanksList />
        </div>
      </div>
    </div>
  );
};

export default Thanks;
