import React from 'react';
import styles from './More.module.scss';

const More = ({ reduxActions, snapId }) => {
  return (
    <div className={styles.more}>
      <button onClick={() => reduxActions.loadMore(snapId)}>Load more</button>
    </div>
  );
};

export default More;
