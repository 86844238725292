import gravatar from './gravatar';

const prepareAvatar = (user) => {
  if (user.avatar) {
    return user.avatar;
  }

  return gravatar(user.email_hash);
};

export default prepareAvatar;
