import React from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import styles from './User.module.scss';
import { Link } from 'react-router-dom';

/**
 * User Component.
 */
const User = ({ data, handleToggleSelect, selected, handleSetFilters }) => {
  const filters = [
    {
      value: {
        name: data.full_name,
        id: data.id,
        setAfterRefresh: true,
      },
      type: 'user',
    },
  ];

  return (
    <div className={styles.user}>
      <div className={cn(styles.checkbox, selected && styles.selected)}>
        <button onClick={() => handleToggleSelect(data.id)} />
      </div>
      <div className={styles.info}>
        <div className={styles.about}>
          <div className={styles.avatar}>
            {data.avatar && <img src={data.avatar} alt={data.full_name} />}
            {!data.avatar && (
              <img
                src={`https://www.gravatar.com/avatar/${data.email_hash}?d=identicon`}
                alt={data.full_name}
              />
            )}
          </div>
          <div className={styles.name}>
            <Link
              onClick={() => {
                handleSetFilters(filters);
              }}
              to="/dashboard/social"
            >
              @{data.full_name}
            </Link>
          </div>
        </div>
        <div className={styles.date}>
          <Moment fromNow ago>
            {data.pivot.created_at}
          </Moment>
        </div>
      </div>
    </div>
  );
};

export default User;
