export const PHRASE = 'phrase';
export const TAG = 'tag';
export const USER = 'user';
export const GROUP = 'group';
export const HOST = 'host';

export const SET = 'FILTERS__SET';
export const RESET = 'FILTERS__RESET';
export const SET_WHERE = 'FILTERS__SET_WHERE';
export const SET_GROUP = 'FILTERS__SET_GROUP';
export const RESET_GROUP = 'FILTERS__RESET_GROUP';
