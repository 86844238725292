import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { concat, of, race } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import getHeaders from '../../globals/helpers/headers';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';
import config from '../../config/config';

/**
 * Check if obj is function.
 *
 * @param obj
 * @returns {boolean}
 */
const isFunction = (obj) => {
  return !!(obj && obj.constructor && obj.call && obj.apply);
};

/**
 * Build query.
 *
 * @param action
 * @param state
 * @param url
 * @param isPost
 * @returns {Observable<AjaxResponse>}
 */
const query = (action, state, url, isPost) => {
  let realUrl = null;

  if (isFunction(url)) {
    realUrl = url(action, state);
  } else {
    realUrl = url;
  }

  let obj;

  if (isPost) {
    obj = {
      headers: getHeaders(),
      method: 'POST',
      url: realUrl,
      body: action.payload,
    };

    if (config.env !== 'development') {
      obj.withCredentials = true;
    }
  } else {
    obj = {
      headers: getHeaders(),
      method: 'GET',
      url: realUrl,
    };
  }

  if (config.env === 'development') {
    obj.crossDomain = true;
  }

  return ajax(obj);
};

/**
 * Prepare epic.
 *
 * @param routine
 * @param url
 * @param isPost
 * @param loadingKey
 * @param attachToRequest
 * @param attachWhenFailure
 * @param attachWhenSuccess
 *
 * @returns {function(*, *): *}
 */
const prepareEpic = (
  routine,
  url,
  isPost = true,
  loadingKey = null,
  attachToRequest = false,
  attachWhenFailure = false,
  attachWhenSuccess = false
) => (action$, state$) => {
  const stateRef = state$;
  const pipe = [];

  pipe.push(ofType(routine.TRIGGER));

  if (loadingKey !== null) {
    pipe.push(filter(() => !stateRef.value[loadingKey].loading));
  }

  pipe.push(
    mergeMap((action) => {
      return concat(
        of(attachToRequest ? routine.request(action.payload) : routine.request()),
        race(
          query(action, stateRef, url, isPost).pipe(
            mergeMap(whenSuccess(routine, attachWhenSuccess ? action : null)),
            catchError(whenFailure(routine, attachWhenFailure ? action : null))
          ),
          whenTimeout(routine)
        )
      );
    })
  );

  return action$.pipe(...pipe);
};

export default prepareEpic;
