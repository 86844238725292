import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { remove as closeAlert } from '../../../models/Alert/Alert.actions';
import ManagerComponent from './Manager.component';

/**
 * Manager Container.
 */
class Manager extends Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose(key) {
    this.props.reduxActions.closeAlert(key);
  }

  render() {
    return <ManagerComponent alerts={this.props.alerts} handleClose={this.onClose} />;
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      closeAlert,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  alerts: state.Alert,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Manager);
