import React, { Component } from 'react';
import { get, prepare } from '../../../abstracts/Sections';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import * as MODALS from '../../../models/Modals/Modals.types';
import RightBox from './../RightBox';
import MainMenu from './../MainMenu';
import { BackMenu } from '../MainMenu/MainMenu.component';
import styles from './View.module.scss';

const HOM_MANY_VARIANTS = 3;

class AddButton extends Component {
  constructor(props) {
    super(props);

    this.state = this.randomVariant();
  }

  // eslint-disable-next-line class-methods-use-this
  randomVariant() {
    return {
      // eslint-disable-next-line no-bitwise
      variant: (Math.random() * HOM_MANY_VARIANTS) >> 0,
    };
  }

  render() {
    return (
      <button
        onClick={this.props.onClick}
        className={cn(styles.button, styles[`variant${this.state.variant}`])}
        onMouseLeave={() => {
          this.setState(this.randomVariant());
        }}
      >
        <span className="icomoon icon-plus" />
        <em>Add link</em>
      </button>
    );
  }
}

/*
 * Sections
 */
export const { Body, Bar, Types } = prepare('Body', 'Bar', 'Types');

/**
 * View Component.
 */
const View = ({ smallWidth, smallHeight, children, page, simple, reduxActions }) => {
  const { BodySection, BarSection, TypesSection } = get(children, Body, Bar, Types);
  const { openModal } = reduxActions;

  return (
    <div
      className={cn({
        [styles.smallWidth]: smallWidth,
        [styles.smallHeight]: smallHeight,
      })}
    >
      <div className={cn(styles.header, TypesSection && styles.heigher)}>
        <div className={styles.top}>
          <div className={styles.topLeft}>
            <Link to="/" className={cn(styles.logo)}>
              zasejwuj.pl
            </Link>

            <AddButton
              onClick={() => {
                openModal({
                  type: MODALS.LINK_ADD,
                });
              }}
            />

            <div className={styles.filtersBar}>
              {BarSection && <div className={styles.contextBar}>{BarSection}</div>}

              {BarSection && <MainMenu page={page} />}
              {!BarSection && <BackMenu />}
            </div>
          </div>

          <RightBox />
        </div>

        {TypesSection && <div className={styles.bottom}>{TypesSection}</div>}
      </div>

      <div className={styles.content}>
        <div className={styles.inner}>{BodySection}</div>
      </div>
    </div>
  );
};

export default View;
