import React from 'react';
import styles from '../../dashboard/Bar/Bar.module.scss';
import cn from 'classnames';
import ownStyles from './Placeholder.module.scss';

const Placeholder = () => {
  return (
    <div className={cn(styles.info, ownStyles.placeholder)}>
      <div className={styles.icon}>
        <span className="icomoon icon-filter_list" />
      </div>
      <div className={cn(styles.name, ownStyles.name)}>Filtering...</div>
    </div>
  );
};

export default Placeholder;
