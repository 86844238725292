import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addAll } from '../../../models/Player/Player.actions';
import PlayerInfo from './PlayerInfo.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        addAll,
      },
      dispatch
    ),
    dispatch,
  },
});

export default connect(
  null,
  mapDispatchToProps
)(PlayerInfo);
