import React, { Fragment } from 'react';
import cn from 'classnames';
import styles from './Menu.module.scss';

/**
 * RenderMenu Component.
 */
const RenderMenu = ({ handleAddGroup, handleAddMember }) => {
  return (
    <Fragment>
      <li>
        <button onClick={handleAddGroup}>Create</button>
      </li>
      <li>
        <button onClick={handleAddMember} className={styles.other}>
          Add members
        </button>
      </li>
    </Fragment>
  );
};

const Menu = ({ small, ...rest }) => {
  return (
    <div className={cn(styles.menu, styles.big)}>
      <ul className={styles.buttonsMenu}>
        <RenderMenu {...rest} />
      </ul>
    </div>
  );
};

export default Menu;
