import { setAuthToken, clearSession } from '../../globals/helpers/headers';
import { login } from '../Account/Account.routines';
import {
  getDetails,
  changeName,
  changePassword,
  markAsInterested,
} from './User.routines';
import * as USER from '../../models/User/User.constants';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  logged: false,
  errors: null,
  token: null,
  called: false,
  logout: false,
  data: {},
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  let token;

  switch (action.type) {
    /*
     * LOADING
     */
    case getDetails.REQUEST:
    case changeName.REQUEST:
    case changePassword.REQUEST:
    case markAsInterested.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case getDetails.FULFILL:
    case changeName.FULFILL:
    case changePassword.FULFILL:
    case markAsInterested.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * ACCOUNT LOGIN
     */
    case login.SUCCESS:
      token = action.payload.token;

      if (token) {
        setAuthToken(token); // side effect

        return {
          ...state,
          token,
        };
      }

      return state;

    /*
     * DETAILS
     */
    case getDetails.SUCCESS:
      return {
        ...state,
        logged: true,
        data: {
          ...action.payload,
        },
      };

    case getDetails.FAILURE:
      return {
        ...state,
        called: true,
      };

    /*
     * CHANGE NAME
     */
    case changeName.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          full_name: action.options.fullName,
        },
      };

    case markAsInterested.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          interested: true,
        },
      };

    /*
     * LOGOUT
     */
    case USER.LOGOUT:
      clearSession();

      return {
        ...initialState,
        logout: true,
      };

    default:
      return state;
  }
};

export default Reducer;
