import React, { useState } from 'react';
import { Modal, Title, Body } from '../../Modal';
import DeckSettings from '../../../pages/Deck/DeckSettings';

/**
 * Add Link Component.
 */
const DeskSettings = ({ reduxActions }) => {
  const [changes, setChanges] = useState([false, []]);

  return (
    <Modal
      handleClose={() => {
        const [isDirty, selected] = changes;

        if (isDirty) {
          const ids = [];

          Object.keys(selected).forEach((k) => {
            if (selected[k]) {
              ids.push(k);
            }
          });

          reduxActions.saveGroups(ids);
        } else {
          reduxActions.closeModal();
        }
      }}
    >
      <Title>Deck Settings</Title>

      <Body>
        <DeckSettings setChanges={setChanges} />
      </Body>
    </Modal>
  );
};

export default DeskSettings;
