/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import { concat, of, race } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';
import settings from '../../config/settings';
import config from '../../config/config';
import getHeaders from '../../globals/helpers/headers';
import * as Fetch from './Fetch.routines';

export const check = epic(
  Fetch.check,
  (action) => `${config.apiHost}snap/check?link=${encodeURIComponent(action.payload.link)}`,
  false,
  'Fetch',
  false,
  true,
  true
);

export const fetch = (action$, state$) => {
  const routine = Fetch.fetch;
  const loading = !state$.value.Fetch.loading;

  const timeout = settings.api.fetchTimeout;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            method: 'GET',
            timeout,
            url: `${config.apiHost}snap/fetch?link=${encodeURIComponent(action.payload.link)}`,
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine, timeout)
        )
      );
    })
  );
};
