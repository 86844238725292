import React from 'react';
import ViewComponent, { Left, Right, Filters } from '../../dashboard/View';
import Links from '../../links/Links';
import Switch from '../../details/Switch';

/**
 * Waiting Room Component.
 */
const WaitingRoom = () => {
  return (
    <ViewComponent page="dashboard/waitings" name="Waitings">
      <Filters />
      <Left>
        <Links
          displayOptions={{
            showUser: false,
            showImage: false,
            isWaiting: true,
          }}
          endpoint="waitings"
        />
      </Left>
      <Right>
        <Switch type="waitings" />
      </Right>
    </ViewComponent>
  );
};

export default WaitingRoom;
