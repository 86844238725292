/* eslint-disable import/prefer-default-export */
import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { concat, of, race } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';
import config from '../../config/config';
import getHeaders from '../../globals/helpers/headers';
import * as Deck from './Deck.routines';
import * as ModalsActions from '../../models/Modals/Modals.actions';
import mapToArray from '../../globals/helpers/mapToArray';
import { addAsSuccess } from '../Alert/Alert.actions';

export const getGroups = (action$, state$) => {
  const routine = Deck.getGroups;
  const loading = !state$.value.Deck.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}deck`,
            method: 'GET',
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const saveGroups = (action$, state$) => {
  const routine = Deck.saveGroups;
  const loading = !state$.value.Deck.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax
            .post(
              `${config.apiHost}deck/save`,
              { ...mapToArray('ids', action.payload) },
              getHeaders()
            )
            .pipe(
              mergeMap(
                whenSuccess(
                  routine,
                  null,
                  of(addAsSuccess(`Your changes have been saved.`)),
                  of(Deck.getGroups.trigger()),
                  of(ModalsActions.closeModal())
                )
              ),
              catchError(whenFailure(routine, null, true))
            ),
          whenTimeout(routine)
        )
      );
    })
  );
};
