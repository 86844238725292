/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import { ofType } from 'redux-observable';
import qs from 'query-string';
import config from '../../config/config';
import { filter, map } from 'rxjs/operators';
import * as Follower from './Follower.routines';
import { SET_EMAIL } from './Follower.constants';

export const getFollower = epic(
  Follower.getFollower,
  (action) => {
    const { payload } = action;
    const user = typeof payload === 'object' ? payload.id : payload;

    const params = qs.stringify(
      {
        user,
      },
      {
        encodeValuesOnly: true,
        arrayFormat: 'index',
      }
    );

    return `${config.apiHost}follower?${params}`;
  },
  false,
  'Follower'
);

export const tryToGetFollower = (action$) => {
  return action$.pipe(
    ofType(SET_EMAIL),
    filter((action) => action.payload),
    map((action) => {
      return Follower.getFollower.trigger(action.payload);
    })
  );
};
