import React from 'react';
import { connect } from 'react-redux';
import mapStateToProps from './../helpers/mapStateToProps';
import mapDispatchToProps from './../helpers/mapDispatchToProps';
import SavedComponent from './Saved.component';
import Main from './../Main';

/**
 * Saved Container.
 */
class Saved extends Main {
  getData() {
    this.props.reduxActions.getTags();
  }

  render() {
    return (
      <SavedComponent
        loading={this.props.loading}
        tags={this.props.tags}
        selectedTags={this.state.tags}
        phrase={this.state.phrase}
        host={this.state.host}
        newTag={this.state.newTag}
        where={this.props.where}
        handleAddTag={this.addTag}
        handleAddNewTag={this.addNewTag}
        handleRemoveTag={this.removeTag}
        handleClose={this.props.handleClose}
        handleChange={this.onChange}
        handleClear={this.clear}
        handleSetFilter={this.setFilters}
        handleAddNewTagByEnter={this.addNewTagByEnter}
        handleSetFiltersByEnter={this.setFiltersByEnter}
        handleSetRef={this.setRef}
        handleChangeWhere={this.changeWhere}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Saved);
