import React from 'react';
import styles from './../Details/Details.module.scss';

/**
 * Tags Component.
 */
const Tags = ({ data, handleClick }) => {
  return (
    <div className={styles.tags}>
      <ul>
        {data.map((tag) => (
          <li key={tag}>
            <button
              onClick={() => {
                handleClick(tag);
              }}
            >
              #{tag}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tags;
