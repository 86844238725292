import React from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';

const Button = ({
  children,
  icon,
  left,
  right,
  onlyIcon = false,
  onClick,
  disabled = false,
  hoverable = true,
  classname,
  small,
  component,
}) => {
  const iconClass = `icomoon icon-${icon}`;

  let Component = 'button';

  if (component) {
    Component = component;
  }

  const rest = {};

  if (onClick) {
    rest.onClick = onClick;
  }

  if (disabled && Component === 'button') {
    rest.disabled = true;
  }

  if (!left && !onlyIcon) {
    // eslint-disable-next-line no-param-reassign
    right = true;
  }

  return (
    <Component className={cn(classname, styles.button, hoverable && styles.hoverable)} {...rest}>
      {icon && left && <span className={cn(iconClass, styles.leftIcon)} />}
      {children && <span className={styles.text}>{children}</span>}
      {icon && onlyIcon && <span className={cn(iconClass, small && styles.small)} />}
      {icon && right && <span className={cn(iconClass, styles.rightIcon)} />}
    </Component>
  );
};

export default Button;
