import React from 'react';
import moment from 'moment';
import gravatar from '../../../globals/helpers/gravatar';
import Menu, { Button as ButtonWrapper, Content } from '../../template/Menu';
import Button from '../../../shared/Button';
import styles from './../styles/Comments.module.scss';

const Comment = ({ data, handleAddMarker, markers, currentUserId, handleRemove }) => {
  const time = moment.utc(data.created_at).local();

  const avatar = data.user.avatar ? data.user.avatar : gravatar(data.user.email_hash);

  const showMarker = markers.findIndex((marker) => marker.id === data.user.id) < 0;

  return (
    <div className={styles.comment}>
      <div className={styles.avatar}>
        <img src={avatar} alt={data.user.full_name} />
      </div>
      <div className={styles.content}>
        <div className={styles.about}>
          <div className={styles.info}>
            <div className={styles.author}>{data.user.full_name}</div>
            <div className={styles.date}>{time.fromNow()}</div>
          </div>
          <div className={styles.actionsWrapper}>
            {showMarker && currentUserId !== data.user.id && (
              <div className={styles.actions}>
                <button
                  onClick={() => {
                    handleAddMarker(data.user);
                  }}
                >
                  Mark user
                </button>
              </div>
            )}

            {currentUserId === data.user.id && (
              <div className={styles.menuWrapper}>
                <Menu>
                  <ButtonWrapper>
                    <Button
                      icon="more_vert"
                      onlyIcon
                      component="div"
                      classname={styles.moreButton}
                    />
                  </ButtonWrapper>
                  <Content className={styles.menuContent}>
                    <ul>
                      <li>
                        <button
                          onClick={(event) => {
                            handleRemove(event, true, { id: data.id });
                          }}
                        >
                          Delete comment
                        </button>
                      </li>
                    </ul>
                  </Content>
                </Menu>
              </div>
            )}
          </div>
        </div>
        {data.markers && (
          <ul className={styles.markerList}>
            {data.markers.map((user) => {
              return <li key={user.id}>@{user.name}</li>;
            })}
          </ul>
        )}
        <div className={styles.text}>{data.comment}</div>
      </div>
    </div>
  );
};

export default Comment;
