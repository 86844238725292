import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import Player from '../../../services/Player';
import Menu, { Button, Content } from '../../template/Menu';
import styles from './../styles/Player.module.scss';
import Preview from './../Preview';
import List from './../List';

const repeats = ["You don't repeat videos", 'You repeat all playlist', 'You repeat current video'];

/**
 * Main Component.
 */
class Main extends Component {
  constructor(props) {
    super(props);

    this.ref = null;

    this.state = {
      showTagInput: false,
      value: '',
    };

    this.changeValue = this.changeValue.bind(this);
    this.hide = this.hide.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.setRef = this.setRef.bind(this);
    this.showTagInput = this.showTagInput.bind(this);
  }

  componentDidUpdate(props) {
    if (props.visible !== this.props.visible && !this.props.visible) {
      setTimeout(() => {
        this.hide();
      }, 1000);
    }
  }

  setRef(ref) {
    this.ref = ref;
  }

  showTagInput() {
    this.setState(
      {
        showTagInput: true,
      },
      () => {
        if (this.ref) {
          this.ref.focus();
        }
      }
    );
  }

  hide() {
    this.setState({
      showTagInput: false,
      value: '',
    });
  }

  changeValue(event) {
    this.setState({
      value: event.target.value,
    });
  }

  keyPress(event) {
    if (event.key === 'Enter') {
      const tag = this.state.value;

      this.props.reduxActions.setTag(tag);

      this.hide();
    }
  }

  render() {
    const { reduxActions, preview, visible, length, repeat, minimalized, shuffle } = this.props;

    return (
      <div
        className={cn(styles.player, !visible && styles.hidden, minimalized && styles.minimalized)}
      >
        <div className={styles.videoWrapper}>
          <iframe
            src="/youtube/index.html"
            scrolling="no"
            className={styles.video}
            ref={(iframe) => {
              if (iframe) {
                Player.setIframe(iframe);
              }
            }}
          />
          {!preview && length < 1 && (
            <div className={styles.noVideos}>There are no videos. Add some...</div>
          )}
        </div>

        <div className={styles.topControls}>
          <div className={styles.panel}>
            <ul>
              {!preview && !shuffle && (
                <Fragment>
                  <li>
                    <button onClick={reduxActions.prev}>
                      <span className="icomoon icon-skip_previous" />
                    </button>
                  </li>
                  <li>
                    <button onClick={reduxActions.next}>
                      <span className="icomoon icon-skip_next" />
                    </button>
                  </li>
                </Fragment>
              )}

              {!preview && !!shuffle && (
                <Fragment>
                  <li>
                    <button onClick={reduxActions.next}>
                      <span className="icomoon icon-skip_next" />
                    </button>
                  </li>
                </Fragment>
              )}

              {preview && (
                <li>
                  <button onClick={reduxActions.setDefaultPlaylist}>
                    <span className="icomoon icon-arrow_back" />
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className={styles.right}>
            <button onClick={reduxActions.toggleHeight}>
              <span className={styles.tooltip}>{!minimalized ? 'Minimalize' : 'Maximize'}</span>
              <span
                className={cn(
                  'icomoon',
                  !minimalized && 'icon-call_received',
                  minimalized && 'icon-call_made'
                )}
              />
            </button>
            <button className={styles.repeat} onClick={reduxActions.changeRepeatMode}>
              <span className={styles.tooltip}>{repeats[repeat]}</span>
              <span className="icomoon icon-replay" />
              {repeat > 0 && <em>{repeat === 1 ? 'all' : '1'}</em>}
            </button>

            {!preview && (
              <button className={styles.shuffle} onClick={reduxActions.changeShuffleMode}>
                <span className={styles.tooltip}>Shuffle videos</span>
                <span className="icomoon icon-shuffle" />
                {shuffle > 0 && <em>on</em>}
              </button>
            )}
          </div>
        </div>
        {!minimalized && (
          <Fragment>
            <div className={cn(styles.playlist, 'T')}>
              {preview && <Preview />}
              {!preview && <List />}
            </div>
            <div className={styles.controls}>
              <div className={styles.left}>
                <div className={styles.menuWrapper}>
                  {!this.state.showTagInput && (
                    <Menu>
                      <Button className={styles.playlistMenu}>
                        <span className="icomoon icon-more_vert" />
                      </Button>
                      <Content className={styles.menu}>
                        <ul>
                          <li>
                            <button onClick={this.showTagInput}>Load from tag #...</button>
                          </li>
                          {!preview && (
                            <li>
                              <button onClick={reduxActions.clear}>Clear playlist</button>
                            </li>
                          )}
                        </ul>
                      </Content>
                    </Menu>
                  )}
                  {this.state.showTagInput && (
                    <div className={styles.tag}>
                      <input
                        value={this.state.value}
                        onChange={this.changeValue}
                        onKeyPress={this.keyPress}
                        ref={this.setRef}
                      />
                      <button className={styles.hide} onClick={this.hide} />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.close}>
                <button onClick={reduxActions.playerClose}>Close player</button>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default Main;
