import React from 'react';
import gravatar from '../../../globals/helpers/gravatar';
import FieldError from '../../errors/Field';
import ApiError from '../../errors/Api';
import { formClassName } from '../../../abstracts/Form/Utils';
import cn from 'classnames';
import viewStyles from '../../account/View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * User Name Component.
 */
const UserName = ({ handlers, fields: { name }, errors, loading, user }) => {
  if (!user) {
    return null;
  }

  return (
    <div className={styles.user}>
      <div className={styles.avatar}>
        <img src={gravatar(user.email_hash)} alt={user.full_name} />
      </div>
      <div className={styles.name}>
        <form onSubmit={handlers.submit}>
          <div className={viewStyles.form}>
            <div className={formClassName(name, errors, viewStyles)}>
              <input
                type="text"
                className={viewStyles.input}
                placeholder="Full name"
                autoComplete="off"
                name={name.name}
                onChange={handlers.change}
                value={name.value}
              />
              <FieldError
                data={name}
                apiData={errors}
                names="Full name"
                className={viewStyles.error}
              />
            </div>

            <div className={cn(viewStyles.row, viewStyles.lastRow)}>
              <button onClick={handlers.submit} className={viewStyles.button} disabled={loading}>
                Change name
              </button>
              <ApiError data={errors} className={viewStyles.error} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserName;
