import { PHRASE, TAG, GROUP, USER, HOST } from '../../models/Filters/Filters.constants';

/**
 * Prepare query object.
 *
 * @param from
 */
const queryObject = (from) => {
  const obj = {
    tags: [],
    phrase: '',
    user: '',
    group: '',
    host: '',
  };

  // Map
  from.forEach((item) => {
    switch (item.type) {
      case PHRASE:
        obj.phrase = item.value;
        break;

      case HOST:
        obj.host = item.value;
        break;

      case USER:
        obj.user = item.value;
        break;

      case GROUP:
        obj.group = item.value;
        break;

      case TAG:
        obj.tags.push(item.value);
        break;

      default:
        break;
    }
  });

  return obj;
};

export default queryObject;
