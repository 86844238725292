/* eslint-disable import/prefer-default-export */
import { createRoutine } from '../../abstracts/Routine';

/*
 * Routines.
 */
export const getDetails = createRoutine('USER__GET_DETAILS');
export const changeName = createRoutine('USER__CHANGE_NAME');
export const changePassword = createRoutine('USER__CHANGE_PASSWORD');
export const markAsInterested = createRoutine('USER__MARK_AS_INTERESTED');
