import React from 'react';
import FieldError from '../../errors/Field';
import ApiError from '../../errors/Api';
import { formClassName } from '../../../abstracts/Form/Utils';
import cn from 'classnames';
import viewStyles from '../../account/View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * Password Component.
 */
const Password = ({
  handlers,
  errors,
  loading,
  fields: { password, rePassword, currentPassword },
}) => {
  return (
    <div className={styles.passwordWrapper}>
      <form onSubmit={handlers.submit}>
        <div className={viewStyles.form}>
          <div className={formClassName(password, errors, viewStyles)}>
            <input
              type="password"
              className={viewStyles.input}
              placeholder="Password"
              autoComplete="off"
              name={password.name}
              onChange={handlers.change}
              value={password.value}
            />
            <FieldError
              data={password}
              apiData={errors}
              names="Password"
              className={viewStyles.error}
            />
          </div>

          <div className={formClassName(rePassword, errors, viewStyles)}>
            <input
              type="password"
              className={viewStyles.input}
              placeholder="Retyped password"
              autoComplete="off"
              name={rePassword.name}
              onChange={handlers.change}
              value={rePassword.value}
            />
            <FieldError
              data={rePassword}
              apiData={errors}
              names={['Retyped password', 'password']}
              className={viewStyles.error}
            />
          </div>

          <div className={formClassName(currentPassword, errors, viewStyles)}>
            <input
              type="password"
              className={viewStyles.input}
              placeholder="Current password"
              autoComplete="off"
              name={currentPassword.name}
              onChange={handlers.change}
              value={currentPassword.value}
            />
            <FieldError
              data={currentPassword}
              apiData={errors}
              names="Current password"
              className={viewStyles.error}
            />
          </div>

          <div className={cn(viewStyles.row, viewStyles.lastRow)}>
            <button onClick={handlers.submit} className={viewStyles.button} disabled={loading}>
              Change password
            </button>
            <ApiError data={errors} className={viewStyles.error} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Password;
