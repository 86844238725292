/* eslint-disable import/prefer-default-export */
import { createRoutine } from '../../abstracts/Routine';

/*
 * Routines.
 */
export const getSnap = createRoutine('ADDING__GET_SNAP');
export const addLink = createRoutine('ADDING__ADD_LINK');
export const getDetails = createRoutine('ADDING__GET_DETAILS');
export const bulkAdding = createRoutine('ADDING__BULK_ADDING');
