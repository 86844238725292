import React from 'react';
import styles from './TagsLoader.module.scss';

const random = (w) => {
  return parseInt(w + Math.random() * (w / 2), 10);
};

const TagsLoader = ({ loading = false }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className={styles.loader}>
      <div style={{ width: random(80) }} />
      <div style={{ width: random(60) }} />
      <div style={{ width: random(100) }} />
    </div>
  );
};

export default TagsLoader;
