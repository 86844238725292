import React from 'react';
import ViewComponent, { Left, Right, Filters } from '../../dashboard/View';
import FiltersSocial from '../../filters/Social';
import Links from '../../links/Links';
import Switch from '../../details/Switch';

/**
 * Social Component.
 */
const Social = () => {
  return (
    <ViewComponent page="dashboard/social" name="Social">
      <Filters>
        <FiltersSocial />
      </Filters>
      <Left>
        <Links endpoint="social" />
      </Left>
      <Right>
        <Switch fetched type="social" />
      </Right>
    </ViewComponent>
  );
};

export default Social;
