/* eslint-disable import/prefer-default-export */
import * as ADDING from './Adding.constants';

export const reset = () => ({
  type: ADDING.RESET,
});

export const setSnap = (snap) => ({
  type: ADDING.SET_SNAP,
  payload: snap,
});
