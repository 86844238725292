import React from 'react';
import cn from 'classnames';
import addedStyles from '../../modals/types/Added/Added.module.scss';
import FulFill from '../../adding/FulFill';
import styles from './Share.module.scss';

/**
 * Share Component
 */
const Share = (props) => {
  return (
    <div className={cn(addedStyles.added, 'S')}>
      <div className={addedStyles.wrapper}>
        <div className={styles.share}>
          <div className={styles.content}>
            <FulFill asModal origin={props.payload.data} type={props.payload.type} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
