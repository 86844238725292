import qs from 'query-string';
import config from '../../config/config';
import prepareQueryObject from '../../globals/helpers/queryObject';

export const tryUseUserFilterInProperWay = (filters) => {
  // eslint-disable-next-line no-prototype-builtins
  if (filters.hasOwnProperty('user')) {
    const { user } = filters;

    if (typeof user === 'object') {
      return {
        ...filters,
        user: user.id,
      };
    }
  }

  return filters;
};

// White list of keys
const whiteList = ['sortKey', 'sortType', 'page', 'from', 'where', 'groupId'];

/**
 * Prepare link.
 *
 * @param base
 * @param filters
 * @param more
 * @returns {string}
 */
export const prepareLinkWithFilters = (base, filters, more = null) => {
  const prepared = prepareQueryObject(filters);
  let query = {};

  // filters
  Object.keys(prepared).forEach((key) => {
    if (prepared[key]) {
      query[key] = prepared[key];
    }
  });

  // e.g. query, page
  if (more) {
    Object.keys(more).forEach((key) => {
      if (whiteList.indexOf(key) >= 0) {
        query[key] = more[key];
      }
    });
  }

  query = tryUseUserFilterInProperWay(query);

  const params = qs.stringify(query, {
    encodeValuesOnly: true,
    arrayFormat: 'index',
  });

  return `${config.apiHost}${base}?${params}`;
};

export const removeIncorrectLinkFromPayload = (links) => links.filter((link) => link.base);

export const removePropsFromLink = (link) => {
  const toRemove = ['created_at', 'updated_at', 'id'];
  const newLink = { ...link };

  newLink.link_id = newLink.id;

  toRemove.forEach((key) => {
    delete newLink[key];
  });

  return newLink;
};

/**
 * It removes duplicates also. So first we need find link's url and basing on that
 * value we remove all links with the same url.
 */
export const removeFromLinks = (action, data, fieldToCompare = 'link') => {
  const { id } = action.options;
  const foundIndex = data.findIndex((link) => link.id === id);

  if (foundIndex >= 0) {
    const value = data[foundIndex][fieldToCompare];
    const newLinks = [];
    let selected = null;
    let lookingFor = true;
    let takeNext = false;
    let counter = 0;

    data.forEach((_link) => {
      if (_link[fieldToCompare] === value) {
        if (_link.id === id) {
          if (selected !== null) {
            lookingFor = false;
          } else {
            takeNext = true;
          }
        }

        ++counter;
      } else {
        newLinks.push(_link);

        if (lookingFor) {
          selected = _link.id;
        }

        if (takeNext) {
          lookingFor = false;
        }
      }
    });

    return {
      data: newLinks,
      selected,
      removeCounter: counter,
    };
  }

  return null;
};

export const massRremoveFromLinks = (action, data, currentSelected) => {
  // selected will be removed
  const founded = action.options.ids.indexOf(currentSelected);
  const counter = action.options.ids.length;
  let newIndex = -1;

  if (founded >= 0) {
    const index = data.findIndex((link) => link.id === currentSelected);

    for (let i = index + 1; i < data.length; i++) {
      if (action.options.ids.indexOf(data[i].id) < 0) {
        // not will be removed
        newIndex = i;
        break;
      }
    }

    // not found for lower part
    if (newIndex < 0) {
      for (let i = 0; i < index; i++) {
        if (action.options.ids.indexOf(data[i].id) < 0) {
          // not will be removed
          newIndex = i;
          break;
        }
      }
    }
  }

  const newData = data.filter((link) => {
    return !(action.options.ids.indexOf(link.id) >= 0);
  });

  return {
    data: newData,
    selected: newIndex >= 0 ? data[newIndex].id : currentSelected,
    removeCounter: counter,
  };
};

export const modifyLink = (state, id, callback) => {
  const index = state.data.findIndex((link) => link.id === id);
  let newState;

  if (index >= 0) {
    newState = {
      ...state,
      data: [...state.data],
    };

    newState.data[index] = {
      ...newState.data[index],
      ...callback(newState.data[index]),
    };

    return newState;
  }

  return state;
};

export const chnageSpeedDial = (state, id, value) => {
  return modifyLink(state, id, (link) => {
    const addTag = 'speedDial';
    let newTags = null;

    if (link.tags) {
      newTags = link.tags.filter((tag) => tag.toLowerCase() !== 'speeddial');

      if (value) {
        newTags.push(addTag);
      }
    } else if (value) {
      newTags = [addTag];
    }

    if (newTags && newTags.length === 0) {
      newTags = [];
    }

    return {
      speed_dial: value,
      tags: newTags,
    };
  });
};
