import React from 'react';
import { Modal, Body } from '../../Modal';
import NoSpeedDial from '../../../noItems/NoSpeedDial';
import Link from './components/Link';
import styles from './SpeedDial.module.scss';

/**
 * SpeedDial Component.
 */
const SpeedDial = ({ links, added, handleCloseModal }) => {
  return (
    <Modal handleClose={handleCloseModal} className={styles.speeddial} wide>
      <Body className={styles.body}>
        {links.length > 0 && (
          <ul>
            {links.map((link) => (
              <li key={link.id}>
                <Link data={link} />
              </li>
            ))}
          </ul>
        )}
        {added && links.length < 1 && <NoSpeedDial />}
      </Body>
    </Modal>
  );
};

export default SpeedDial;
