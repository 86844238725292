import React from 'react';
import ViewComponent, { Center as CenterSection, Menu, Filters } from '../../dashboard/View';
import FollowersMenu from '../../followers/Menu';
import FiltersPhrase from '../../filters/Phrase';
import List from '../../followers/List';

/**
 * Followers Component.
 */
const Followers = () => {
  return (
    <ViewComponent page="followers" simple name="Followers">
      <Menu>
        <FollowersMenu />
      </Menu>
      <Filters>
        <FiltersPhrase />
      </Filters>
      <CenterSection>
        <List />
      </CenterSection>
    </ViewComponent>
  );
};

export default Followers;
