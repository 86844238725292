import { getFollowers, unfollow, invite, follow } from './Followers.routines';
import { RESET } from './Followers.constants';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  data: [],
  pagination: null,
  errors: null,
  response: null,
  responseReceived: false,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case getFollowers.TRIGGER:
      if (action.payload.withReload) {
        return {
          ...initialState,
          loading: state.loading,
        };
      }

      return state;

    /*
     * LOADING
     */
    case getFollowers.REQUEST:
    case invite.REQUEST:
    case follow.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case getFollowers.FULFILL:
    case unfollow.FULFILL:
    case invite.FULFILL:
    case follow.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * GET FOLLOWERS
     */
    case getFollowers.SUCCESS:
      return {
        ...state,
        data: [...((action.payload && action.payload.data) || [])],
        pagination: { ...((action.payload && action.payload.pagination) || {}) },
      };

    /*
     * UNFOLLOW
     */
    case unfollow.REQUEST:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case unfollow.FAILURE:
      return {
        ...state,
        errors: { ...action.payload.response.errors },
      };

    /*
     * INVITE & FOLLOW
     */
    case invite.SUCCESS:
    case follow.SUCCESS:
      return {
        ...state,
        response: action.payload,
        responseReceived: true,
      };

    /*
     * RESET
     */
    case RESET:
      return {
        ...state,
        errors: null,
        response: initialState.response,
        responseReceived: initialState.responseReceived,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
