import React, { Component } from 'react';
import cn from 'classnames';
import gravatar from '../../../globals/helpers/gravatar';
import cutDataForPlaylist from '../../../globals/helpers/cutDataForPlaylist';
import loadImage from '../../../globals/helpers/loadImage';
import styles from './Link.module.scss';

const initialDisplayOptions = {
  showUser: true,
  showImage: true,
  isWaiting: false,
  showCheckbox: false,
};

/**
 * Link Component.
 */
class Link extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingImage: true,
      errorImage: false,
    };
  }

  componentDidMount() {
    if (this.props.data.now_added) {
      setTimeout(() => {
        // Mark as seen when link is new - just added
        this.props.handleMarkAsSeen(this.props.data.id);
      }, 15000);
    }

    if (this.props.data.image && this.props.data.image.url) {
      this.imageLoader = loadImage(this.props.data.image.url, (value) => {
        this.setState({
          loadingImage: false,
          errorImage: value,
        });
      });
    }
  }

  componentWillUnmount() {
    if (this.imageLoader) {
      this.imageLoader.destroy();
    }
  }

  render() {
    const {
      data,
      selected,
      displayOptions,
      checked,
      handleChangeSelection,
      reduxActions,
    } = this.props;

    const options = {
      ...initialDisplayOptions,
      ...displayOptions,
    };

    const author = data.user || data.from_user || {};

    if (!author.avatar) {
      author.avatar = gravatar(author.email_hash);
    }

    const withAvatar = options.showUser && author.avatar;

    const showImage = options.showImage && data.image && data.image.url;

    return (
      <div
        className={cn(
          styles.link,
          selected === data.id && styles.selected,
          // !options.showUser && styles.withPadding,
          data.now_added && styles.new,
          options.isWaiting && styles.waiting,
          data.hidden && styles.hidden
        )}
      >
        <div className={cn(styles.inside, !options.isWaiting && styles.withMinHeight)}>
          {showImage && (
            <div className={styles.image}>
              {!this.state.loadingImage && !this.state.errorImage && (
                <img src={data.image.url} alt={data.title} />
              )}
              {data.type === 'video' && (
                <div
                  role="button"
                  className={styles.play}
                  onClick={(event) => {
                    event.stopPropagation();

                    reduxActions.preview(cutDataForPlaylist(data));
                  }}
                />
              )}
            </div>
          )}
          {/* This is fix for flex lef-align */
          !showImage && <div />}

          {options.showCheckbox && (
            <div className={styles.checkbox}>
              <span
                className={cn(checked && styles.selected)}
                onClick={(event) => {
                  handleChangeSelection(event, data.id);
                }}
              />
            </div>
          )}

          <div
            className={cn({
              [styles.info]: true,
              // [styles.withMarginInsteadImage]: options.showImage && !data.image,
            })}
          >
            <h2>
              {data.title}
              <a href={data.link} rel="noreferrer noopener" target="_blank">
                <img src="/images/link.svg" alt={data.title} />
              </a>
            </h2>

            <div className={cn(styles.aboutWrapper)}>
              <div className={styles.about}>
                <div className={styles.host}>{data.link}</div>

                {options.showUser && (
                  <div className={styles.user}>
                    <div className={styles.name}>@{author.full_name}</div>

                    {withAvatar && (
                      <div className={styles.avatar}>
                        <img src={author.avatar} alt={author.full_name} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Link;
