import React from 'react';
import styles from '../../users/List/List.module.scss';
import gravatar from '../../../globals/helpers/gravatar';
import cn from 'classnames';
import { Link } from 'react-router-dom';

const render = (props) => (user) => {
  const filters = [
    {
      value: {
        name: user.full_name,
        id: user.id,
        setAfterRefresh: true,
      },
      type: 'user',
    },
  ];

  return (
    <li key={user.id}>
      <div className={styles.user}>
        <div className={styles.about}>
          <div className={styles.avatar}>
            <img src={gravatar(user.email_hash)} />
          </div>
          <div className={styles.name}>
            {props.setFilters && (
              <Link
                to="/dashboard/social"
                onClick={() => {
                  props.setFilters(filters);
                }}
              >
                {user.full_name}
              </Link>
            )}
            {!props.setFilters && user.full_name}
          </div>
        </div>
        <div className={styles.select}>
          {user.id !== props.ownerId && (
            <button
              className={cn({
                [styles.selected]: props.selected.findIndex((id) => id === user.id) >= 0,
              })}
              onClick={() => {
                props.handleSelectChange(user.id);
              }}
            >
              selected
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default render;
