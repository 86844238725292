import React from 'react';
import Alert from '../../alerts/Alert';
import styles from './Manager.module.scss';

/**
 * Manager Component.
 */
const Manager = ({ alerts, handleClose }) => {
  return (
    <div className={styles.alerts}>
      {alerts.map((item) => (
        <Alert key={item.alert.key} data={item} handleClose={handleClose} />
      ))}
    </div>
  );
};

export default Manager;
