import React from 'react';
import cn from 'classnames';
import Settings from '../../config/settings';
import styles from './ProgressBar.module.scss';

/**
 * ProgressBar Component.
 */
const ProgressBar = ({ status, show }) => {
  const css = {};

  if (show) {
    css.transition = `${
      status === 1 ? Settings.progressBar.loading : Settings.progressBar.finishing
    }ms all linear`;
  }

  return (
    <div className={cn(styles.container, show && styles.show)}>
      <div
        style={css}
        className={cn(styles.bar, {
          [styles.loading]: status === 1,
          [styles.finishing]: status === 2,
        })}
      />
    </div>
  );
};

export default ProgressBar;
