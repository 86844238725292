/* eslint-disable no-param-reassign, no-bitwise, no-multi-assign, prefer-template */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styles from './Pagination.module.scss';

const PAGE_KEY = 'page';

/**
 * Default render.
 */
const defaultRender = (match, location) => {
  const newStr = [];
  const regex = new RegExp(':' + PAGE_KEY + '[^/]*', 'i');
  const splited = match.path.split('/');
  const realSplited = match.url.split('/');

  const j = Math.max(splited.length, realSplited.length);

  for (let i = 0; i < j; i += 1) {
    if (regex.exec(splited[i])) {
      newStr.push('@@');
    } else {
      newStr.push(realSplited[i]);
    }
  }

  const str = newStr.join('/');

  return (page, name) => <Link to={`${str.replace('@@', page)}${location.search}`}>{name}</Link>;
};

/**
 * Print from code.
 *
 * @param code
 * @returns {string}
 */
const print = (code) => {
  /* global String */
  return String.fromCharCode(code);
};

/**
 * Pagination Component.
 */
const Pagination = ({ current, pages, render = null, match, location }) => {
  let canPrev = false;
  let canPrevFive = false;
  let canNext = false;
  let canNextFive = false;
  let canFirst = true;
  let canLast = true;

  if (current !== undefined) {
    current >>= 0;
  } else {
    current = match.params[PAGE_KEY] >> 0;
  }

  current = Math.max(current, 0);

  pages >>= 0;

  if (!current) {
    current = 1;
  }

  if (current > 1) {
    canPrev = true;
  }

  if (current > 6) {
    canPrevFive = true;
  }

  if (current < pages) {
    canNext = true;
  }

  if (current < pages - 5) {
    canNextFive = true;
  }

  if (pages > 1) {
    canFirst = true;
  }

  if (current === 1) {
    canFirst = false;
  }

  if (current > pages) {
    canPrev = canPrevFive = canNext = canNextFive = false;
  }

  if (current >= pages) {
    canLast = false;
  }

  const sum = !canFirst && !canPrevFive && !canNextFive && !canLast && !canPrev && !canNext;

  if (!render) {
    render = defaultRender(match, location);
  }

  if (pages <= 1) {
    return null;
  }

  return (
    <div className={styles.pagination}>
      {canFirst && <div className={styles.button}>{render(1, 1)}</div>}
      {canPrevFive && <div className={styles.button}>{render(current - 5, current - 5)}</div>}
      {!sum && <div className={styles.current}>{current}</div>}
      {canNextFive && <div className={styles.button}>{render(current + 5, current + 5)}</div>}
      {canLast && <div className={styles.button}>{render(pages, pages)}</div>}

      <div className={styles.separator} />

      {canPrev && <div className={styles.button}>{render(current - 1, `${print(171)}`)}</div>}
      {!canPrev && (
        <div className={styles.button}>
          <span>{`${print(171)}`}</span>
        </div>
      )}
      {canNext && <div className={styles.button}>{render(current + 1, `${print(187)}`)}</div>}
      {!canNext && (
        <div className={styles.button}>
          <span>{`${print(187)}`}</span>
        </div>
      )}
    </div>
  );
};

export default withRouter(Pagination);
