import { connect } from 'react-redux';
import Indicator from './Indicator.component';

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  playerOpen: state.App.playerOpen,
  logged: state.User.logged,
});

export default connect(mapStateToProps)(Indicator);
