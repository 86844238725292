import React from 'react';
import { Modal, Body, Title } from '../../Modal';
import FieldError from '../../../errors/Field';
import ApiError from '../../../errors/Api';
import { formClassName } from '../../../../abstracts/Form/Utils';
import cn from 'classnames';
import viewStyles from '../../../account/View/View.module.scss';
import styles from '../../Modal/Modal.module.scss';

/**
 * Group Component.
 */
const Group = ({ handlers, fields, errors, loading, editMode, handleCloseModal }) => {
  const { name } = fields;

  return (
    <Modal handleClose={handleCloseModal}>
      <Title>{editMode ? 'Editing' : 'Creating'} group</Title>

      <Body>
        <form onSubmit={handlers.submit}>
          <div className={viewStyles.form}>
            <div className={formClassName(name, errors, viewStyles)}>
              <input
                type="text"
                className={viewStyles.input}
                placeholder="Name of group"
                autoComplete="off"
                name={name.name}
                onChange={handlers.change}
                value={name.value}
                ref={handlers.ref}
              />
              <FieldError
                data={name}
                apiData={errors}
                names="Group name"
                className={viewStyles.error}
              />
            </div>

            <div className={cn(styles.controls)}>
              <button
                type="submit"
                onClick={handlers.submit}
                className={viewStyles.button}
                disabled={loading}
              >
                {editMode ? 'Edit' : 'Create'}
              </button>
              <ApiError data={errors} className={viewStyles.error} />
            </div>
          </div>
        </form>
      </Body>
    </Modal>
  );
};

export default Group;
