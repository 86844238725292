import { of } from 'rxjs';
import { logout } from '../../models/User/User.actions';
import { API, API_ENDPOINT_NOT_FOUND, SOMETHING_WENT_WRONG } from './dictionary';

export const RETURN = 'RETURN';
export const USE_VALUE = 'USE_VALUE';
export const INHERIT = 'INHERIT';

export const DEFAULT_ERROR = 500;

/**
 * Map errors.
 *
 * This object defines how should react whenFailure procedure.
 */
export const Map = {
  401: {
    type: RETURN,
    value: of(logout()),
  },
  403: {
    type: RETURN,
    value: of(logout()),
  },
  404: {
    type: USE_VALUE,
    value: {
      [API]: [API_ENDPOINT_NOT_FOUND],
    },
  },
  422: {
    type: INHERIT,
  },
  500: {
    type: USE_VALUE,
    value: {
      [API]: [SOMETHING_WENT_WRONG],
    },
  },
};

const codes = (status) => {
  if (Object.hasOwnProperty.call(Map, status)) {
    return Map[status];
  }

  return Map[DEFAULT_ERROR];
};

export default codes;
