/* global localStorage */
import settings from '../../config/settings';

/**
 * Headers Object.
 */
const headers = {};

/**
 * Set any header.
 */
export const setHeader = (key, value) => {
  headers[key] = value;
};

/**
 * Set Auth Token.
 */
export const setAuthToken = (token) => {
  localStorage.setItem(settings.auth.tokenName, token);

  setHeader(
    settings.auth.tokenName,
    `${settings.auth.tokenPrefix}${token}${settings.auth.tokenPostfix}`
  );
};

/**
 * Clear session.
 */
export const clearSession = () => {
  localStorage.removeItem(settings.auth.tokenName);

  Object.keys(headers).forEach((key) => {
    delete headers[key];
  });
};

/**
 * Return headers.
 */
const getHeaders = () => ({ ...headers });

export default getHeaders;
