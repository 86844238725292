/* eslint-disable import/prefer-default-export */
import * as FILTERS from './Filters.constants';

export const setFilters = (filters) => ({
  type: FILTERS.SET,
  payload: {
    filters,
  },
});

export const resetFilters = () => ({
  type: FILTERS.RESET,
});

export const setWhere = (where) => ({
  type: FILTERS.SET_WHERE,
  payload: {
    where,
  },
});

export const setGroup = (payload) => ({
  type: FILTERS.SET_GROUP,
  payload,
});

export const resetGroup = () => ({
  type: FILTERS.RESET_GROUP,
});
