import React from 'react';
import ViewComponent, { Left, Right } from '../../dashboard/View';
import Links from '../../links/Links';
import Switch from '../../details/Switch';

/**
 * For You Component.
 */
const ForYou = () => {
  return (
    <ViewComponent page="dashboard/for-you" name="For you">
      <Left>
        <Links endpoint="for_you" />
      </Left>
      <Right>
        <Switch fetched type="for_you" />
      </Right>
    </ViewComponent>
  );
};

export default ForYou;
