import React, { Component } from 'react';
import styles from './AutoSizeInput.module.scss';

/**
 * AutoSizeInput Component
 */
class AutoSizeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null,
    };

    this.onChange = this.onChange.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  shouldComponentUpdate(props, state) {
    return props.value !== this.props.value || state.width !== this.state.width;
  }

  onChange(event) {
    const value = event.target.value;

    if (this.ref) {
      this.ref.innerHTML = value;

      const width = this.ref.offsetWidth + 4; // border size

      this.setState({
        width,
      });
    }

    this.props.onChange(event);
  }

  setRef(ref) {
    this.ref = ref;

    if (this.ref) {
      const inputs = this.ref.parentNode.getElementsByTagName('input');

      if (inputs && inputs.length > 0) {
        const input = inputs[0];

        const toCopy = ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'padding'];

        const cs = document.defaultView.getComputedStyle(input);

        toCopy.forEach((key) => {
          this.ref.style[key] = cs.getPropertyValue(key);
        });
      }
    }
  }

  render() {
    const { handleSetFocus, ...rest } = this.props;

    return (
      <div
        className={styles.autoSizeWrapper}
        style={{
          width: this.state.width,
        }}
      >
        <span className={styles.magicInput} ref={this.setRef} />
        <input
          {...rest}
          onChange={this.onChange}
          ref={handleSetFocus}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      </div>
    );
  }
}

export default AutoSizeInput;
