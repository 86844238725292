import React from 'react';
import { Link } from 'react-router-dom';
import * as MODALS from '../../../models/Modals/Modals.types';
import styles from './../styles/Common.module.scss';

/**
 * No Groups Component.
 */
const NoGroups = ({ reduxActions }) => {
  return (
    <div className={styles.noLinks}>
      <h4>No groups found</h4>

      <div className={styles.info}>
        <span>Try to use solutions:</span>

        <ul>
          <li>
            <button
              onClick={() => {
                reduxActions.resetFilters();
              }}
            >
              Clear filters and go to basic list
            </button>
          </li>
          <li>
            <Link to="/groups">Go to first page</Link>
          </li>
          <li>
            <button
              onClick={() => {
                reduxActions.openModal({
                  type: MODALS.GROUP_ADD,
                });
              }}
            >
              Create new group
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NoGroups;
