import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Groups from '../../../models/Groups/Groups.routines';
import { bindActionCreators } from 'redux';
import { setGroup, resetGroup } from '../../../models/Filters/Filters.actions';

/**
 * Group Loader.
 */
class GroupLoader extends Component {
  componentDidMount() {
    if (!this.props.added) {
      this.props.reduxActions.getAllGroups();
    }

    if (this.props.added) {
      this.setGroup();
    }
  }

  componentDidUpdate(props) {
    if (
      (props.added !== this.props.added && this.props.added) ||
      props.match !== this.props.match
    ) {
      this.setGroup();
    }
  }

  componentWillUnmount() {
    this.props.reduxActions.resetGroup();
  }

  setGroup() {
    const group = parseInt(this.props.match.params.id, 10);

    if (group) {
      const found = this.props.groups.find((_g) => _g.id === group);

      if (found) {
        found.index = Date.now();

        this.props.reduxActions.setGroup(found);
      } else {
        this.props.reduxActions.setGroup(null);
      }
    }
  }

  render() {
    return null;
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getAllGroups: Groups.getAllGroups.trigger,
      setGroup,
      resetGroup,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  groups: state.Groups.all.data,
  added: state.Groups.all.added,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroupLoader)
);
