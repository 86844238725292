/* eslint-disable no-empty */
import React, { Component } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from '../../types/Added/Added.module.scss';
import ownStyles from './SelectGroup.module.scss';

/**
 * GroupMembers Component.
 */
class GroupMembers extends Component {
  constructor(props) {
    super(props);

    this.setRef = this.setRef.bind(this);
  }

  setRef(ref) {
    if (ref) {
      ref.scrollTo(0, 0);
    }
  }

  render() {
    const {
      loading,
      groups,
      group: selected,
      added,
      search,
      handleCloseModal,
      handleSetRef,
      handleChangeSearch,
    } = this.props;

    const re = new RegExp(`^${search}`, 'i');

    return (
      <div className={cn(styles.added, 'S')} onClick={handleCloseModal} ref={this.setRef}>
        <div
          className={cn(styles.wrapper, ownStyles.modal)}
          onClick={(e) => {
            e.stopPropagation();

            if (e.nativeEvent) {
              e.nativeEvent.stopImmediatePropagation();
            }
          }}
        >
          <div className={styles.name}>Choose group</div>
          <div className={styles.list}>
            {loading && <div className={ownStyles.loading}>Loading...</div>}

            {!loading && added && groups.length < 1 && (
              <div className={ownStyles.noGroup}>You do not have any group</div>
            )}

            {!loading && groups.length > 0 && (
              <div>
                <div className={ownStyles.inputWrapper}>
                  <input
                    type="text"
                    className={ownStyles.input}
                    placeholder="Search group..."
                    ref={handleSetRef}
                    onChange={handleChangeSearch}
                  />
                </div>
                <ul className={cn(ownStyles.select, 'T')}>
                  {groups
                    .filter((group) => {
                      if (search.length < 1) {
                        return true;
                      }

                      try {
                        return group.name.match(re);
                      } catch (e) {}

                      return false;
                    })
                    .map((group) => (
                      <li
                        key={group.id}
                        className={cn(selected && selected.id === group.id && ownStyles.selected)}
                      >
                        <Link onClick={handleCloseModal} to={`/dashboard/grouped/${group.id}`}>
                          {group.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default GroupMembers;
