import React from 'react';
import styles from './../../SpeedDial.module.scss';

const Link = ({ data }) => {
  const style = {};

  if (data.image) {
    style.backgroundImage = `url('${data.image.url}')`;
  }

  return (
    <a href={data.link} target="_blank" rel="noreferrer noopener" className={styles.link}>
      <div className={styles.cover} style={style} />
      <div className={styles.anchor}>
        <h3>{data.title}</h3>
        <h4>{data.base}</h4>
      </div>
    </a>
  );
};

export default Link;
