/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Switch } from 'react-router-dom';

/**
 * Auth Routes Component.
 */
const AuthRoutes = ({ children, isAuthenticated }) => {
  const childrenArray = React.Children.count(children) > 1 ? children : [children];

  // console.log('Route changed');

  return (
    <Switch>
      {childrenArray.map((child, i) => {
        return React.cloneElement(child, {
          key: i,
          isAuthenticated,
        });
      })}
    </Switch>
  );
};

export default AuthRoutes;
