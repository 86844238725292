import React from 'react';
import FormClass from '../../../../abstracts/Form/Class';
import { bindRoutines } from '../../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Validator from '../../../../abstracts/Form/Validator';
import * as Groups from '../../../../models/Groups/Groups.routines';
import { reset } from '../../../../models/Groups/Groups.actions';
import * as ModalsActions from '../../../../models/Modals/Modals.actions';
import mapLocationToObject from '../../../../globals/helpers/mapLocationToObject';
import { upload } from '../../../adding/Friends/Friends.helper';
import * as MODALS from '../../../../models/Modals/Modals.types';
import MembersComponent from './Members.component';

/**
 * Members Container.
 */
class Members extends FormClass {
  static initialState(props) {
    return {
      fields: {
        emails: {
          name: 'emails',
          value: '',
          error: null,
          focus: false,
        },
        group: {
          name: 'group',
          value: (props && props.payload && props.payload.group && props.payload.group.id) || null,
          error: null,
          focus: false,
        },
      },
    };
  }

  componentDidMount() {
    if (!this.props.added) {
      this.props.reduxActions.getAllGroups();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.responseReceived !== this.props.responseReceived && this.props.responseReceived) {
      this.prepareAlerts();
    }
  }

  prepareAlerts() {
    const collection = [];

    Object.keys(this.props.response).forEach((user) => {
      collection.push({
        status: this.props.response[user].toLowerCase(),
        name: user,
      });
    });

    upload(this.props.response);

    const selectedGroup = this.props.groups.find(
      (group) => group.id === this.state.fields.group.value
    );

    this.props.reduxActions.closeAndOpen({
      type: MODALS.ADDED,
      payload: {
        title: `Adding members to: ${selectedGroup ? selectedGroup.name : 'group'}`,
        type: 'members',
        data: collection,
      },
    });

    this.props.reduxActions.reset();
  }

  bindings() {
    this.tryClose = this.tryClose.bind(this);

    this.changeEmails = this.changeEmails.bind(this);
    this.changeGroup = this.changeGroup.bind(this);
  }

  setVars(props) {
    this.groupAsProps = !!(props.payload && props.payload.group && props.payload.group.id);
  }

  changeEmails(key, data) {
    this.setState({
      fields: {
        ...this.state.fields,
        emails: {
          ...this.state.fields.emails,
          value: data && data.length > 0 ? data : '',
        },
      },
    });
  }

  changeGroup(group) {
    if (this.groupAsProps) {
      return;
    }

    this.setState({
      fields: {
        ...this.state.fields,
        group: {
          ...this.state.fields.group,
          value: group,
        },
      },
    });
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {
      emails: [Validator.rules.required(), Validator.rules.emails(-1)],
      group: [Validator.rules.required()],
    };
  }

  submitForm() {
    const payload = {
      emails: this.state.fields.emails.value,
      group: this.state.fields.group.value,
      reload: mapLocationToObject(this.props.location, false),
    };

    this.props.reduxActions.addMembers(payload);
  }

  tryClose(event) {
    this.props.reduxActions.closeModalWithConfirm(event, this.isDirty());
  }

  render() {
    return (
      <MembersComponent
        fields={this.state.fields}
        handlers={this.handlers}
        loading={this.props.loading}
        errors={this.props.errors}
        groups={this.props.groups}
        selectedGroup={this.state.fields.group}
        groupAsProps={this.groupAsProps}
        added={this.props.added}
        handleCloseModal={this.tryClose}
        handleChangeEmails={this.changeEmails}
        handleChangeGroup={this.changeGroup}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        addMembers: Groups.addMembers.trigger,
        getAllGroups: Groups.getAllGroups.trigger,
      },
      dispatch
    ),

    ...bindActionCreators(
      {
        reset,
        closeAndOpen: ModalsActions.closeAndOpen,
      },
      dispatch
    ),

    closeModalWithConfirm: ModalsActions.wrapWithConfirm(
      dispatch,
      ModalsActions.closeModal,
      'Do you want to abort adding members?'
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Groups.loading,
  errors: state.Groups.errors,
  responseReceived: state.Groups.responseReceived,
  response: state.Groups.response,
  groups: state.Groups.all.data,
  added: state.Groups.all.added,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Members)
);
