import * as MODALS from '../../models/Modals/Modals.types';
import { OPEN_MODAL, CLOSE_MODAL, CLOSE_ALL_MODALS, CLOSE_AND_OPEN } from './Modals.constants';

/*
 * Modals actions
 */
export const openModal = (payload) => ({
  type: OPEN_MODAL,
  payload,
});

export const closeAndOpen = (payload) => ({
  type: CLOSE_AND_OPEN,
  payload,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const closeAllModals = () => ({
  type: CLOSE_ALL_MODALS,
});

export const wrapWithConfirm = (dispatch, actionCreator, title, shouldCloseAllModals = false) => {
  return (...rest) => {
    let isDirty = true;

    const event = rest.shift();

    if (typeof rest[0] === 'boolean') {
      isDirty = rest.shift();
    }

    if (!isDirty) {
      dispatch(closeModal());
    } else {
      const dispatchedAction = openModal({
        type: MODALS.CONFIRM,
        payload: {
          title,
          click: event
            ? {
                x: event.pageX,
                y: event.pageY,
              }
            : null,
          action: actionCreator(...rest),
          shouldCloseAllModals,
        },
      });

      dispatch(dispatchedAction);
    }
  };
};
