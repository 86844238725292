import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from '../../../models/Account/Account.actions';
import EmailComponent from './../Email';
import FieldsComponent from './../Fields';
import CreatedComponent from './../Created';

/**
 * Register Container.
 */
class Register extends Component {
  componentWillUnmount() {
    this.props.reduxActions.reset();
  }

  render() {
    switch (this.props.window) {
      case 'email':
        return <EmailComponent />;

      case 'fields':
        return <FieldsComponent />;

      case 'created':
        return <CreatedComponent />;

      default:
        return null;
    }
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      reset,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  window: state.Account.register.window,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
