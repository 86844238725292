import * as ALERT from './Alert.constants';

export const remove = (key) => ({
  type: ALERT.REMOVE,
  key,
});

export const addAsSuccess = (info) => ({
  type: ALERT.SUCCESS,
  payload: {
    info,
  },
});

export const addAsInfo = (info) => ({
  type: ALERT.INFO,
  payload: {
    info,
  },
});
