import React from 'react';
import styles from './NoSpeedDial.module.scss';

/**
 * NoSpeedDial Component.
 */
const NoSpeedDial = () => {
  return (
    <div className={styles.wrapper}>
      <h3>Choose your link from saved and use context menu to add to Speed Dial</h3>
      <img src="/images/speeddial.png" />
      <h3>
        or when you adding link use tag <span>#speedDial</span>.
      </h3>
    </div>
  );
};

export default NoSpeedDial;
