import React from 'react';
import Song from './../Song';

const List = ({ videos, playingId }) => {
  return videos.map((video, i) => (
    <Song key={`${video.details.videoId}`} data={video} playingId={playingId} />
  ));
};

export default List;
