import { Component } from 'react';
import Validator from './../Validator';

/**
 * Form Component.
 *
 * field: {
 *    value: 'value',
 *    error: null,
 *    focus: true,
 * }
 */
class FormComponent extends Component {
  static initialState() {
    return {};
  }

  constructor(props) {
    super(props);

    // Bindings form handlers
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.isDirty = this.isDirty.bind(this);

    // Bindings ref
    this.setRef = this.setRef.bind(this);

    this.setHandlers();

    // Bindings state
    this.getState = this.getState.bind(this);

    // State and validators
    this.state = this.constructor.initialState(props);

    // Extended bindings
    this.bindings();
    this.setVars(props);

    this.validators = this.setValidators();
  }

  /**
   * Focus handler.
   *
   * @param e
   */
  onFocus(e) {
    const { name } = e.target;

    this.modifyField(name, {
      focus: true,
    });
  }

  /**
   * Blur handler.
   *
   * @param e
   */
  onBlur(e) {
    const { name, checked, type } = e.target;
    let { value } = e.target;

    let error = null;

    if (type === 'checkbox') {
      value = checked;
    }

    // Validate only if not empty
    if (Validator.get.is(value)) {
      error = this.validateField(name, value);
    }

    this.modifyField(name, {
      focus: false,
      error,
    });
  }

  /**
   * Change handler.
   *
   * @param e
   */
  onChange(e) {
    const { name, checked, type } = e.target;
    let { value } = e.target;

    if (type === 'checkbox') {
      value = checked;
    }

    this.modifyField(name, {
      value,
      error: null,
    });
  }

  /**
   * Submit handler.
   *
   * @param e
   */
  onSubmit(e) {
    e.preventDefault();

    const newFields = this.beforeValidation(this.state.fields);

    let withError = false;

    Object.keys(this.validators).forEach((name) => {
      const error = this.validateField(name, newFields[name].value);

      newFields[name].error = error;

      if (error) {
        withError = true;
      }
    });

    this.setState(
      {
        fields: newFields,
      },
      () => {
        if (!withError) {
          this.submitForm();
        }
      }
    );
  }

  /**
   * Set reference.
   *
   * @param ref
   */
  setRef(ref) {
    if (ref) {
      const small = ref.name.toLowerCase();
      const name = `ref${small.charAt(0).toUpperCase() + small.slice(1)}`;

      this[name] = ref;
    }
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {};
  }

  /**
   * Method uses in Validator to check if validated values are in relation
   * with values from state.
   *
   * @returns object
   */
  getState() {
    return this.state;
  }

  /**
   * Create object with handlers.
   */
  setHandlers() {
    this.handlers = {
      submit: this.onSubmit,
      focus: this.onFocus,
      blur: this.onBlur,
      change: this.onChange,
      ref: this.setRef,
    };
  }

  /* eslint-disable class-methods-use-this */
  setVars() {}

  /* eslint-disable class-methods-use-this */
  submitForm() {}

  /* eslint-disable class-methods-use-this */
  bindings() {}

  /**
   * Build new state according to given part of state related to field.
   *
   * @param name
   * @param newValues
   */
  modifyField(name, newValues) {
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: {
          ...this.state.fields[name],
          ...newValues,
        },
      },
    });
  }

  /**
   * Check if current state is the same as initial state.
   * Return true if not, return false if as the same.
   *
   * @returns {boolean}
   */
  isDirty() {
    const freshState = this.constructor.initialState(this.props);
    const { fields } = freshState;

    if (!fields) {
      return false;
    }

    const keys = Object.keys(fields);

    const isFresh = keys.reduce((prev, key) => {
      return prev && fields[key].value === this.state.fields[key].value;
    }, true);

    return !isFresh;
  }

  /**
   * Validate field.
   *
   * @param field
   * @param value
   *
   * @returns string|null
   */
  validateField(field, value) {
    let error;
    const validators = this.validators[field];

    if (!validators) {
      return null;
    }

    for (let i = 0; i < validators.length; i += 1) {
      error = validators[i](value);

      if (error) {
        return error;
      }
    }

    return null;
  }

  /* eslint-disable class-methods-use-this */
  beforeValidation(fields) {
    return fields;
  }
}

export default FormComponent;
