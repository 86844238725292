import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, remove } from '../../../models/Comments/Comments.routines';
import * as ModalsActions from '../../../models/Modals/Modals.actions';
import Comments from './Comments.component';

const mapStateToProps = (state) => {
  return {
    snapId: state.Comments.snap,
    groupId: state.Comments.group,
    type: state.Comments.enabled,
    loading: state.Comments.loading,
    comments: state.Comments.data,
    currentUserId: state.User.data.id,
    showMore: state.Comments.showMore,
    user: state.User.data,
  };
};

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        loadComments: get.trigger,
      },
      dispatch
    ),
    remove: ModalsActions.wrapWithConfirm(
      dispatch,
      remove.trigger,
      'Do you want to remove this comment?'
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments);
