import React, { Fragment } from 'react';
import cn from 'classnames';
import Pagination from '../../template/Pagination';
import Loader from '../../users/Loader';
import styles from './List.module.scss';

/**
 * List Component.
 */
const List = ({
  loading,
  data,
  added,
  pagination,
  selected,
  ownerId,
  render,
  renderUser,
  handleSelectChange,
  handleSelectAll,
  handleRemove,
  setFilters,
}) => {
  if (!added && loading) {
    return <Loader />;
  }

  const preparedRender = renderUser({
    ownerId,
    selected,
    handleSelectChange,
    setFilters,
  });

  if (!loading && added && data.length < 1) {
    return <div className={styles.noUsers}>There is no users...</div>;
  }

  return (
    <Fragment>
      <ul
        className={cn(styles.users, loading && styles.loading, data.length < 2 && styles.noBorder)}
      >
        {data.map(preparedRender)}
      </ul>

      {data.length > 1 && (
        <div className={styles.fixedLi}>
          <button onClick={handleSelectAll} className={styles.fixButton} />
        </div>
      )}

      <div className={styles.navigation}>
        <div className={styles.left}>
          {pagination && (
            <Pagination current={pagination.current} pages={pagination.pages} render={render} />
          )}
        </div>
        {selected.length > 0 && (
          <div className={styles.right}>
            <button onClick={handleRemove} className={styles.remove}>
              Remove from list ({selected.length})
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default List;
