import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { playerOpen } from '../../../models/App/App.actions';
import Open from './Open.component';

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  blink: state.Player.blink,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      playerOpen,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Open);
