import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindRoutines } from '../../../abstracts/Routine';
import { getDetailsForGrouped } from '../../../models/Groups/Groups.routines';
import * as FiltersActions from '../../../models/Filters/Filters.actions';
import GroupComponent from './Group.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        getDetails: getDetailsForGrouped.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        setFilters: FiltersActions.setFilters,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Groups.grouped.loading,
  data: state.Groups.grouped.data,
  id: state.Filters.group ? state.Filters.group.id : null,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupComponent);
