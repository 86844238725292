import { connect } from 'react-redux';
import DeckSettings from './DeckSettings.component';
import { bindActionCreators } from 'redux';
import { openModal, closeModal } from '../../../../models/Modals/Modals.actions';
import { bindRoutines } from '../../../../abstracts/Routine';
import * as DeckRoutines from '../../../../models/Deck/Deck.routines';

/*
 * Connection
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        openModal,
        closeModal,
      },
      dispatch
    ),
    ...bindRoutines(
      {
        saveGroups: DeckRoutines.saveGroups.trigger,
      },
      dispatch
    ),
  },
});

export default connect(
  undefined,
  mapDispatchToProps
)(DeckSettings);
