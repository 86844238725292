import React from 'react';
import { connect } from 'react-redux';
import FormClass from '../../../abstracts/Form/Class';
import Validator from '../../../abstracts/Form/Validator';
import { bindRoutines } from '../../../abstracts/Routine';
import * as Adding from '../../../models/Adding/Adding.routines';
import * as AddingActions from '../../../models/Adding/Adding.actions';
import Url from './Url.component';

/**
 * Url Container.
 */
class UrlContainer extends FormClass {
  static initialState() {
    return {
      fields: {
        link: {
          name: 'link',
          value: '',
          error: null,
          focus: false,
        },
        links: {
          name: 'links',
          value: '',
          error: null,
          focus: false,
        },
      },
      saveAsStored: true,
    };
  }

  static setFocus(ref) {
    if (ref) {
      ref.focus();
    }
  }

  componentDidMount() {
    this.props.reduxActions.reset();

    this.props.setCallback(() => {
      return !this.state.saveAsStored && this.state.fields.links.value.length > 0;
    });
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {
      link: [Validator.rules.required(), Validator.rules.url()],
    };
  }

  bindings() {
    this.change = this.change.bind(this);
    this.saveAsStored = this.saveAsStored.bind(this);
    this.trySaveBulk = this.trySaveBulk.bind(this);
  }

  change(event) {
    this.modifyField('link', {
      value: event.target.value.trim(),
      error: null,
    });
  }

  submitForm() {
    const {
      link: { value: linkValue },
    } = this.state.fields;

    const payload = {
      link: linkValue,
    };

    this.props.reduxActions.getSnap(payload);
  }

  saveAsStored(saveAsStored) {
    this.setState({
      saveAsStored,
    });
  }

  informAboutBulkError() {
    this.modifyField('links', {
      error: 'Missing at least one valid link',
    });
  }

  trySaveBulk(event) {
    event.preventDefault();

    const { value } = this.state.fields.links;

    if (value.length < 1) {
      return this.informAboutBulkError();
    }

    const lines = value.split('\n').map((line) => line.trim());

    const links = [];

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      if (line.length < 1) {
        continue;
      }

      const firstSpace = line.indexOf(' ');

      let link = '';
      let title = '';

      if (firstSpace >= 0) {
        link = line.substring(0, firstSpace);
        title = line.substring(firstSpace).trim();
      } else {
        // eslint-disable-next-line no-multi-assign
        link = title = line;
      }

      const error = Validator.rules.url()(link);

      if (error) {
        return this.modifyField('links', { error: `In line ${i + 1} is incorrect link` });
      }

      links.push({
        link,
        title,
      });
    }

    if (links.length < 1) {
      return this.informAboutBulkError();
    }

    this.modifyField('links', { error: null });

    const payload = {};

    links.forEach((link, index) => {
      payload[`links[${index}][link]`] = link.link;
      payload[`links[${index}][title]`] = link.title;
    });

    this.props.reduxActions.bulkAdding(payload);
  }

  render() {
    return (
      <Url
        fields={this.state.fields}
        handlers={this.handlers}
        handleChange={this.change}
        handleBulkChange={this.onChange}
        loading={this.props.loading}
        errors={this.props.errors}
        handleSetFocus={this.constructor.setFocus}
        handleChangeOption={this.saveAsStored}
        isSavingOneLink={this.state.saveAsStored}
        handleSaveBulk={this.trySaveBulk}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      getSnap: Adding.getSnap.trigger,
      bulkAdding: Adding.bulkAdding.trigger,
      reset: AddingActions.reset,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  errors: state.Adding.errors,
  loading: state.Adding.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UrlContainer);
