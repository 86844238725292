import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ModalsActions from '../../../../models/Modals/Modals.actions';
import ConfirmComponent from './Confirm.component';

/**
 * Confirm Container.
 */
const Confirm = ({ payload, reduxActions, sizes }) => {
  return (
    <ConfirmComponent
      title={payload.title}
      click={payload.click}
      sizes={sizes}
      handleCloseModal={(confirmed) => {
        if (confirmed) {
          if (payload.shouldCloseAllModals) {
            reduxActions.closeAllModals();
          } else {
            reduxActions.closeModal();
          }

          reduxActions.action();
        } else {
          reduxActions.closeModal();
        }
      }}
    />
  );
};

/*
 * Connection.
 */
const mapStateToProps = (state) => ({
  sizes: state.App.sizes,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const toBind = {
    closeModal: ModalsActions.closeModal,
    closeAllModals: ModalsActions.closeAllModals,
  };

  if (ownProps.payload.action) {
    toBind.action = () => ownProps.payload.action;
  }

  return {
    reduxActions: bindActionCreators(toBind, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirm);
