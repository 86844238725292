/* eslint-disable import/prefer-default-export */
import { createRoutine } from '../../abstracts/Routine';

/*
 * Routines.
 */
export const get = createRoutine('COMMENTS__GET');
export const add = createRoutine('COMMENTS__ADD');
export const loadMore = createRoutine('COMMENTS__LOAD_MORE');
export const remove = createRoutine('COMMENTS__REMOVE');
