import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindRoutines } from '../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import FormClass from '../../../abstracts/Form/Class';
import * as Adding from '../../../models/Adding/Adding.routines';
import { edit } from '../../../models/Links/Links.routines';
import { prepareToPost, prepareToShare, mapTypeToIs } from '../../../models/Adding/Adding.helpers';
import Added from '../Added';
import { closeAllModals, closeModal, closeAndOpen } from '../../../models/Modals/Modals.actions';
import mapToArray from '../../../globals/helpers/mapToArray';
import arraysEqual from '../../../globals/helpers/arraysEqual';
import { reset, setSnap } from '../../../models/Adding/Adding.actions';
import { LINK_ADD } from '../../../models/Modals/Modals.types';
import ModalFulFill from '../../share/FulFill';
import FulFill from './../Create';
import Edit from './../Edit';

/**
 * FulFill Container
 */
class FulFillContainer extends FormClass {
  static initialState(props) {
    const myProps = {
      ...props,
    };

    if (props.origin) {
      myProps.originSnap = props.origin;
    }

    return {
      fields: {
        title: {
          name: 'title',
          value: myProps.originSnap.title || '',
          error: null,
          focus: false,
        },
        description: {
          name: 'description',
          value: myProps.originSnap.description || '',
          error: null,
          focus: false,
        },
        groups: {
          name: 'groups',
          value: null,
          error: null,
          focus: false,
        },
        users: {
          name: 'users',
          value: null,
          error: null,
          focus: false,
        },
      },

      originSnap: myProps.originSnap,

      is: mapTypeToIs(myProps),

      shouldLoadTags: true,

      remove: false,

      // TODO: fix it
      editMode: false,
      imageUrl: myProps.originSnap.image ? myProps.originSnap.image.url : null,
    };
  }

  bindings() {
    this.setIs = this.setIs.bind(this);

    this.changeEditMode = this.changeEditMode.bind(this);
    this.changeImage = this.changeImage.bind(this);

    this.closeModalAndReset = this.closeModalAndReset.bind(this);

    this.revert = this.revert.bind(this);

    this.markTagsAsLoaded = this.markTagsAsLoaded.bind(this);

    this.changeData = this.changeData.bind(this);

    this.sendFix = this.sendFix.bind(this);
    this.share = this.share.bind(this);
    this.edit = this.edit.bind(this);

    this.switchToFulFill = this.switchToFulFill.bind(this);
    this.changeRemove = this.changeRemove.bind(this);

    this.dirtyForEdit = this.dirtyForEdit.bind(this);
  }

  changeData(key, data) {
    this[key] = data;
  }

  setIs(is) {
    this.setState({
      is,
    });
  }

  setVars(props) {
    this.tags = null;
    this.groups = null;
    this.emails = null;

    this.tagsIterator = 1;

    if (props.asEdit && props.handleDirty) {
      props.handleDirty(this.dirtyForEdit);
    }

    if (props.origin && props.origin.tags) {
      this.tags = props.origin.tags;
    }

    this.initialIs = this.state.is;
  }

  dirtyForEdit() {
    return (
      this.isDirty() ||
      !arraysEqual(this.tags || [], this.props.origin.tags || []) ||
      this.initialIs !== this.state.is
    );
  }

  markTagsAsLoaded() {
    this.setState({
      shouldLoadTags: false,
    });
  }

  changeEditMode() {
    this.setState({
      editMode: !this.state.editMode,
    });
  }

  changeImage() {
    if (this.refImage) {
      this.setState({
        imageUrl: this.refImage.value,
      });
    }
  }

  closeModalAndReset() {
    this.props.reduxActions.closeAllModals();
    this.props.reduxActions.reset();
  }

  switchToFulFill() {
    this.props.reduxActions.setSnap(this.state.originSnap);

    this.props.reduxActions.closeAndOpen({
      type: LINK_ADD,
    });
  }

  revert(type) {
    switch (type) {
      case 'title':
        this.modifyField('title', { value: this.state.originSnap.title || '' });
        break;

      case 'description':
        this.modifyField('description', { value: this.state.originSnap.description || '' });
        break;

      case 'image':
        this.setState({
          imageUrl: this.state.originSnap.image ? this.state.originSnap.image.url : null,
          editMode: false,
        });
        break;

      case 'tags':
        if (this.props.origin) {
          this.tags = this.props.origin.tags;
          this.tagsIterator++;

          this.forceUpdate();
        }

        break;

      default:
        break;
    }
  }

  changeRemove() {
    this.setState({
      remove: !this.state.remove,
    });
  }

  sendFix() {
    const save = {
      from: 'snap',
      hash: this.state.originSnap.hash,
      ...prepareToPost({
        is: this.state.is,
        groups: this.groups,
        emails: this.emails,
        tags: this.tags,
      }),
    };

    if (this.state.fields.title.value.length > 0) {
      save.title = this.state.fields.title.value;
    }

    if (this.state.fields.description.value.length > 0) {
      save.description = this.state.fields.description.value;
    }

    if (this.state.imageUrl && this.state.imageUrl.length > 0) {
      save.image = this.state.imageUrl;
    }

    this.props.reduxActions.addLink(save);
  }

  share() {
    const save = prepareToShare(this.state.originSnap, {
      type: this.props.type,
      groups: this.groups,
      emails: this.emails,
      tags: this.tags,
      is: this.state.is,
      title: this.state.fields.title.value,
    });

    this.props.reduxActions.addLink(save);
  }

  edit() {
    const save = {};

    const title = this.state.fields.title.value;
    const description = this.state.fields.description.value;
    const image = this.state.imageUrl;

    if (title !== this.state.originSnap.title && title.length > 0) {
      save.title = title;
    }

    if (description !== this.state.originSnap.description && description.length > 0) {
      save.description = description;
    }

    if (image && image.length > 0) {
      if (this.state.originSnap.image) {
        if (this.state.originSnap.image.url !== image) {
          save.image = image;
        }
      } else {
        save.image = image;
      }
    } else if (this.state.originSnap.image) {
      save.without_image = 1;
    }

    if (this.state.originSnap.is === 'LINK') {
      let isPrivate;
      const current = !!this.state.originSnap.private;
      isPrivate = current;

      if (this.state.is === 'private') {
        isPrivate = 1;
      }

      if (this.state.is === 'public') {
        isPrivate = 0;
      }

      if (current !== isPrivate) {
        save.private = isPrivate;
      }
    }

    const toSave = {
      ...save,
      ...mapToArray('tags', this.tags),
    };

    this.props.reduxActions.editLink({
      id: this.state.originSnap.id,
      payload: toSave,
    });
  }

  render() {
    let Compo = null;

    if (this.props.asModal) {
      Compo = ModalFulFill;
    } else if (this.props.asEdit) {
      Compo = Edit;
    } else {
      Compo = FulFill;
    }

    return (
      <Fragment>
        <Compo
          loading={this.props.loading}
          errors={this.props.errors}
          originSnap={this.state.originSnap}
          handlers={this.handlers}
          fields={this.state.fields}
          is={this.state.is}
          handleSetIs={this.setIs}
          editMode={this.state.editMode}
          handleChangeEditMode={this.changeEditMode}
          handleChangeImage={this.changeImage}
          imageUrl={this.state.imageUrl}
          send={this.sendFix}
          share={this.share}
          added={this.props.added}
          handleCloseModalAndReset={this.closeModalAndReset}
          handleCloseModal={this.props.reduxActions.closeModal}
          handleRevert={this.revert}
          handleSwitchToFulFill={this.switchToFulFill}
          handleChangeRemove={this.changeRemove}
          handleEdit={this.edit}
          shouldLoadTags={this.state.shouldLoadTags}
          markTagsAsLoaded={this.markTagsAsLoaded}
          remove={this.state.remove}
          handleChangeData={this.changeData}
          emails={this.emails}
          tags={this.tags}
          tagsIterator={this.tagsIterator}
          groups={this.groups}
          type={this.props.type}
          mode={!!this.props.asEdit}
        />
        {this.props.added === true && <Added state={this.state} type={this.props.type} />}
      </Fragment>
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        addLink: Adding.addLink.trigger,
        editLink: edit.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        closeAllModals,
        closeModal,
        closeAndOpen,
        setSnap,
        reset,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  errors: state.Adding.errors,
  loading: state.Adding.loading,
  originSnap: state.Adding.snap,
  added: state.Adding.added,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulFillContainer);
