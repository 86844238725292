import map from './map';

/**
 * Get sections.
 *
 * @param children
 * @param components
 *
 * @returns {{}}
 */
const get = (children, ...components) => {
  return map(
    (obj) => {
      return obj.props.children;
    },
    'Section',
    children,
    components
  );
};

export default get;
