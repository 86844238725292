/* eslint-disable import/prefer-default-export */
import { createRoutine } from '../../abstracts/Routine';

/*
 * Routines.
 */
export const getGroups = createRoutine('GROUPS__GET_GROUPS');
export const getAllGroups = createRoutine('GROUPS__GET_ALL_GROUPS');
export const addGroup = createRoutine('GROUPS__ADD_GROUP');
export const editGroup = createRoutine('GROUPS__EDIT_GROUP');

export const addMembers = createRoutine('GROUPS__ADD_MEMBERS');
export const getMembers = createRoutine('GROUPS__GET_MEMBERS');
export const removeMembers = createRoutine('GROUPS__REMOVE_MEMBERS');
export const getDetails = createRoutine('GROUPS__GET_DETAILS');
export const getDetailsForGrouped = createRoutine('GROUPS__GET_DETAILS_FOR_GROUPED');

export const leave = createRoutine('GROUPS__LEAVE');
export const deleteGroup = createRoutine('GROUPS__DELETE_GROUP');
