import React from 'react';
import cn from 'classnames';
import Item from './components/Item';
import styles from './Added.module.scss';

const TEXTS = {
  private: '',
  public: '',
  friend: ' - shared with:',
  group: ' - saved in:',
};

/**
 * Added Component.
 */
const Added = ({ data, close, title, type }) => {
  return (
    <div className={cn(styles.added, 'S')}>
      <div className={styles.wrapper}>
        <div className={styles.name}>
          {title}
          {type && TEXTS[type]}
        </div>
        <div className={styles.list}>
          <ul>
            {data.map((item, index) => (
              <Item
                key={`${item.name}-${index}`}
                name={item.name}
                status={item.status}
                type={type}
              />
            ))}
          </ul>
        </div>
        <div className={styles.close}>
          <button className={styles.button} onClick={close}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Added;
