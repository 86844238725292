import React from 'react';
import { connect } from 'react-redux';
import FormClass from '../../../abstracts/Form/Class';
import Validator from '../../../abstracts/Form/Validator';
import { bindRoutines } from '../../../abstracts/Routine';
import * as Account from '../../../models/Account/Account.routines';
import FieldsComponent from './Fields.component';

/**
 * Fields Container.
 */
class Fields extends FormClass {
  static initialState() {
    return {
      fields: {
        fullName: {
          name: 'fullName',
          value: '',
          error: null,
          focus: false,
        },
        password: {
          name: 'password',
          value: '',
          error: null,
          focus: false,
        },
        rePassword: {
          name: 'rePassword',
          value: '',
          error: null,
          focus: false,
        },
      },
    };
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {
      fullName: [Validator.rules.required(), Validator.rules.length(6, 30)],
      password: [Validator.rules.required(), Validator.rules.length(6)],
      rePassword: [Validator.rules.required(), Validator.rules.theSame('password', this.getState)],
    };
  }

  submitForm() {
    const {
      fullName: { value: fullNameValue },
      password: { value: passwordValue },
      rePassword: { value: rePasswordValue },
    } = this.state.fields;

    const payload = {
      fullName: fullNameValue,
      password: passwordValue,
      rePassword: rePasswordValue,
      email: this.props.email,
    };

    this.props.reduxActions.register(payload);
  }

  render() {
    return (
      <FieldsComponent
        fields={this.state.fields}
        handlers={this.handlers}
        loading={this.props.loading}
        errors={this.props.errors}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      register: Account.register.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  errors: state.Account.register.errors,
  email: state.Account.register.email,
  loading: state.Account.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fields);
