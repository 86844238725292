import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommentsActions from '../../../models/Comments/Comments.actions';
import Extras from './Extras.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        set: CommentsActions.set,
        unset: CommentsActions.unset,
      },
      dispatch
    ),
    dispatch,
  },
});

const mapStateToProps = (state) => ({
  enabled: state.Comments.enabled,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Extras);
