import React, { Fragment, useEffect, useState } from 'react';
import styles from './DeckSettings.module.scss';
import { connect } from 'react-redux';

const Group = ({ data, selectedState }) => {
  const handleChange = () => {
    const [selected, setSelected] = selectedState;

    setSelected({
      ...selected,
      [data.id]: !selected[data.id],
    });
  };

  const isSelected = selectedState[0][data.id];

  return (
    <Fragment>
      <div className={styles.about}>
        <div className={styles.name}>{data.name}</div>
        <div>
          Users: {data.user_counter} &bull; Links: {data.link_counter}
        </div>
      </div>
      <div>
        <label className={styles.switch}>
          <input type="checkbox" defaultChecked={isSelected} onClick={handleChange} />
          <span className={styles.slider} />
        </label>
      </div>
    </Fragment>
  );
};

const Settings = ({ groups, setChanges }) => {
  const [prev, setPrev] = useState({});
  const selectedState = useState({});
  const setSelected = selectedState[1];

  useEffect(() => {
    const _selected = {};

    groups.forEach((group) => {
      _selected[group.id] = !!group.selected;
    });

    setSelected(_selected);
    setPrev(_selected);
  }, [groups]);

  useEffect(() => {
    const isDirty = Object.keys(prev).reduce((acc, curr) => {
      return acc || prev[curr] !== selectedState[0][curr];
    }, false);

    const changes = [isDirty, selectedState[0]];

    setChanges(changes);
  }, [prev, selectedState[0], setChanges]);

  return (
    <div>
      <ul className={styles.list}>
        {groups.map((group) => (
          <li key={group.id}>
            <Group data={group} selectedState={selectedState} />
          </li>
        ))}
      </ul>
    </div>
  );
};

/*
 * Connection.
 */
const mapStateToProps = (state) => ({
  groups: state.Deck.data,
});

export default connect(mapStateToProps)(Settings);
