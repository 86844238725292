import React from 'react';
import cn from 'classnames';
import styles from './../../Added.module.scss';

const STATUSES = {
  private: {
    existed: 'Already exists',
    created: 'Saved in your store as private',
    ignored: 'Cannot save',
  },
  public: {
    existed: 'Already exists',
    created: 'Saved in your store as public',
    ignored: 'Cannot save',
  },
  friend: {
    existed: 'You have sent this link',
    created: 'Shared successfully',
    ignored: 'You cannot share',
  },
  group: {
    existed: 'Link is saved in group',
    created: 'Saved successfully',
    ignored: 'You cannot add',
  },
  invite: {
    existed: 'You have already invited this user',
    created: 'Invitation send',
    ignored: 'You cannot send invitation to this email',
  },
  follow: {
    existed: 'You have already followed this user',
    created: 'Now you follow this user',
    ignored: 'You cannot follow this user',
  },
  members: {
    existed: 'This user is already a member',
    created: 'Member added',
    ignored: 'You cannot add this user to this group',
  },
};

/**
 * Item Component.
 */
const Item = ({ name, status, type }) => (
  <li>
    <div
      className={cn(styles.item, {
        [styles.existed]: status === 'existed',
        [styles.created]: status === 'created',
        [styles.ignored]: status === 'ignored',
      })}
    >
      <h4>{name}</h4>
      <span className={styles.status}>{STATUSES[type][status]}</span>
    </div>
  </li>
);

export default Item;
