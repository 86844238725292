import React, { Component } from 'react';
import randomWidth from '../../../globals/helpers/randomWidth';
import cx from 'classnames';
import styles from './Loader.module.scss';

/**
 * Loader Row Component.
 */
const LoaderRow = () => {
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.left}>
        <div style={randomWidth(80, 100)} />
        <div style={randomWidth(60, 80)} />
        <div style={randomWidth(20, 50)} />
      </div>
      <div className={styles.right} />
    </div>
  );
};

/**
 * Loader Component.
 */
class Loader extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { one } = this.props;

    return (
      <div className={cx(styles.wrapper, !one && styles.withTopMargin)}>
        <LoaderRow />
        {!one && <LoaderRow />}
      </div>
    );
  }
}

export default Loader;
