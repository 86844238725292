import React from 'react';
import { connect } from 'react-redux';
import FormClass from '../../../abstracts/Form/Class';
import Validator from '../../../abstracts/Form/Validator';
import { bindRoutines } from '../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import * as Account from '../../../models/Account/Account.routines';
import { reset } from '../../../models/Account/Account.actions';
import ResetComponent from './Reset.component';
import ResetEmailSentComponent from './../ResetEmailSent/ResetEmailSent.component';

/**
 * Reset Container.
 */
class Reset extends FormClass {
  static initialState() {
    return {
      fields: {
        email: {
          name: 'email',
          value: '',
          error: null,
          focus: false,
        },
      },
    };
  }

  componentWillUnmount() {
    this.props.reduxActions.reset();
  }

  /* eslint-disable class-methods-use-this */
  setValidators() {
    return {
      email: [Validator.rules.required(), Validator.rules.email()],
    };
  }

  submitForm() {
    const {
      email: { value: emailValue },
    } = this.state.fields;

    const payload = {
      email: emailValue,
    };

    this.props.reduxActions.resetPassword(payload);
  }

  render() {
    return this.props.success ? (
      <ResetEmailSentComponent />
    ) : (
      <ResetComponent
        fields={this.state.fields}
        handlers={this.handlers}
        loading={this.props.loading}
        errors={this.props.errors}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        resetPassword: Account.reset.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        reset,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  errors: state.Account.password.errors,
  success: state.Account.password.success,
  loading: state.Account.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reset);
