const hydrate = (min, max) => {
  const arr = [];

  // eslint-disable-next-line no-mixed-operators, no-bitwise
  const j = (min + Math.random() * (max - min)) >> 0;

  for (let i = 0; i < j; i++) {
    arr.push(i);
  }

  return arr;
};

export default hydrate;
