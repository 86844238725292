import epic from '../../abstracts/Epic';
import * as DarkList from '../../models/DarkList/DarkList.routines';
import { prepareLinkWithFilters } from '../Links/Links.helpers';
import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { concat, of, race } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import mapToArray from '../../globals/helpers/mapToArray';
import config from '../../config/config';
import getHeaders from '../../globals/helpers/headers';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';

export const getBanned = epic(
  DarkList.getBanned,
  (action) => prepareLinkWithFilters('darklist', [], action.payload),
  false
);

export const removeFromList = (action$, state$) => {
  const routine = DarkList.removeFromList;
  const loading = !state$.value.DarkList.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax
            .post(
              `${config.apiHost}darklist/remove`,
              { ...mapToArray('ids', action.payload.ids) },
              getHeaders()
            )
            .pipe(
              mergeMap(whenSuccess(routine)),
              catchError(whenFailure(routine))
            ),
          whenTimeout(routine)
        )
      );
    })
  );
};
