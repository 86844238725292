import React from 'react';
import ViewComponent, { Center as CenterSection, Menu, Filters } from '../../dashboard/View';
import GroupsMenu from '../../group/Menu';
import FiltersPhrase from '../../filters/Phrase';
import GroupList from '../../group/Groups';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

/**
 * Groups Component.
 */
const Groups = ({ location }) => {
  let showFilters = true;

  if (location && location.search) {
    const params = qs.parse(location.search.substr(1));

    if (params.hasOwnProperty('groupId')) {
      showFilters = false;
    }
  }

  return (
    <ViewComponent page="groups" simple name={showFilters ? 'Groups' : 'choosen Group'}>
      <Menu>
        <GroupsMenu />
      </Menu>
      <Filters>{showFilters && <FiltersPhrase />}</Filters>
      <CenterSection>
        <GroupList />
      </CenterSection>
    </ViewComponent>
  );
};

export default withRouter(Groups);
