import React, { useState, useCallback, useRef, useEffect, Fragment } from 'react';
import styles from './Group.module.scss';
import Link from '../../links/Link/Link.component';
import cn from 'classnames';
import { ajax } from 'rxjs/ajax';
import config from '../../../config/config';
import getHeaders from '../../../globals/helpers/headers';
import When from '../../links/When';
import { Link as RouterLink } from 'react-router-dom';
import End from '../../links/End';
import More from '../../links/More/More.component';
import Settings from '../../../config/settings';

const Group = ({ data, preview, setSelectedItem }) => {
  const [showNameBar, setShowNameBar] = useState();
  const [links, setLinks] = useState([]);
  const [asked, setAsked] = useState(false);
  const howManyLoads = useRef(0);

  const divRef = useRef(null);

  const handler = useCallback(() => {
    if (divRef && divRef.current) {
      const top = divRef.current.scrollTop;

      if (top > 180 && !showNameBar) {
        setShowNameBar(true);
      }

      if (top < 180 && showNameBar) {
        setShowNameBar(false);
      }
    }
  }, [showNameBar, setShowNameBar]);

  useEffect(() => {
    const obs = ajax({
      url: `${config.apiHost}show/group/${data.id}`,
      method: 'GET',
      headers: getHeaders(),
    });

    obs.subscribe(
      (res) => {
        if (res.status === 200) {
          howManyLoads.current++;
          setLinks(res.response);
          setAsked(true);
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }, []);

  const loadMoreItems = useCallback(() => {
    const id = links.length > 0 ? links[links.length - 1].id : 0;

    const obs = ajax({
      url: `${config.apiHost}show/group/${data.id}?from=${id}`,
      method: 'GET',
      headers: getHeaders(),
    });

    obs.subscribe(
      (res) => {
        if (res.status === 200) {
          howManyLoads.current++;
          setLinks([...links, ...res.response]);
          setAsked(true);
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }, [links]);

  const showMore =
    links.length > 0 && links.length >= howManyLoads.current * Settings.links.perPage;

  return (
    <div className={styles.wrapper}>
      <div ref={divRef} className={cn(styles.column, 'T')} onScroll={handler}>
        {showNameBar && (
          <div className={styles.nameBar}>
            <RouterLink to={`/dashboard/grouped/${data.id}`}>{data.name}</RouterLink>
          </div>
        )}

        <div className={styles.group}>
          <div className={styles.info}>
            <div className={styles.name}>
              <RouterLink to={`/dashboard/grouped/${data.id}`}>{data.name}</RouterLink>
            </div>
            <div className={styles.number}>{data.link_counter} links</div>
          </div>

          <div className={styles.users}>
            {data.users.length > 0 && (
              <div className={styles.list}>
                {data.users.map((user) => (
                  <div key={user.email_hash} className={styles.user}>
                    <img
                      src={`https://www.gravatar.com/avatar/${user.email_hash}?d=identicon`}
                      alt={user.full_name}
                    />
                    <div className={styles.userName}>{user.full_name}</div>
                  </div>
                ))}

                <span className={styles.more}>{data.user_counter}</span>
              </div>
            )}
          </div>
        </div>

        {asked && links.length < 1 && <div className={styles.noLinks}>No links found</div>}

        <ul className={styles.links}>
          {links.map((link, index) => {
            return (
              <Fragment key={link.id}>
                <li>
                  <When
                    now={link.created_at}
                    last={index > 0 ? links[index - 1].created_at : null}
                    index={index}
                  />
                  <button
                    className={styles.linkWrapper}
                    onClick={() => {
                      setSelectedItem(link);
                    }}
                  >
                    <Link key={link.id} data={link} reduxActions={{ preview }} />
                  </button>
                </li>
              </Fragment>
            );
          })}
        </ul>

        {showMore && <More handleClick={loadMoreItems} />}

        {!showMore && links.length > 20 && <End />}
      </div>
    </div>
  );
};

export default Group;
