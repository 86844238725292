import { getSnap, addLink, getDetails, bulkAdding } from './Adding.routines';
import { RESET, SET_SNAP } from './Adding.constants';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  window: 'start',
  snap: null,
  errors: null,
  added: null,
  response: null,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSnap.TRIGGER:
      return {
        ...initialState,
        loading: state.loading,
      };

    case RESET:
      return {
        ...initialState,
      };

    /*
     * LOADING START
     */
    case getSnap.REQUEST:
    case addLink.REQUEST:
    case getDetails.REQUEST:
    case bulkAdding.REQUEST:
      return {
        ...state,
        loading: true,
      };

    /*
     * LOADING STOP
     */
    case getSnap.FULFILL:
    case addLink.FULFILL:
    case getDetails.FULFILL:
    case bulkAdding.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * GET SNAP
     */
    case getSnap.SUCCESS:
    case SET_SNAP:
      return {
        ...state,
        snap: action.payload,
        window: 'fulfill',
      };

    /*
     * ERRORS
     */
    case getSnap.FAILURE:
    case addLink.FAILURE:
    case bulkAdding.FAILURE:
      if (action.payload.response && !action.payload.response.alert) {
        return {
          ...state,
          errors: { ...action.payload.response.errors },
        };
      }

      return state;

    /*
     * ADD LINK
     */
    case addLink.SUCCESS:
      return {
        ...state,
        added: true,
        response: action.payload,
        errors: null,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
