/* eslint-disable import/prefer-default-export */
import * as COMMENTS from './Comments.constants';

export const set = (enabled, snap = null, group = null) => ({
  type: COMMENTS.SET,
  payload: {
    enabled,
    snap,
    group,
  },
});

export const unset = () => ({
  type: COMMENTS.UNSET,
});
