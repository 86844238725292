import React from 'react';

const RevertButton = ({ name, action }) => (
  <li>
    <button
      onClick={() => {
        action(name.toLowerCase());
      }}
    >
      {name}
    </button>
  </li>
);

export default RevertButton;
