import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Notes.module.scss';

export const Notes = () => {
  return (
    <div className={styles.notes}>
      <Link className={styles.upgrade} to="/upgrade">
        Upgrade your plan
      </Link>
    </div>
  );
};

export default Notes;
