import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { remove, play } from '../../../models/Player/Player.actions';
import Song from './Song.component';

/*
 * Connection
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      remove,
      play,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(Song);
