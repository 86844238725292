const Settings = {
  api: {
    timeout: 10000,
    fetchTimeout: 60000,
  },
  auth: {
    tokenName: 'Authorization',
    tokenPrefix: 'Bearer ',
    tokenPostfix: '',
    loginRoute: '/',
    mainRoute: '/dashboard',
  },
  progressBar: {
    loading: 3000,
    finishing: 1000,
  },
  alerts: {
    duration: 10000,
  },
  template: {
    detailsWidth: 580,
    detailsImageMaxHeight: 500,
  },
  links: {
    perPage: 20,
  },
  howMany: {
    emails: 5,
    tags: 10,
    groups: 5,
    waitings: 10,
  },
  comments: {
    perPage: 10,
  },
};

export default Settings;
