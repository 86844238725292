import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import View, { Body, Menu } from '../../../modules/account/View';
import viewStyles from '../../../modules/account/View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * Created Component.
 */
const Created = () => {
  return (
    <View>
      <Menu>
        <li>
          <Link to="/">Log in</Link>
        </li>
      </Menu>
      <Body>
        <div className={cn(viewStyles.form, styles.createdBox)}>
          <h1>One step and voilà</h1>

          <div className={styles.description}>
            Confirm email and use <span>AS</span>. Thanks for choose us.
          </div>

          <Link className={viewStyles.button} to="/">
            Sign in
          </Link>
        </div>
      </Body>
    </View>
  );
};

export default Created;
