import React, { Component } from 'react';
import settings from '../../../config/settings';
import cn from 'classnames';
import Validator from '../../../abstracts/Form/Validator';
import styles from '../../adding/styles/Common.module.scss';
import { download } from './Friends.helper';

/**
 * Friends Component.
 */
class Friends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: download(),
      usedEmails: props.initialData || [],
      email: '',
    };

    this.limit = props.limit !== undefined ? parseInt(props.limit, 10) : settings.howMany.emails;

    this.change = this.change.bind(this);
    this.tryToAdd = this.tryToAdd.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.pressEnter = this.pressEnter.bind(this);
  }

  componentDidMount() {
    if (this.input) {
      this.input.focus();
    }
  }

  change(event) {
    this.setState({
      email: event.target.value,
    });
  }

  tryToAdd() {
    if (this.input) {
      const email = this.input.value.trim();

      const isEmail = Validator.rules.email();

      if (isEmail(email) === null) {
        this.add(email);
      }
    }
  }

  add(email) {
    const normalizedEmail = email.toLowerCase();

    const index = this.state.usedEmails.findIndex(
      (_email) => _email.toLowerCase() === normalizedEmail
    );

    if (index >= 0) {
      return;
    }

    // full
    if (this.state.usedEmails.length >= this.limit) {
      if (this.props.withReplacing) {
        const newEmails = this.state.usedEmails.slice(0, this.state.usedEmails.length - 1);

        this.setState(
          {
            usedEmails: [...newEmails, email],
            email: '',
          },
          () => {
            this.propagateChanges();
          }
        );
      }

      return;
    }

    this.setState(
      {
        usedEmails: [...this.state.usedEmails, email],
        email: '',
      },
      () => {
        this.propagateChanges();
      }
    );
  }

  remove(email) {
    const normalizedEmail = email.toLowerCase();

    const usedEmails = this.state.usedEmails.filter(
      (_email) => _email.toLowerCase() !== normalizedEmail
    );

    this.setState(
      {
        usedEmails,
      },
      () => {
        this.propagateChanges();
      }
    );
  }

  pressEnter(event) {
    if (event.key === 'Enter') {
      this.tryToAdd();
    }
  }

  prepareFilterFunction() {
    if (this.state.email.length < 1) {
      return () => true;
    }

    const re = new RegExp(`^${this.state.email}`, 'i');

    return (email) => email.match(re);
  }

  propagateChanges() {
    this.props.change('emails', this.state.usedEmails);
  }

  render() {
    return (
      <div className={styles.friends}>
        <div className={styles.search}>
          <div className={styles.typeEmail}>
            <input
              type="text"
              placeholder="Email"
              value={this.state.email}
              onChange={this.change}
              onKeyPress={this.pressEnter}
              ref={(ref) => {
                this.input = ref;
              }}
            />
            <button onClick={this.tryToAdd} type="button">
              Add email
            </button>
          </div>
          <div className={cn(styles.emails, 'T')}>
            <ul>
              {this.state.emails.filter(this.prepareFilterFunction()).map((email) => (
                <li key={email}>
                  <button
                    onClick={() => {
                      this.add(email);
                    }}
                  >
                    {email}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.listInfo}>
            <div className={styles.selectedInfo}>
              You have chosen <strong>{this.state.usedEmails.length}</strong>
              {this.props.withoutLimit ? '' : `/${this.limit}`} friends.
            </div>
            {this.props.useChildAsError && this.props.children}
          </div>
          <ul className={styles.basicList}>
            {this.state.usedEmails.map((email) => (
              <li key={email}>
                <button
                  onClick={() => {
                    this.remove(email);
                  }}
                >
                  {email}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Friends;
