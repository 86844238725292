import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openModal } from '../../../models/Modals/Modals.actions';
import NoGroupComponent from './NoGroup.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      openModal,
    },
    dispatch
  ),
});

export default connect(
  null,
  mapDispatchToProps
)(NoGroupComponent);
