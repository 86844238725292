import React from 'react';

/**
 * Logout Component.
 */
const Logout = ({ handleClick, className }) => (
  <button className={className} onClick={handleClick}>
    Logout
  </button>
);

export default Logout;
