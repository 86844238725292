import { connect } from 'react-redux';
import { setFollowerEmail } from '../../../models/Follower/Follower.actions';
import { bindActionCreators } from 'redux';
import { resetFilters } from '../../../models/Filters/Filters.actions';
import AuthorComponent from './Author.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        setFollowerEmail,
        resetFilters,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Follower.loading,
  data: state.Follower.data,
  userId: state.User.data.id,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorComponent);
