/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import config from '../../config/config';
import { prepareLinkWithFilters } from '../Links/Links.helpers';
import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { concat, of, race } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import getHeaders from '../../globals/helpers/headers';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';
import { addAsSuccess } from '../Alert/Alert.actions';
import mapToArray from '../../globals/helpers/mapToArray';
import * as Groups from './Groups.routines';

export const getGroups = epic(
  Groups.getGroups,
  (action, state$) =>
    prepareLinkWithFilters('groups', state$.value.Filters.selected, action.payload),
  false,
  'Groups'
);

export const getAllGroups = epic(
  Groups.getAllGroups,
  `${config.apiHost}groups/all`,
  false,
  'Groups'
);

export const getDetails = epic(
  Groups.getDetails,
  (action) => `${config.apiHost}groups/${action.payload.id}`,
  false,
  'Groups'
);

export const editGroup = (action$, state$) => {
  const routine = Groups.editGroup;
  const loading = !state$.value.Groups.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax
            .post(
              `${config.apiHost}groups/${action.payload.id}`,
              { name: action.payload.name },
              getHeaders()
            )
            .pipe(
              mergeMap(
                whenSuccess(
                  routine,
                  null,
                  of(addAsSuccess(`You have changed group to: ${action.payload.name}.`))
                  // of(Groups.getGroups.trigger(action.payload.reload)),
                )
              ),
              catchError(whenFailure(routine))
            ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const addGroup = (action$, state$) => {
  const routine = Groups.addGroup;
  const loading = !state$.value.Groups.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax.post(`${config.apiHost}groups`, { name: action.payload.name }, getHeaders()).pipe(
            mergeMap(
              whenSuccess(
                routine,
                null,
                of(addAsSuccess(`You have added group: ${action.payload.name}.`)),
                of(Groups.getGroups.trigger(action.payload.reload))
              )
            ),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const addMembers = (action$, state$) => {
  const routine = Groups.addMembers;
  const loading = !state$.value.Groups.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax
            .post(
              `${config.apiHost}groups/${action.payload.group}/users`,
              { ...mapToArray('users', action.payload.emails) },
              getHeaders()
            )
            .pipe(
              mergeMap(
                whenSuccess(
                  routine,
                  null,
                  of(Groups.getGroups.trigger(action.payload.reload)),
                  of(Groups.getAllGroups.trigger())
                )
              ),
              catchError(whenFailure(routine))
            ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const getMembers = epic(
  Groups.getMembers,
  (action) => `${config.apiHost}groups/${action.payload.id}/users?page=${action.payload.page}`,
  false,
  'Groups'
);

export const removeMembers = (action$, state$) => {
  const routine = Groups.removeMembers;
  const loading = !state$.value.Groups.members.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}groups/${action.payload.id}/users/discard`,
            body: { ...mapToArray('ids', action.payload.ids) },
            method: 'POST',
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const deleteGroup = (action$, state$) => {
  const routine = Groups.deleteGroup;
  const loading = !state$.value.Groups.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}groups/${action.payload.id}`,
            headers: getHeaders(),
            method: 'DELETE',
          }).pipe(
            mergeMap(
              whenSuccess(
                routine,
                null,
                of(Groups.getGroups.trigger(action.payload.reload)),
                of(Groups.getAllGroups.trigger())
              )
            ),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const leave = (action$, state$) => {
  const routine = Groups.leave;
  const loading = !state$.value.Groups.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}groups/${action.payload.id}/leave`,
            headers: getHeaders(),
            method: 'POST',
          }).pipe(
            mergeMap(
              whenSuccess(
                routine,
                null,
                of(Groups.getGroups.trigger(action.payload.reload)),
                of(Groups.getAllGroups.trigger())
              )
            ),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const getGroupDetailsForGrouped = (action$, state$) => {
  const routine = Groups.getDetailsForGrouped;
  const loading = !state$.value.Groups.grouped.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax.get(`${config.apiHost}groups/${action.payload}`, getHeaders()).pipe(
            mergeMap(whenSuccess(routine)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};
