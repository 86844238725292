import { OPEN_MODAL, CLOSE_MODAL, CLOSE_ALL_MODALS, CLOSE_AND_OPEN } from './Modals.constants';

/*
 * Initial State.
 */
const initialState = {
  data: [],
};

/**
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case CLOSE_AND_OPEN:
      return {
        ...state,
        data: [action.payload],
      };

    case CLOSE_MODAL:
      return {
        ...state,
        // close last modal
        data: state.data.filter((_, i) => i < state.data.length - 1),
      };

    case CLOSE_ALL_MODALS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default Reducer;
