import { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resize } from '../../models/App/App.actions';
import { SMALL, BIG } from '../../models/App/App.constants';

/**
 * Resize Container.
 */
class Resizer extends Component {
  constructor(props) {
    super(props);

    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', debounce(this.resize, 200));

    this.resize();
  }

  resize() {
    const w =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const h =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const sizes = {
      width: w < 800 ? SMALL : BIG,
      height: h < 600 ? SMALL : BIG,
      realWidth: w,
      realHeight: h,
    };

    this.props.reduxActions.resize(sizes);
  }

  componentWillReceiveProps(props) {
    const { width, height } = props.sizes;
    const { width: thisWidth, height: thisHeight } = this.props.sizes;

    if (width !== thisWidth || height !== thisHeight) {
      if (width === null || height === null) {
        this.resize();
      }
    }
  }

  render() {
    return null;
  }
}

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  sizes: state.App.sizes,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      resize,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resizer);
