import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as LinksRoutines from '../../../models/Links/Links.routines';
import * as LinksActions from '../../../models/Links/Links.actions';
import * as FiltersActions from '../../../models/Filters/Filters.actions';
import { getSelectedEmail } from '../../../models/Filters/Filters.selectors';
import { bindRoutines } from '../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import settings from '../../../config/settings';
import LinksComponent from './Links.component';

/**
 * Links Container.
 */
class Links extends Component {
  constructor(props) {
    super(props);

    this.selectedRef = null;
    this.doScroll = false;

    this.state = {
      show: false,
      selected: [],
    };

    this.select = this.select.bind(this);
    this.setSelectedRef = this.setSelectedRef.bind(this);
    this.setScrollRef = this.setScrollRef.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
    this.clear = this.clear.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.props.reduxActions.resetFilters();
  }

  componentWillReceiveProps(props) {
    if (props.match !== this.props.match) {
      this.props.reduxActions.resetFilters();
    } else if (
      props.filters !== this.props.filters ||
      props.group !== this.props.group ||
      props.forcedRefreshTime !== this.props.forcedRefreshTime
    ) {
      this.load(props);

      const newState = {
        selected: [],
      };

      /*
       * When you mount this component as fresh instance you can have old Links data in reducer
       * and when you change from waiting room (few fields in missed) app can throws errors.
       * We use flag to solve this problem. When this component really will load data, component
       * change value to true.
       */
      if (!this.state.show) {
        newState.show = true;
      }

      this.setState(newState);
    }

    if (props.selectionIterator !== this.props.selectionIterator) {
      this.doScroll = true;
    }

    if (this.props.endpoint === 'waitings' && props.removeStamp !== this.props.removeStamp) {
      this.setState({
        selected: this.state.selected.filter(
          (id) => props.links.findIndex((link) => link.id === id) >= 0
        ),
      });

      if (props.showMore && props.links.length < 1) {
        this.load(props);
        this.resetSelected();
      }
    }
  }

  setScrollRef(ref) {
    if (ref) {
      this.scrollRef = ref;
    }
  }

  setSelectedRef(ref) {
    if (ref && this.scrollRef) {
      if (ref !== this.selectedRef || this.doScroll) {
        this.selectedRef = ref;

        this.scroll();
      }

      this.doScroll = false;
    }
  }

  refresh() {
    this.props.reduxActions.refresh(this.props.endpoint);
  }

  resetSelected() {
    this.setState({
      selected: [],
    });
  }

  scroll() {
    const visiblePosition = this.selectedRef.offsetTop - this.scrollRef.scrollTop;
    const height = this.scrollRef.offsetHeight;

    if (visiblePosition < 0) {
      this.scrollRef.scrollTop = this.selectedRef.offsetTop - 50;
    }

    const delta = height - visiblePosition;

    if (delta < 0 || visiblePosition + this.selectedRef.offsetHeight > height) {
      this.scrollRef.scrollTop =
        this.scrollRef.scrollTop - delta + this.selectedRef.offsetHeight + 50;
    }
  }

  changeSelection(e, id) {
    const value = parseInt(id, 10);

    e.stopPropagation();

    if (e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
    }

    const { length } = this.state.selected;

    const newSelected = this.state.selected.filter((id) => id !== value);

    if (newSelected.length === length) {
      // add
      newSelected.push(value);
    }

    if (newSelected.length > settings.howMany.waitings) {
      return;
    }

    this.setState({
      selected: newSelected,
    });
  }

  clear() {
    this.setState({
      selected: [],
    });
  }

  load(props) {
    if (props.endpoint === 'grouped') {
      if (!props.group) {
        return;
      }
    }

    props.reduxActions.getLinks({
      endpoint: props.endpoint,
      loadMore: false,
      withSelection: true,
    });
  }

  select(id) {
    this.props.reduxActions.select(id);
  }

  render() {
    if (!this.state.show) {
      return null;
    }

    return (
      <LinksComponent
        added={this.props.added}
        displayOptions={this.props.displayOptions}
        endpoint={this.props.endpoint}
        errors={this.props.errors}
        filtersLength={this.props.filters.length}
        firstTime={this.props.firstTime}
        followerEmail={this.props.followerEmail}
        followerError={this.props.followerError}
        group={this.props.group}
        groupAdded={this.props.groupAdded}
        handleChangeSelection={this.changeSelection}
        handleClear={this.clear}
        handleMarkAsSeen={this.props.reduxActions.markAsSeen}
        handleSelect={this.select}
        handleSetScrollRef={this.setScrollRef}
        handleSetSelectedRef={this.setSelectedRef}
        links={this.props.links}
        loading={this.props.loading}
        refresh={this.refresh}
        selected={this.props.selected}
        selectedLinks={this.state.selected}
        showMore={this.props.showMore}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        getLinks: LinksRoutines.getLinks.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        resetFilters: FiltersActions.resetFilters,
        select: LinksActions.select,
        markAsSeen: LinksActions.markAsSeen,
        refresh: LinksActions.refresh,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Links.loading,
  links: state.Links.data,
  added: state.Links.added,
  selected: state.Links.selected,
  selectionIterator: state.Links.selectionIterator,
  showMore: state.Links.showMore,
  errors: state.Links.errors,
  removeStamp: state.Links.removeStamp,
  // To refresh
  filters: state.Filters.selected,
  followerError: state.Follower.error,
  group: state.Filters.group,
  groupAdded: state.Filters.groupAdded,
  forcedRefreshTime: state.Filters.forcedRefreshTime,
  firstTime: state.User.data.first_time !== 0,
  followerEmail: getSelectedEmail(state),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Links)
);
