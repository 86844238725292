import React from 'react';
import { Link } from 'react-router-dom';
import View, { Body, Menu } from '../../../modules/account/View';
import FieldError from '../../../modules/errors/Field';
import ApiError from '../../../modules/errors/Api';
import { formClassName } from '../../../abstracts/Form/Utils';
import cn from 'classnames';
import viewStyles from '../../../modules/account/View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * Login Component.
 */
const Login = ({ handlers, fields: { email, password }, errors, loading }) => {
  return (
    <View>
      <Menu>
        <li>
          <Link to="/account/register">Sign up</Link>
        </li>
      </Menu>
      <Body>
        <div className={viewStyles.form}>
          <h1>Log in</h1>

          <div className={styles.description}>
            If you find something what you want save, just do it!
          </div>

          <form onSubmit={handlers.submit}>
            <div className={viewStyles.form}>
              <div className={formClassName(email, errors, viewStyles)}>
                <input
                  type="text"
                  className={viewStyles.input}
                  placeholder="Email"
                  autoComplete="off"
                  name={email.name}
                  onChange={handlers.change}
                  value={email.value}
                />
                <FieldError
                  data={email}
                  apiData={errors}
                  names="Email"
                  className={viewStyles.error}
                />
              </div>

              <div className={formClassName(password, errors, viewStyles)}>
                <input
                  type="password"
                  className={viewStyles.input}
                  placeholder="Password"
                  autoComplete="off"
                  name={password.name}
                  onChange={handlers.change}
                  value={password.value}
                />
                <FieldError
                  data={password}
                  apiData={errors}
                  names="Password"
                  className={viewStyles.error}
                />
              </div>

              <div className={cn(viewStyles.row, viewStyles.lastRow)}>
                <button onClick={handlers.submit} className={viewStyles.button} disabled={loading}>
                  Sign in
                </button>
                <ApiError data={errors} className={viewStyles.error} />
              </div>
            </div>
          </form>

          <div className={styles.reset}>
            <Link to="/account/reset">Reset password</Link>
          </div>

          {/*
            <div className={styles.or}>Pick the way you want to log in into your account.</div>

            <div className={styles.other}>
              <div className={styles.facebook}>Connect with Facebook</div>
            </div>
          */}

          <div className={styles.singUp}>
            {"Don't"} have account? <Link to="/account/register">Sign up</Link> and have fun!
          </div>
        </div>
      </Body>
    </View>
  );
};

export default Login;
