import React, { Component } from 'react';
import bindRoutines from '../../../abstracts/Routine/src/bindRoutines';
import connect from 'react-redux/es/connect/connect';
import * as Groups from '../../../models/Groups/Groups.routines';
import settings from '../../../config/settings';
import GroupsComponent from './Groups.component';

/**
 * Groups Container.
 */
class GroupsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usedGroups: props.initialData || [],
    };

    this.changeGroup = this.changeGroup.bind(this);
    this.isGroupSelected = this.isGroupSelected.bind(this);
  }

  componentDidMount() {
    if (!this.props.added) {
      this.props.reduxActions.getAllGroups();
    }
  }

  changeGroup(id) {
    let newGroups;

    const index = this.state.usedGroups.findIndex((groupId) => {
      return groupId === id;
    });

    // found
    if (index >= 0) {
      newGroups = this.state.usedGroups.filter((groupId) => groupId !== id);
    } else {
      newGroups = [...this.state.usedGroups, id];
    }

    if (newGroups.length > settings.howMany.groups) {
      return;
    }

    this.setState(
      {
        usedGroups: newGroups,
      },
      () => {
        this.propagateChanges();
      }
    );
  }

  isGroupSelected(id) {
    return this.state.usedGroups.findIndex((groupId) => groupId === id) >= 0;
  }

  propagateChanges() {
    this.props.change('groups', this.state.usedGroups);
  }

  render() {
    return (
      <GroupsComponent
        loading={this.props.loading}
        groups={this.props.groups}
        usedGroups={this.state.usedGroups}
        added={this.props.added}
        handleChangeGroup={this.changeGroup}
        isGroupSelected={this.isGroupSelected}
      />
    );
  }
}

/*
 * Connection
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      getAllGroups: Groups.getAllGroups.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  // errors: state.Groups.errors,
  loading: state.Groups.loading,
  groups: state.Groups.all.data,
  added: state.Groups.all.added,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsContainer);
