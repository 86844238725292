import { login, checkEmail, register, confirm, reset, password } from './Account.routines';
import { RESET } from './Account.constants';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  login: {
    errors: null,
  },
  register: {
    email: null,
    window: 'email',
    errors: null,
  },
  password: {
    success: false,
    errors: null,
  },
  confirm: {
    window: 'start',
    errors: null,
  },
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  let preparedErrors = null;

  switch (action.type) {
    case login.TRIGGER:
    case checkEmail.TRIGGER:
    // case register.TRIGGER: // see below
    case confirm.TRIGGER:
    case reset.TRIGGER:
    case password.TRIGGER:
    case RESET:
      return {
        ...initialState,
        loading: state.loading,
      };

    /*
     * LOADING START
     */
    case login.REQUEST:
    // case checkEmail.REQUEST: // see below
    case register.REQUEST:
    case confirm.REQUEST:
    case reset.REQUEST:
    case password.REQUEST:
      return {
        ...state,
        loading: true,
      };

    /*
     * LOADING STOP
     */
    case login.FULFILL:
    case checkEmail.FULFILL:
    case register.FULFILL:
    case confirm.FULFILL:
    case reset.FULFILL:
    case password.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * LOGIN
     */
    case login.FAILURE:
      if (action.payload.response && !action.payload.response.alert) {
        return {
          ...state,
          login: {
            ...state.login,
            errors: { ...action.payload.response.errors },
          },
        };
      }

      return state;

    /*
     * CHECK EMAIL
     */
    case checkEmail.REQUEST:
      return {
        ...state,
        loading: true,
        register: {
          ...state.register,
          email: action.payload.email,
        },
      };

    case checkEmail.SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          window: 'fields',
        },
      };

    /*
     * REGISTER
     */
    case register.TRIGGER:
      return {
        ...state,
        register: {
          ...state.register,
          errors: null,
        },
      };

    case register.SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          window: 'created',
        },
      };

    /*
     * REGISTER, CHECK EMAIL FAILURES
     */
    case checkEmail.FAILURE:
    case register.FAILURE:
      if (action.payload.response && !action.payload.response.alert) {
        return {
          ...state,
          register: {
            ...state.register,
            errors: { ...action.payload.response.errors },
          },
        };
      }

      return state;

    /*
     * RESET, PASSWORD
     */
    case reset.SUCCESS:
    case password.SUCCESS:
      return {
        ...state,
        password: {
          ...state.password,
          success: true,
        },
      };

    case reset.FAILURE:
    case password.FAILURE:
      if (action.payload.response && !action.payload.response.alert) {
        return {
          ...state,
          password: {
            ...state.password,
            errors: { ...action.payload.response.errors },
          },
        };
      }

      return state;

    /*
     * CONFIRM
     */
    case confirm.SUCCESS:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          window: 'success',
        },
      };

    case confirm.FAILURE:
      if (action.payload.response && !action.payload.response.alert) {
        preparedErrors = { ...action.payload.response.errors };
      }

      return {
        ...state,
        confirm: {
          ...state.confirm,
          window: 'failure',
          errors: preparedErrors,
        },
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
