import { getLinks } from '../Links/Links.routines';
import { getThanks } from '../Thanks/Thanks.routines';
import { getStats } from './Statistics.routines';

/*
 * Initial State.
 */
const initialState = {
  'for-you': false,
  thanks: false,
  social: false,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     * GET STATS
     */
    case getStats.SUCCESS:
      return action.payload;

    case getLinks.SUCCESS:
      if (action.options.endpoint === 'social') {
        return {
          ...state,
          social: false,
        };
      } else if (action.options.endpoint === 'for_you') {
        return {
          ...state,
          'for-you': false,
        };
      }

      return state;

    case getThanks.SUCCESS:
      return {
        ...state,
        thanks: false,
      };

    default:
      return state;
  }
};

export default Reducer;
