import React from 'react';
import { connect } from 'react-redux';
import mapStateToProps from './../helpers/mapStateToProps';
import mapDispatchToProps from './../helpers/mapDispatchToProps';
import GroupedComponent from './Grouped.components';
import Main from './../Main';

/**
 * Grouped Container.
 */
class Grouped extends Main {
  render() {
    return (
      <GroupedComponent
        selectedTags={this.state.tags}
        phrase={this.state.phrase}
        newTag={this.state.newTag}
        where={this.props.where}
        handleAddNewTag={this.addNewTag}
        handleRemoveTag={this.removeTag}
        handleClose={this.props.handleClose}
        handleChange={this.onChange}
        handleClear={this.clear}
        handleSetFilter={this.setFilters}
        handleAddNewTagByEnter={this.addNewTagByEnter}
        handleSetFiltersByEnter={this.setFiltersByEnter}
        handleSetRef={this.setRef}
        handleChangeWhere={this.changeWhere}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Grouped);
