import React, { Fragment } from 'react';
import Button from '../../../shared/Button';

/**
 * Navigation Component.
 */
const Navigation = ({
  className,
  loading,
  showMore,
  disablePrevious,
  disableNext,
  handlePrevious,
  handleCurrent,
  handleNext,
  handleLoadMore,
}) => {
  return (
    <Fragment>
      <Button
        className={className}
        icon="skip_previous"
        disabled={disablePrevious}
        left
        onClick={handlePrevious}
      >
        Previous
      </Button>
      <Button
        className={className}
        onClick={handleCurrent}
        icon="filter_center_focus"
        onlyIcon
        small
      />
      {!showMore && (
        <Button
          className={className}
          onClick={handleNext}
          disabled={disableNext}
          icon="skip_next"
          right
        >
          Next
        </Button>
      )}
      {showMore && !loading && (
        <Button className={className} onClick={handleLoadMore}>
          Load more
        </Button>
      )}
    </Fragment>
  );
};

export default Navigation;
