import React from 'react';
import cn from 'classnames';
import noCheckInput from '../../../globals/helpers/noCheckInput';
import styles from '../styles/Common.module.scss';
import PolicyButton from '../PolicyButton';
import Tags from '../Tags';
import Image from '../../details/Image';
import RevertButton from '../RevertButton';

/**
 * Edit Component.
 */
const Edit = ({
  loading,
  // errors,

  originSnap,
  handlers,
  // eslint-disable-next-line no-unused-vars
  fields: { title, description, users, groups: groupsField },
  is,
  handleSetIs,

  editMode,
  handleChangeEditMode,
  handleChangeImage,
  imageUrl,
  // send,
  added,
  handleCloseModalAndReset,
  handleRevert,
  handleEdit,

  shouldLoadTags,
  markTagsAsLoaded,

  handleChangeData,
  // emails,
  tags,
  tagsIterator,
  // groups,
}) => {
  const imageStyles = {
    cover: null,
    small: null,
    image: null,
    imageError: styles.imageError,
  };

  return (
    <div className={styles.adding}>
      <div className={styles.link}>{originSnap.link}</div>

      <div className={styles.columns}>
        <div className={styles.imageWrapper}>
          {imageUrl && (
            <div className={styles.image}>
              <Image key={imageUrl} src={imageUrl} styles={imageStyles} />
              <button onClick={handleChangeEditMode} className={styles.changeImageMode} />
            </div>
          )}

          {(!imageUrl || (imageUrl && editMode)) && (
            <div className={styles.imageUrl}>
              <input
                type="text"
                className={styles.inputImage}
                defaultValue={imageUrl}
                {...noCheckInput}
                name="image"
                ref={handlers.ref}
                placeholder="Image URL"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleChangeImage();
                  }
                }}
              />
              <button onClick={handleChangeImage}>Change image</button>

              {1 === 0 && <div className={styles.incorrectImage}>This url is incorrect</div>}
            </div>
          )}
        </div>

        <div className={styles.infoWrapper}>
          <input
            type="text"
            value={title.value}
            className={styles.input}
            onChange={handlers.change}
            name={title.name}
            placeholder="Title"
          />
          <textarea
            className={styles.textarea}
            name={description.name}
            value={description.value}
            onChange={handlers.change}
            placeholder="Description..."
          />
        </div>
      </div>

      {originSnap.is === 'LINK' && (
        <div className={styles.type}>
          <ul>
            <li>
              <PolicyButton
                is={is}
                type="private"
                text="Save as private"
                handleSetIs={handleSetIs}
              />
            </li>
            <li>
              <PolicyButton is={is} type="public" text="Save as public" handleSetIs={handleSetIs} />
            </li>
          </ul>
        </div>
      )}

      <div className={styles.withMarginTopTags}>
        <Tags
          shouldLoadTags={shouldLoadTags}
          markTagsAsLoaded={markTagsAsLoaded}
          change={handleChangeData}
          initialData={tags}
          tagsIterator={tagsIterator}
          is={is}
        />
      </div>

      <div className={styles.addAgain}>
        <button
          onClick={handleEdit}
          disabled={added}
          className={cn(styles.save, loading && styles.loading)}
        >
          Edit link
        </button>

        <div className={styles.buttonWrapper}>
          <button className={styles.cancel} onClick={handleCloseModalAndReset}>
            Cancel without confirmation
          </button>

          <div className={styles.revert}>
            <button>Revert</button>

            <ul>
              <RevertButton name="Title" action={handleRevert} />
              <RevertButton name="Description" action={handleRevert} />
              <RevertButton name="Image" action={handleRevert} />
              <RevertButton name="Tags" action={handleRevert} />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
