import { getSpeedDial } from './SpeedDial.routines';

/*
 * Initial State.
 */
const initialState = {
  loading: false,
  added: false,
  data: [],
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     * LOADING
     */
    case getSpeedDial.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case getSpeedDial.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * GET TAGS
     */
    case getSpeedDial.SUCCESS:
      return {
        ...state,
        data: action.payload || [],
        added: true,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
