/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import { ofType } from 'redux-observable';
import { catchError, filter, mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { concat, EMPTY, of, race } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api';
import config from '../../config/config';
import getHeaders from '../../globals/helpers/headers';
import mapToArray from '../../globals/helpers/mapToArray';
import { addAsInfo, addAsSuccess } from '../Alert/Alert.actions';
import { closeAllModals } from '../Modals/Modals.actions';
import * as Links from './Links.routines';
import { editLink as editLinkAction, setPureLinks } from './Links.actions';
import { prepareLinkWithFilters } from './Links.helpers';
import * as LINKS from './Links.constants';

/**
 * Get links Epic.
 */
export const getLinks = epic(
  Links.getLinks,
  (action, state$) => {
    const payload = {};

    if (action.payload.loadMore) {
      const { data } = state$.value.Links;
      const from = data.length > 0 ? data[data.length - 1].id : null;

      if (from) {
        payload.from = from;
      }
    }

    if (state$.value.Filters.where === 'and' && state$.value.Filters.selected.length > 0) {
      payload.where = 'and';
    }

    let url = `show/${action.payload.endpoint}`;

    if (action.payload.endpoint === 'grouped') {
      url = `show/group/${state$.value.Filters.group.id || 0}`;
    }

    return prepareLinkWithFilters(url, state$.value.Filters.selected, payload);
  },
  false,
  'Links',
  false,
  false,
  true
);

export const refresh = (action$, state$) => {
  const loading = !state$.value.Links.loading;

  return action$.pipe(
    ofType(LINKS.REFRESH),
    filter(() => loading),
    map((action) => {
      return Links.getLinks.trigger({
        endpoint: action.payload.endpoint,
        withSelection: true,
        loadMore: false,
      });
    })
  );
};

export const removeLink = (action$, state$) => {
  const routine = Links.removeLink;
  const loading = !state$.value.Links.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}links/${action.payload.id}`,
            method: 'DELETE',
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const removeFromWaitings = (action$, state$) => {
  const routine = Links.removeFromWaitings;
  const loading = !state$.value.Links.removeLoading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}waitings/${action.payload.id}`,
            method: 'DELETE',
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const massRemoveFromWaitings = (action$, state$) => {
  const routine = Links.massRemoveFromWaitings;
  const loading = !state$.value.Links.removeLoading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax
            .post(
              `${config.apiHost}waitings/discard`,
              { ...mapToArray('ids', action.payload.ids) },
              getHeaders()
            )
            .pipe(
              mergeMap(whenSuccess(routine, action)),
              catchError(whenFailure(routine))
            ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const showRemoveAlert = (action$, state$) => {
  return action$.pipe(
    ofType(Links.removeFromWaitings.SUCCESS, Links.removeLink.SUCCESS),
    mergeMap((action) => {
      const counter = state$.value.Links.removeCounter;

      return of(
        addAsInfo(
          `You have removed (${counter}) link${counter > 1 ? 's' : ''}: ${action.options.link}.`
        )
      );
    })
  );
};

export const showMassRemoveAlert = (action$, state$) => {
  return action$.pipe(
    ofType(Links.massRemoveFromWaitings.SUCCESS),
    mergeMap(() => {
      const counter = state$.value.Links.removeCounter;

      return of(addAsInfo(`You have removed (${counter}) link${counter > 1 ? 's' : ''}.`));
    })
  );
};

export const addToSpeedDial = epic(
  Links.addToSpeedDial,
  `${config.apiHost}speeddial`,
  true,
  'Links',
  false,
  false,
  true
);

export const removeFromSpeedDial = (action$, state$) => {
  const routine = Links.removeFromSpeedDial;
  const loading = !state$.value.Links.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}speeddial/${action.payload.id}`,
            method: 'DELETE',
            headers: getHeaders(),
          }).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

/**
 * Change - do not send id as body @TODO.
 * Link.loading - incoreect loader
 */
export const changePrivacy = epic(
  Links.changePrivacy,
  (action) => `${config.apiHost}links/${action.payload.id}/privacy`,
  true,
  'Links',
  false,
  false,
  true
);

export const showAlertAfterFieldChange = (action$) => {
  return action$.pipe(
    ofType(
      Links.changePrivacy.SUCCESS,
      Links.addToSpeedDial.SUCCESS,
      Links.removeFromSpeedDial.SUCCESS,
      Links.sayThankYou.SUCCESS,
      Links.block.SUCCESS,
      Links.block.FAILURE
    ),
    mergeMap((action) => {
      switch (action.type) {
        case Links.changePrivacy.SUCCESS:
          return of(
            addAsInfo(`You have changed link to ${action.options.private ? 'private' : 'public'}.`)
          );

        case Links.addToSpeedDial.SUCCESS:
          return of(addAsInfo('You have added link to Speed Dial.'));

        case Links.removeFromSpeedDial.SUCCESS:
          return of(addAsInfo('You have removed link from Speed Dial.'));

        case Links.sayThankYou.SUCCESS:
          return of(addAsInfo(`You said thank you for ${action.options.user}. Nice :)`));

        case Links.block.SUCCESS:
          return of(addAsSuccess(`User added to darklist.`));

        case Links.block.FAILURE:
          return of(addAsInfo(`You have already blocked this user.`));

        default:
          /*
           * Fix CS
           */
          return EMPTY;
      }
    })
  );
};

export const editLink = (action$, state$) => {
  const routine = Links.edit;
  const loading = !state$.value.Links.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}links/${action.payload.id}`,
            method: 'POST',
            headers: getHeaders(),
            body: action.payload.payload,
          }).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

const mapSelectedToObject = (selected) => {
  let phrase = null;
  const tags = [];
  const obj = {};

  // eslint-disable-next-line no-shadow
  selected.forEach((filter) => {
    if (filter.type === 'tag') {
      tags.push(filter.value);
    } else if (filter.type === 'phrase') {
      phrase = filter.value;
    }
  });

  if (phrase !== null) {
    obj.phrase = phrase;
  }

  if (tags.length > 0) {
    obj.tags = tags;
  }

  if (Object.keys(obj).length > 0) {
    return obj;
  }

  return null;
};

export const editLinkSuccess = (action$, state$) => {
  const routine = Links.edit;

  return action$.pipe(
    ofType(routine.SUCCESS),
    mergeMap((action) => {
      const { payload: edited } = action;
      const filters = mapSelectedToObject(state$.value.Filters.selected);
      const logicType = state$.value.Filters.where;
      let markAsEdited = false;

      if (filters) {
        if (filters.phrase) {
          if (!edited.title.search(filter.phrase)) {
            markAsEdited = false;
          }
        }

        if (filters.tags) {
          if (logicType === 'or') {
            // use lodash here
          } else {
            // and
          }
        }
      }

      if (!markAsEdited) {
        edited.hidden = true;
      }

      return concat(
        of(editLinkAction(edited)),
        of(closeAllModals()),
        of(addAsInfo(`You have edited "${`${edited.title.substr(0, 40)}...`}".`))
      );
    })
  );
};

export const sayThankYou = epic(
  Links.sayThankYou,
  (action) => `${config.apiHost}thanks/${action.payload.id}`,
  true,
  'Links',
  false,
  false,
  true
);

export const blockUser = (action$, state$) => {
  const routine = Links.block;
  const loading = !state$.value.Links.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax({
            url: `${config.apiHost}darklist`,
            method: 'POST',
            headers: getHeaders(),
            body: action.payload,
          }).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const removeUserLinks = (action$, state$) => {
  const routine = Links.block;

  return action$.pipe(
    ofType(routine.SUCCESS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      if (action.options.remove) {
        const links = state.Links.data;

        const newLinks = links.filter((link) => link.from_user.id !== action.options.id);

        const actions = [setPureLinks(newLinks)];

        if (state.Links.showMore && newLinks.length < 4) {
          actions.push(
            getLinks({
              endpoint: 'for_you',
              withSelection: false,
              loadMore: true,
            })
          );
        }

        return actions;
      }

      return EMPTY;
    })
  );
};
