import React, { Component } from 'react';
import cn from 'classnames';
import { get, prepare } from '../../../abstracts/Sections';
import ViewComponent, { Body, Bar, Types } from '../../template/View';
import BarComponent, { Filters as BarFilters } from '../../dashboard/Bar';
import styles from './View.module.scss';

/*
 * Export
 */
export const { Left, Right, Center, Menu, Filters } = prepare(
  'Left',
  'Right',
  'Center',
  'Menu',
  'Filters'
);

/**
 * View Component.
 */
class View extends Component {
  render() {
    const { children, page, simple, name, hide } = this.props;

    const { LeftSection, RightSection, CenterSection, MenuSection, FiltersSection } = get(
      children,
      Left,
      Right,
      Center,
      Menu,
      Filters
    );

    return (
      <ViewComponent page={page} simple={simple}>
        {!hide && (
          <Bar>
            <BarComponent name={name}>
              <BarFilters>{FiltersSection}</BarFilters>
            </BarComponent>
          </Bar>
        )}

        {MenuSection && <Types>{MenuSection}</Types>}

        <Body>
          {(LeftSection || RightSection) && (
            <div className={styles.content}>
              <div className={styles.links}>
                <div className={styles.list}>{LeftSection}</div>
              </div>
              <div className={styles.details}>{RightSection}</div>
            </div>
          )}

          {CenterSection && (
            <div className={styles.content}>
              <div className={cn(styles.centerSection, 'S')}>{CenterSection}</div>
            </div>
          )}
        </Body>
      </ViewComponent>
    );
  }
}

export default View;
