import { SET, RESET, SET_WHERE, SET_GROUP, RESET_GROUP } from './Filters.constants';

/*
 * Initial State.
 */
const initialState = {
  selected: [],

  where: 'or',

  group: null,
  groupAdded: false,

  // When you want to make forced refresh change this value,
  forcedRefreshTime: Date.now(),
};

const makeTrickForUser = (selected) => {
  let toReturn = {};

  selected.forEach((filter) => {
    if (
      filter.type === 'user' &&
      typeof filter.value === 'object' &&
      filter.value.setAfterRefresh
    ) {
      toReturn = {
        selected: [
          {
            type: 'user',
            value: {
              name: filter.value.name,
              id: filter.value.id,
            },
          },
        ],
      };
    }
  });

  return toReturn;
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     * SET
     */
    case SET:
      return {
        ...state,
        selected: [...action.payload.filters],
      };

    /*
     * RESET
     */
    case RESET:
      return {
        ...initialState,
        group: state.group,
        forcedRefreshTime: Date.now(),
        ...makeTrickForUser(state.selected),
      };

    /*
     * SET WHERE
     */
    case SET_WHERE:
      return {
        ...state,
        where: action.payload.where,
      };

    /*
     * GROUP
     */
    case SET_GROUP:
      return {
        ...state,
        group: action.payload,
        groupAdded: true,
      };

    case RESET_GROUP:
      return {
        ...state,
        group: null,
        groupAdded: false,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
