import React from 'react';
import ViewComponent, { Center as CenterSection } from '../../dashboard/View';
import DownloadComponent from '../Download';

/**
 * Download Component.
 */
const Download = () => {
  return (
    <ViewComponent page="download" simple hide={true}>
      <CenterSection>
        <DownloadComponent />
      </CenterSection>
    </ViewComponent>
  );
};

export default Download;
