import React from 'react';
import ViewComponent, { Center as CenterSection } from '../../dashboard/View';
import UserName from '../../settings/UserName';
import Password from '../../settings/Password';
import DarkList from '../../settings/DarkList';
import cn from 'classnames';
import styles from '../../settings/styles/Common.module.scss';

/**
 * Settings Component.
 */
const Settings = () => {
  return (
    <ViewComponent page="settings" hide={true}>
      <CenterSection>
        <div className={cn(styles.scroller, 'S')}>
          <div className={styles.wrapper}>
            <h2>User name</h2>

            <UserName />
          </div>

          <div className={styles.wrapper}>
            <h2>Password</h2>

            <Password />
          </div>

          <div className={styles.wrapper}>
            <h2 className={styles.dark}>Dark list</h2>

            <DarkList />
          </div>
        </div>
      </CenterSection>
    </ViewComponent>
  );
};

export default Settings;
