/* eslint-disable import/prefer-default-export */
import { createRoutine } from '../../abstracts/Routine';

/*
 * Routines.
 */
export const login = createRoutine('ACCOUNT__LOGIN');
export const checkEmail = createRoutine('ACCOUNT__CHECK_EMAIL');
export const register = createRoutine('ACCOUNT__REGISTER');
export const confirm = createRoutine('ACCOUNT__CONFIRM');
export const reset = createRoutine('ACCOUNT__RESET_PASSWORD');
export const password = createRoutine('ACCOUNT__PASSWORD');
