import { connect } from 'react-redux';
import * as StatisticsRoutines from '../../models/Statistics/Statistics.routines';
import { bindRoutines } from '../../abstracts/Routine';
import StatisticsComponent from './Statistics.component';

/*
 * Connection.
 */
const mapStateToProps = (state) => ({
  logged: state.User.logged,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        getStats: StatisticsRoutines.getStats.trigger,
      },
      dispatch
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsComponent);
