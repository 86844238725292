import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { openModal, closeAndOpen } from '../../../models/Modals/Modals.actions';
import { setUserHas } from '../../../models/Links/Links.actions';
import * as MODALS from '../../../models/Modals/Modals.types';
import { getDetails } from '../../../models/Adding/Adding.routines';
import { bindRoutines } from '../../../abstracts/Routine';
import { removeFromWaitings, removeLink } from '../../../models/Links/Links.routines';
import { upload } from '../Friends/Friends.helper';

/**
 * Added Container.
 */
class Added extends Component {
  componentDidMount() {
    this.prepareInjection();
    this.prepareAlerts();
    this.markAsUserHas();
    this.remove();
  }

  prepareAlerts() {
    const { is } = this.props.state;

    if (is === 'private' || is === 'public') {
      const keys = Object.keys(this.props.response);
      const status = this.props.response[keys[0]].toLowerCase();

      this.openModal([
        {
          status,
          name: this.props.user.full_name,
        },
      ]);
    } else if (is === 'group') {
      const collection = [];

      Object.keys(this.props.response).forEach((_groupId) => {
        const groupId = parseInt(_groupId, 10);

        const status = this.props.response[groupId].toLowerCase();
        const group = this.props.groups.find((_group) => parseInt(_group.id, 10) === groupId);

        if (group) {
          const { name } = group;

          collection.push({
            status,
            name,
          });
        }
      });

      this.openModal(collection);
    } else if (is === 'friend') {
      const collection = [];

      Object.keys(this.props.response).forEach((user) => {
        collection.push({
          status: this.props.response[user].toLowerCase(),
          name: user,
        });
      });

      upload(this.props.response);

      this.openModal(collection);
    }
  }

  prepareInjection() {
    const { is } = this.props.state;
    const path = this.props.location.pathname;

    if (path === '/dashboard/saved' && (is === 'private' || is === 'public')) {
      this.addLink();
    }
  }

  markAsUserHas() {
    const { is } = this.props.state;
    const path = this.props.location.pathname;

    if (path === '/dashboard/waitings' && (is === 'private' || is === 'public')) {
      this.props.reduxActions.setUserHas(this.props.state.originSnap.id);
    }
  }

  addLink() {
    this.props.reduxActions.getDetails({
      hash: this.props.state.originSnap.hash,
    });
  }

  openModal(data) {
    let fcn;

    if (this.props.type) {
      fcn = this.props.reduxActions.closeAndOpen;
    } else {
      fcn = this.props.reduxActions.openModal;
    }

    fcn({
      type: MODALS.ADDED,
      payload: {
        title: this.props.state.fields.title.value,
        type: this.props.state.is,
        data,
      },
    });
  }

  remove() {
    if (this.props.state.remove) {
      const snap = this.props.state.originSnap;
      let { is } = snap;

      if (!is) {
        is = 'waitings';
      }

      is = is.toLowerCase();

      const payload = {
        id: snap.id,
        link: snap.link,
      };

      if (is === 'waitings') {
        this.props.reduxActions.removeFromWaitings(payload);
      } else if (is === 'for_you') {
        this.props.reduxActions.removeLink(payload);
      }
    }
  }

  render() {
    return null;
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        getDetails: getDetails.trigger,
        removeFromWaitings: removeFromWaitings.trigger,
        removeLink: removeLink.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        openModal,
        closeAndOpen,
        setUserHas,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  response: state.Adding.response,
  groups: state.Groups.all.data,
  user: state.User.data,
  // filters: state.Filters.selected,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Added)
);
