import { combineEpics, ofType } from 'redux-observable';
import { mergeMap, tap, map, filter, catchError } from 'rxjs/operators';
import cutDataForPlaylist from '../../globals/helpers/cutDataForPlaylist';
import Player from '../../services/Player';
import * as APP from '../../models/App/App.constants';
import { ajax } from 'rxjs/ajax';
import config from '../../config/config';
import { whenFailure, whenSuccess, whenTimeout } from '../../globals/api/index';
import getHeaders from '../../globals/helpers/headers';
import { concat, EMPTY, of, race } from 'rxjs';
import * as PLAYER from './Player.constants';
import * as Actions from './Player.actions';
import * as PlayerRoutines from './Player.routines';

export const preview = (action$) => {
  return action$.pipe(
    ofType(PLAYER.PREVIEW),
    tap((action) => {
      Player.play(action.payload.video.details.videoId);
    }),
    mergeMap(() => {
      return EMPTY;
    })
  );
};

export const play = (action$) => {
  return action$.pipe(
    ofType(PLAYER.PLAY),
    tap((action) => {
      Player.play(action.payload.id);
    }),
    mergeMap(() => EMPTY)
  );
};

export const stop = (action$) => {
  return action$.pipe(
    ofType(PLAYER.STOP),
    tap(() => {
      Player.stop();
    }),
    mergeMap(() => EMPTY)
  );
};

export const hideWhenClose = (action$) => {
  return action$.pipe(
    ofType(APP.PLAYER_CLOSE),
    mergeMap(() => [Actions.stop(), Actions.setDefaultPlaylist()])
  );
};

export const hide = (action$) => {
  return action$.pipe(
    ofType(PLAYER.HIDE),
    tap(() => {
      Player.hide();
    }),
    mergeMap(() => [Actions.stop()])
  );
};

export const blinkWhenAdded = (action$) => {
  return action$.pipe(
    ofType(PLAYER.ADD),
    mergeMap(() => {
      return [Actions.blink()];
    })
  );
};

export const addAll = (action$, state$) => {
  function onlyUnique(value, index, self) {
    const findIndex = self.findIndex((video) => video.details.videoId === value.details.videoId);

    return findIndex === index;
  }

  return action$.pipe(
    ofType(PLAYER.ADD_ALL),
    mergeMap(() => {
      const videos = state$.value.Player.defaultList;
      const videosFromLinks = state$.value.Links.data
        .filter((link) => link.type === 'video')
        .map(cutDataForPlaylist);

      const playlist = [...videos, ...videosFromLinks].filter(onlyUnique);

      return [Actions.replacePlaylist(playlist), Actions.blink()];
    })
  );
};

export const remove = (action$, state$) => {
  return action$.pipe(
    ofType(PLAYER.REMOVE),
    mergeMap((action) => {
      const videos = state$.value.Player.defaultList;
      const playingId = state$.value.Player.playingId;

      const index = videos.findIndex((video) => video.details.videoId === action.payload.id);
      const playlist = [...videos].filter((video) => video.details.videoId !== action.payload.id);

      const toReturn = [Actions.replacePlaylist(playlist)];

      if (playingId === action.payload.id) {
        if (playingId && index < playlist.length) {
          toReturn.push(Actions.play(playlist[index].details.videoId));
        } else {
          toReturn.push(Actions.stop());
        }
      }

      return toReturn;
    })
  );
};

export const runCurrentSongWhenBackToPlaylist = (action$, state$) => {
  return action$.pipe(
    ofType(PLAYER.SET_DEFAULT_PLAYLIST),
    map(() => {
      return state$.value.Player.playingId;
    }),
    tap((playingId) => {
      if (playingId) {
        Player.play(playingId);
      } else {
        Player.hide();
      }
    }),
    mergeMap(() => {
      return EMPTY;
    })
  );
};

export const tryNext = (action$, state$) => {
  return action$.pipe(
    ofType(PLAYER.NEXT),
    mergeMap(() => {
      // eslint-disable-next-line no-shadow
      const { preview, playingId, defaultList, shuffle } = state$.value.Player;

      if (preview) {
        return EMPTY;
      }

      const currentIndex = defaultList.findIndex((video) => video.details.videoId === playingId);

      let nextIndex = currentIndex;

      if (shuffle) {
        while (nextIndex === currentIndex) {
          nextIndex = Math.floor(Math.random() * defaultList.length);
        }
      } else {
        nextIndex = currentIndex + 1;
      }

      if (nextIndex >= defaultList.length) {
        return [Actions.hide()];
      }

      return [Actions.play(defaultList[nextIndex].details.videoId)];
    })
  );
};

export const songEnded = (action$, state$) => {
  return action$.pipe(
    ofType(PLAYER.SONG_ENDED),
    mergeMap(() => {
      // eslint-disable-next-line no-shadow
      const { preview, playingId, defaultList, repeat } = state$.value.Player;

      // repeat one
      if (repeat === 2) {
        return [Actions.play(playingId)];
      }
      // repeat all
      else if (repeat === 1) {
        if (preview) {
          return [Actions.play(preview.details.videoId)];
        }
        // playlist

        const nextIndex = defaultList.findIndex((video) => video.details.videoId === playingId) + 1;

        if (nextIndex >= defaultList.length) {
          return [Actions.play(defaultList[0].details.videoId)];
        }
      }

      return [Actions.next()];
    })
  );
};

export const tryPrev = (action$, state$) => {
  return action$.pipe(
    ofType(PLAYER.PREV),
    mergeMap(() => {
      // eslint-disable-next-line no-shadow
      const { preview, playingId, defaultList } = state$.value.Player;

      if (preview) {
        return EMPTY;
      }

      const prevIndex = defaultList.findIndex((video) => video.details.videoId === playingId) - 1;

      if (prevIndex < 0) {
        return [Actions.hide()];
      }

      return [Actions.play(defaultList[prevIndex].details.videoId)];
    })
  );
};

export const getVideosWhenTagSet = (action$) => {
  return action$.pipe(
    ofType(PLAYER.SET_TAG),
    mergeMap((action) => {
      return [PlayerRoutines.get.trigger(action.payload)];
    })
  );
};

export const getVideos = (action$, state$) => {
  const routine = PlayerRoutines.get;
  const loading = !state$.value.Player.loading;

  return action$.pipe(
    ofType(routine.TRIGGER),
    filter(() => loading),
    mergeMap((action) => {
      return concat(
        of(routine.request()),
        race(
          ajax.get(`${config.apiHost}player/show?tags[0]=${action.payload.tag}`, getHeaders()).pipe(
            mergeMap(whenSuccess(routine, action)),
            catchError(whenFailure(routine))
          ),
          whenTimeout(routine)
        )
      );
    })
  );
};

export const useVideosFromTagAsDefaultPlaylist = (action$) => {
  const routine = PlayerRoutines.get;

  return action$.pipe(
    ofType(routine.SUCCESS),
    mergeMap((action) => {
      return [Actions.replacePlaylist(action.payload)];
    })
  );
};

export default combineEpics(
  addAll,
  blinkWhenAdded,
  getVideos,
  getVideosWhenTagSet,
  hide,
  hideWhenClose,
  play,
  preview,
  remove,
  runCurrentSongWhenBackToPlaylist,
  songEnded,
  stop,
  tryNext,
  tryPrev,
  useVideosFromTagAsDefaultPlaylist
);
