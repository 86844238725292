import { concat, of } from 'rxjs';

/**
 * When success.
 *
 * @param routine
 */
const whenSuccess = (routine, action = null, ...rest) => (response) => {
  return concat(of(routine.success(response.response, action)), of(routine.fulfill()), ...rest);
};

export default whenSuccess;
