import React, { Component, Fragment } from 'react';
import { get, getProps, prepare } from '../../../abstracts/Sections';
import cn from 'classnames';
import styles from './Menu.module.scss';

/*
 * Sections
 */
export const { Button, Content } = prepare('Button', 'Content');

export const Separator = () => <li className={styles.separator} />;

/**
 * Menu Component.
 */
class Menu extends Component {
  static handleClose(e) {
    e.stopPropagation();

    if (e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };

    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.toggle);
  }

  toggle() {
    this.setState(
      {
        opened: !this.state.opened,
      },
      this.manageEvent
    );
  }

  close() {
    this.setState(
      {
        opened: false,
      },
      this.manageEvent
    );
  }

  manageEvent() {
    if (this.state.opened) {
      document.addEventListener('click', this.toggle, false);
    } else {
      document.removeEventListener('click', this.toggle);
    }
  }

  render() {
    const { ButtonSection, ContentSection } = get(this.props.children, Button, Content);
    const { ButtonProps, ContentProps } = getProps(this.props.children, Button, Content);

    let shouldClose = true;

    if (Object.hasOwnProperty.call(this.props, 'close')) {
      shouldClose = !!this.props.close; // eslint-disable-line
    }

    const style = cn(
      styles.content,
      !this.props.withoutLinks && styles.links,
      ContentProps.className
    );

    const datas = ButtonProps.dataProps || {};

    return (
      <Fragment>
        <button
          onClick={this.toggle}
          className={cn(
            styles.button,
            ButtonProps.className,
            this.state.opened && 'menu-button--opened'
          )}
          {...datas}
        >
          {ButtonSection}
        </button>
        {this.state.opened && shouldClose && <div className={style}>{ContentSection}</div>}

        {this.state.opened && !shouldClose && (
          <div role="none" onClick={this.constructor.handleClose}>
            <div className={style}>
              {React.cloneElement(ContentSection, {
                handleClose: this.close,
              })}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Menu;
