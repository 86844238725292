import React from 'react';
import cn from 'classnames';
import noCheckInput from '../../../globals/helpers/noCheckInput';
import styles from '../styles/Common.module.scss';
import PolicyButton from '../PolicyButton';
import Friends from '../Friends';
import Groups from '../Groups';
import Tags from '../Tags';
import Image from '../../details/Image';
import RevertButton from '../RevertButton';
import FieldError from '../../errors/Field/Field.component';

/**
 * FulFill Component.
 */
const FulFill = ({
  loading,
  errors,

  originSnap,
  handlers,
  fields: { title, description, users, groups: groupsField },
  is,
  handleSetIs,

  editMode,
  handleChangeEditMode,
  handleChangeImage,
  imageUrl,
  send,
  added,
  handleCloseModalAndReset,
  handleRevert,

  shouldLoadTags,
  markTagsAsLoaded,

  handleChangeData,
  emails,
  tags,
  groups,
}) => {
  const canSave = !(originSnap.user_has && (is === 'private' || is === 'public'));

  const imageStyles = {
    cover: null,
    small: null,
    image: null,
    imageError: styles.imageError,
  };

  return (
    <div className={styles.adding}>
      <div className={styles.link}>{originSnap.link}</div>

      <div className={styles.columns}>
        <div className={styles.imageWrapper}>
          {imageUrl && (
            <div className={styles.image}>
              <Image key={imageUrl} src={imageUrl} styles={imageStyles} />
              <button onClick={handleChangeEditMode} className={styles.changeImageMode} />
            </div>
          )}

          {(!imageUrl || (imageUrl && editMode)) && (
            <div className={styles.imageUrl}>
              <input
                type="text"
                className={styles.inputImage}
                defaultValue={imageUrl}
                {...noCheckInput}
                name="image"
                ref={handlers.ref}
                placeholder="Image URL"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleChangeImage();
                  }
                }}
              />
              <button onClick={handleChangeImage}>Change image</button>

              {1 === 0 && <div className={styles.incorrectImage}>This url is incorrect</div>}
            </div>
          )}
        </div>

        <div className={styles.infoWrapper}>
          <input
            type="text"
            value={title.value}
            className={styles.input}
            onChange={handlers.change}
            name={title.name}
            placeholder="Title"
          />
          <textarea
            className={styles.textarea}
            name={description.name}
            value={description.value}
            onChange={handlers.change}
          />
        </div>
      </div>

      <div className={styles.type}>
        <ul>
          <li>
            <PolicyButton is={is} type="private" text="Save as private" handleSetIs={handleSetIs} />
          </li>
          <li>
            <PolicyButton is={is} type="public" text="Save as public" handleSetIs={handleSetIs} />
          </li>
          <li>
            <PolicyButton is={is} type="friend" text="Send to users" handleSetIs={handleSetIs} />
          </li>
          <li>
            <PolicyButton is={is} type="group" text="Share with groups" handleSetIs={handleSetIs} />
          </li>
        </ul>

        <FieldError data={groupsField} apiData={errors} names="Groups" className={styles.error} />
        <FieldError data={users} apiData={errors} names="Emails" className={styles.error} />
      </div>

      {is === 'friend' && <Friends change={handleChangeData} initialData={emails} />}
      {is === 'group' && <Groups change={handleChangeData} initialData={groups} />}

      {is !== 'friend' && (
        <Tags
          shouldLoadTags={shouldLoadTags}
          markTagsAsLoaded={markTagsAsLoaded}
          change={handleChangeData}
          initialData={tags}
          is={is}
        />
      )}

      <div className={styles.addAgain}>
        <button
          onClick={send}
          disabled={!canSave || added}
          className={cn(styles.save, loading && styles.loading)}
        >
          {canSave && 'Add link'}
          {!canSave && 'You already have it'}
        </button>

        <div className={styles.buttonWrapper}>
          <button className={styles.cancel} onClick={handleCloseModalAndReset}>
            Cancel without confirmation
          </button>

          <div className={styles.revert}>
            <button>Revert</button>

            <ul>
              <RevertButton name="Title" action={handleRevert} />
              <RevertButton name="Description" action={handleRevert} />
              <RevertButton name="Image" action={handleRevert} />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FulFill;
