/**
 * Filter object that returned has only keys with real values.
 *
 * @param obj
 */
const withValue = (obj) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export default withValue;
