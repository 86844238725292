import * as Alert from '../../models/Alert/Alert.constants';
import { getDetails, changePassword } from '../User/User.routines';
import {
  login,
  checkEmail,
  register,
  confirm,
  reset,
  password,
} from '../Account/Account.routines';
import { getTags } from '../Tags/Tags.routines';
import { getLinks, removeFromWaitings, removeLink } from '../Links/Links.routines';
import { getFollowers, unfollow } from '../Followers/Followers.routines';
import { getGroups, getAllGroups } from '../Groups/Groups.routines';
import { check, fetch } from '../Fetch/Fetch.routines';
import { getSnap, addLink, bulkAdding } from '../Adding/Adding.routines';
import { report } from '../Report/Report.routines';
import { remove, loadMore } from '../Comments/Comments.routines';
import { getFollower } from '../Follower/Follower.routines';
import * as helpers from './Alert.helpers';

/*
 * Initial State.
 */
const initialState = [];

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  let newState;
  let index;

  switch (action.type) {
    /*
     * ADD ERROR
     */
    case addLink.FAILURE:
    case changePassword.FAILURE:
    case check.FAILURE:
    case checkEmail.FAILURE:
    case confirm.FAILURE:
    case fetch.FAILURE:
    case getAllGroups.FAILURE:
    case getDetails.FAILURE:
    case getFollowers.FAILURE:
    case getGroups.FAILURE:
    case getLinks.FAILURE:
    case getSnap.FAILURE:
    case getTags.FAILURE:
    case loadMore.FAILURE:
    case login.FAILURE:
    case password.FAILURE:
    case register.FAILURE:
    case remove.FAILURE:
    case removeFromWaitings.FAILURE:
    case removeLink.FAILURE:
    case report.FAILURE:
    case reset.FAILURE:
    case unfollow.FAILURE:
    case getFollower.FAILURE:
    case bulkAdding.FAILURE:
      if (action.payload.response && action.payload.response.alert) {
        return [...state, helpers.asError(action)];
      }

      return state;

    /*
     * ADD SUCCESS
     */
    case Alert.SUCCESS:
      return [...state, helpers.asSuccess(action.payload)];

    /*
     * ADD INFO
     */
    case Alert.INFO:
      return [...state, helpers.asInfo(action.payload)];

    /*
     * REMOVE
     */
    case Alert.REMOVE:
      index = state.findIndex((item) => item.alert.key === action.key);

      if (index >= 0) {
        newState = [...state];
        newState.splice(index, 1);

        return newState;
      }

      return state;

    default:
      return state;
  }
};

export default Reducer;
