import epic from '../../abstracts/Epic';
import config from '../../config/config';
import * as Account from './Account.routines';

/**
 * Account Login Epic.
 */
export const login = epic(Account.login, `${config.apiHost}account/login`, true, 'Account');

/**
 * Account Check Email Epic.
 */
export const checkEmail = epic(
  Account.checkEmail,
  `${config.apiHost}account/email`,
  true,
  'Account',
  true
);

/**
 * Account Register Epic.
 */
export const register = epic(
  Account.register,
  `${config.apiHost}account/register`,
  true,
  'Account'
);

/**
 * Account Reset Epic.
 */
export const reset = epic(Account.reset, `${config.apiHost}account/reset`, true, 'Account');

/**
 * Account Password Epic.
 */
export const password = epic(
  Account.password,
  `${config.apiHost}account/password`,
  true,
  'Account'
);

/**
 * Account Confirm Epic.
 */
export const confirm = epic(Account.confirm, `${config.apiHost}account/confirm`, true);
