class Player {
  constructor() {
    this.iframeLoaded = false;

    this.addPostMessageListener();
  }

  setIframe(iframe) {
    if (!this.iframeLoaded) {
      this.iframe = iframe;
      this.iframeLoaded = true;
    }
  }

  sendMessage(action) {
    this.iframe.contentWindow.postMessage(
      JSON.stringify({
        type: 'AS',
        action,
      }),
      '*'
    );
  }

  play(id) {
    this.sendMessage({
      type: 'PLAY',
      payload: {
        id,
      },
    });
  }

  stop() {
    this.sendMessage({
      type: 'STOP',
    });
  }

  hide() {
    this.sendMessage({
      type: 'HIDE',
    });
  }

  addPostMessageListener() {
    function receiveMessage(_event) {
      try {
        if (typeof _event.data === 'string') {
          const event = JSON.parse(_event.data);

          if (event.type === 'AS') {
            const newEvent = new CustomEvent(`AS_${event.action.type}`, {
              detail: event.action.payload,
            });

            window.dispatchEvent(newEvent);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    window.addEventListener('message', receiveMessage, false);
  }
}

export default new Player();
