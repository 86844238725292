import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import { LINK_SHARE, LINK_EDIT } from '../../../models/Modals/Modals.types';
import {
  removeLink,
  removeFromWaitings,
  addToSpeedDial,
  removeFromSpeedDial,
  changePrivacy,
  block,
} from '../../../models/Links/Links.routines';
import * as ModalsActions from '../../../models/Modals/Modals.actions';
import { report } from '../../../models/Report/Report.routines';
import { add, preview } from '../../../models/Player/Player.actions';
import Button from '../../../shared/Button';
import ActionsComponent from './Actions.component';

/**
 * Actions Container.
 */
class Actions extends Component {
  constructor(props) {
    super(props);

    this.renderButton = this.renderButton.bind(this);
    this.remove = this.remove.bind(this);
    this.changeSpeedDial = this.changeSpeedDial.bind(this);
    this.changePrivacy = this.changePrivacy.bind(this);
    this.openToEdit = this.openToEdit.bind(this);
    this.open = this.open.bind(this);
    this.report = this.report.bind(this);
  }

  open(type) {
    this.props.reduxActions.openModal({
      type: LINK_SHARE,
      payload: {
        type,
        data: this.props.link,
      },
    });
  }

  openToEdit() {
    this.props.reduxActions.openModal({
      type: LINK_EDIT,
      payload: this.props.link,
    });
  }

  prepareRemove() {
    const { dispatch } = this.props.reduxActions;

    switch (this.props.type) {
      case 'saved':
      case 'for_you':
      case 'grouped':
        return ModalsActions.wrapWithConfirm(
          dispatch,
          removeLink.trigger, // original action
          `Do you want to remove '${this.props.link.title}'?`,
          true
        );

      case 'waitings':
        return ModalsActions.wrapWithConfirm(
          dispatch,
          removeFromWaitings.trigger, // original action
          `Do you want to remove every '${this.props.link.link}'?`,
          true
        );

      default:
        return null;
    }
  }

  remove(event) {
    const action = this.prepareRemove();

    if (action) {
      action(event, true, {
        id: this.props.link.id,
        link: this.props.link.link,
      });
    }
  }

  changeSpeedDial() {
    const { id } = this.props.link;

    if (this.props.link.speed_dial) {
      this.props.reduxActions.removeFromSpeedDial({ id });
    } else {
      this.props.reduxActions.addToSpeedDial({ id });
    }
  }

  changePrivacy() {
    this.props.reduxActions.changePrivacy({
      id: this.props.link.id,
      private: this.props.link.private ? 0 : 1,
    });
  }

  report(event) {
    const action = ModalsActions.wrapWithConfirm(
      this.props.reduxActions.dispatch,
      report.trigger,
      `Do you want to report '${this.props.link.title}' from ${this.props.link.user.full_name}?`
    );

    action(event, true, { id: this.props.link.id });
  }

  renderButton(name, type, className = null) {
    return (
      <Button
        className={cn(className)}
        onClick={() => {
          this.open(type);
        }}
      >
        {name}
      </Button>
    );
  }

  render() {
    return (
      <ActionsComponent
        link={this.props.link}
        type={this.props.type}
        userId={this.props.userId}
        renderButton={this.renderButton}
        handleRemove={this.remove}
        handleChangeSpeedDial={this.changeSpeedDial}
        handleChangePrivacy={this.changePrivacy}
        handleEdit={this.openToEdit}
        handleAdd={this.props.reduxActions.add}
        handlePreview={this.props.reduxActions.preview}
        handleOpen={this.open}
        handleBlock={this.props.reduxActions.block}
        handleReport={this.report}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        openModal: ModalsActions.openModal,
        addToSpeedDial: addToSpeedDial.trigger,
        removeFromSpeedDial: removeFromSpeedDial.trigger,
        changePrivacy: changePrivacy.trigger,
        block: block.trigger,
        add,
        preview,
      },
      dispatch
    ),
    dispatch,
  },
});

const mapStateToProps = (state) => ({
  userId: state.User.data.id,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions);
