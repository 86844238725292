/* eslint-disable import/prefer-default-export */
import { createRoutine } from '../../abstracts/Routine';

/*
 * Routines.
 */
export const getLinks = createRoutine('LINKS__GET_LINKS');
export const removeLink = createRoutine('LINKS__REMOVE_LINK');
export const removeFromWaitings = createRoutine('LINKS__REMOVE_FROM_WAITINGS');
export const massRemoveFromWaitings = createRoutine('LINKS__MASS_REMOVE_FROM_WAITINGS');

export const addToSpeedDial = createRoutine('LINKS__ADD_TO_SPEED_DIAL');
export const removeFromSpeedDial = createRoutine('LINKS__REMOVE_FROM_SPEED_DIAL');
export const changePrivacy = createRoutine('LINKS__CHANGE_PRIVACY');
export const edit = createRoutine('LINKS__EDIT');

export const sayThankYou = createRoutine('LINKS__SAY_THANK_YOU');
export const block = createRoutine('LINKS__BLOCK');
