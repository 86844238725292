import { getFollower } from './Follower.routines';
import { SET_EMAIL } from './Follower.constants';

/*
 * Initial State.
 */
const initialState = {
  data: null,
  email: null,
  error: false,
  followers: [],
  followersLoading: false,
  loading: false,
  pagination: null,
};

/*
 * Define Reducer.
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
        error: false,
      };

    /*
     * LOADING
     */
    case getFollower.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };

    case getFollower.FULFILL:
      return {
        ...state,
        loading: false,
      };

    /*
     * GET FOLLOWERS
     */
    case getFollower.SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    /*
     * FAILURE
     */
    case getFollower.FAILURE:
      return {
        ...state,
        error: action.payload.response.errors,
      };

    /*
     * DEFAULT
     */
    default:
      return state;
  }
};

export default Reducer;
