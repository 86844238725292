import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindRoutines } from '../../../abstracts/Routine';
import { preview } from '../../../models/Player/Player.actions';
import * as ModalsActions from '../../../models/Modals/Modals.actions';
import { removeFromWaitings, sayThankYou } from '../../../models/Links/Links.routines';
import { setFilters } from '../../../models/Filters/Filters.actions';
import * as FetchRoutines from '../../../models/Fetch/Fetch.routines';
import DetailsComponent from './../Details';
import FetchComponent from './../Fetch';
import Loader from './../Loader';

/**
 * Switch Container.
 */
class Switch extends Component {
  constructor(props) {
    super(props);

    this.inner = null;

    this.setInnerRef = this.setInnerRef.bind(this);
    this.makeScroll = this.makeScroll.bind(this);
    this.fetch = this.fetch.bind(this);
    this.removeFromFetchComponent = this.removeFromFetchComponent.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentWillReceiveProps(props) {
    const id = this.props.data && this.props.data.link && this.props.data.link.id;

    if (props.data && props.data.link && props.data.link.id !== id) {
      if (this.inner) {
        this.inner.scrollTop = 0;
      }

      if (
        props.data.link &&
        !(this.props.fetched || props.data.link.checked || props.data.link.fetched)
      ) {
        this.props.reduxActions.check({
          id: props.data.link.id,
          link: props.data.link.link,
        });
      }
    }
  }

  setInnerRef(ref) {
    this.inner = ref;
  }

  makeScroll(e) {
    this.inner.scrollTop += e.deltaY;
  }

  scrollToTop() {
    this.inner.scrollTop = 0;
  }

  fetch() {
    this.props.reduxActions.fetch({
      id: this.props.data.link.id,
      link: this.props.data.link.link,
    });
  }

  removeFromFetchComponent(event) {
    const action = ModalsActions.wrapWithConfirm(
      this.props.reduxActions.dispatch,
      removeFromWaitings.trigger, // original action
      `Do you want to remove every '${this.props.data.link.link}'?`,
      true
    );

    action(event, true, {
      id: this.props.data.link.id,
      link: this.props.data.link.link,
    });
  }

  render() {
    if (!this.props.data) {
      return null;
    }

    if (this.props.loading && !(this.props.data && this.props.data.link.checked)) {
      return <Loader />;
    }

    if (
      this.props.data &&
      this.props.data.link &&
      !(this.props.data.link.fetched || this.props.fetched)
    ) {
      return (
        <FetchComponent
          data={this.props.data}
          loading={this.props.loading}
          handleMakeScroll={this.makeScroll}
          handleSetInnerRef={this.setInnerRef}
          handleFetch={this.fetch}
          handleRemove={this.removeFromFetchComponent}
          handleScrollToTop={this.scrollToTop}
          key={Math.random()}
        />
      );
    }

    return (
      <DetailsComponent
        data={this.props.data}
        type={this.props.type}
        handleMakeScroll={this.makeScroll}
        handleSetInnerRef={this.setInnerRef}
        handleScrollToTop={this.scrollToTop}
        handlePreview={this.props.reduxActions.preview}
        handleSayThankYou={this.props.reduxActions.sayThankYou}
        handleSetFilters={this.props.reduxActions.setFilters}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        check: FetchRoutines.check.trigger,
        fetch: FetchRoutines.fetch.trigger,
        sayThankYou: sayThankYou.trigger,
        setFilters,
        preview,
      },
      dispatch
    ),
    dispatch,
  },
});

const selectSelected = (state) => {
  const index = state.Links.data.findIndex((item) => item.id === state.Links.selected);

  if (index >= 0) {
    return {
      index,
      link: state.Links.data[index],
    };
  }

  return null;
};

const mapStateToProps = (state) => ({
  loading: state.Fetch.loading,
  data: selectSelected(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Switch);
