import React from 'react';
import cn from 'classnames';
import prepareAvatar from '../../../globals/helpers/prepareAvatar';
import cutDataForPlaylist from '../../../globals/helpers/cutDataForPlaylist';
import moment from 'moment';
import Image from '../../details/Image';
import styles from '../../details/Details/Details.module.scss';
import Tags from '../../details/Tags';
import Actions from '../../details/Actions';
import Extras from '../../details/Extras';
import Button from '../../../shared/Button';

/**
 * Details Component.
 */
const Details = ({ link, handlePreview, handleClose }) => {
  const time = moment.utc(link.created_at).local();

  const user = link.user || link.from_user || null;

  return (
    <div className={cn(styles.detail, 'R')} id="details">
      <div className={styles.controlsWrapper}>
        <div className={styles.controls}>
          <div className={styles.left}>
            <Button
              className={styles.button}
              icon="arrow_back"
              disabled={false}
              left
              onClick={handleClose}
            >
              Back
            </Button>
          </div>
          <Actions type="grouped" link={link} />
        </div>
      </div>

      <div className={styles.inner}>
        {link.hidden && (
          <div className={styles.alreadyHave}>
            Link has been changed and does not match with given filter criteria.
          </div>
        )}

        <div className={styles.about}>
          <div className={cn(styles.actions, user && styles.aboutWithMargin)}>
            <div className={styles.user}>
              {user && (
                <div className={styles.avatar}>
                  <img src={prepareAvatar(user)} alt="" />
                </div>
              )}
              <div className={styles.info}>
                {user && (
                  <div className={styles.userDetails}>
                    <span>@{user.full_name}</span>
                  </div>
                )}
                <div className={styles.info}>
                  <span className={styles.date}>
                    {time.fromNow()}
                    <em>{time.format('DD.MM.YYYY, HH:mm:ss')}</em>
                  </span>
                  <span>
                    <a href={`http://${link.base}`} target="_blank" rel="noreferrer noopener">
                      {link.base}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {link.image && (
          <Image
            key={link.image.url}
            src={link.image.url}
            width={link.image.width}
            height={link.image.height}
            styles={styles}
            withBackground={link.type === 'video'}
          >
            {link.type === 'video' && (
              <div
                className={styles.play}
                onClick={() => {
                  handlePreview(cutDataForPlaylist(link));
                }}
              />
            )}
          </Image>
        )}

        <div className={styles.title}>
          <h2>
            <a href={link.link} target="_blank" rel="noopener noreferrer">
              {link.title}
            </a>
          </h2>
        </div>

        <div className={styles.about}>
          <div className={styles.description}>{link.description}</div>

          <Tags data={link.tags} />
        </div>

        <Extras type="grouped" snapId={link.snap_id} groupId={link.group_id} />
      </div>
    </div>
  );
};

export default Details;
