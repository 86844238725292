import map from './map';

/**
 * Get props.
 *
 * @param children
 * @param components
 *
 * @returns {{}}
 */
const getProps = (children, ...components) => {
  return map(
    (obj) => {
      const { children: _, ...rest } = obj.props;

      return rest;
    },
    'Props',
    children,
    components
  );
};

export default getProps;
