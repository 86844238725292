const mapToArray = (key, list) => {
  const arr = [];

  if (list) {
    list.forEach((value, index) => {
      arr[`${key}[${index}]`] = value;
    });
  }

  return arr;
};

export default mapToArray;
