import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { playerOpen, playerClose } from '../../../models/App/App.actions';
import { openModal } from '../../../models/Modals/Modals.actions';
import RightBoxComponent from './RightBox.component';

/**
 * Right Box Container.
 */
const RightBox = ({ reduxActions, ...rest }) => {
  return (
    <RightBoxComponent
      {...rest}
      openModal={reduxActions.openModal}
      openPlayer={reduxActions.playerOpen}
      closePlayer={reduxActions.playerClose}
    />
  );
};

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  isPlayerOpen: state.App.playerOpen,
  user: state.User.data,
  newThanksReady: state.Statistics.thanks,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      openModal,
      playerOpen,
      playerClose,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightBox);
