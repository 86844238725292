import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as FiltersActions from '../../models/Filters/Filters.actions';
import { bindActionCreators } from 'redux';
import TagsComponent from './Tags.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        setFilters: FiltersActions.setFilters,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Tags.loading,
  tags: state.Tags.data,
  isAnySelected: state.Filters.selected.length > 0,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TagsComponent)
);
