import React, { Component, Fragment } from 'react';
import moment from 'moment';
import gravatar from '../../../globals/helpers/gravatar';
import { Link } from 'react-router-dom';
import NoFollower from '../../noItems/NoFollower';
import styles from './Author.module.scss';

/**
 * Author Component
 */
class Author extends Component {
  componentDidMount() {
    if (this.props.email) {
      this.props.reduxActions.setFollowerEmail(this.props.email);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.props.email) {
      this.props.reduxActions.setFollowerEmail(nextProps.email);
    }
  }

  render() {
    const { email, error, data, userId, loading } = this.props;

    if (loading) {
      return <div className={styles.loading} />;
    }

    if (!email) {
      return null;
    }

    if (error) {
      return <NoFollower />;
    }

    if (!data) {
      return null;
    }

    const time = moment.utc(data.created_at).local();

    return (
      <Fragment>
        {data && userId === data.id && (
          <div className={styles.ownProfile}>
            You are watching own public profile. Get to <Link to="/">stored</Link> to see full list.
          </div>
        )}
        <div className={styles.author}>
          <div className={styles.avatar}>
            <img src={gravatar(data.email_hash, 200)} alt={data.full_name} />
          </div>
          <div className={styles.info}>
            <div className={styles.name}>{data.full_name}</div>
            <div className={styles.email}>{time.fromNow()}</div>

            <div className={styles.actions}>
              <div className={styles.data}>
                <button>{data.following ? 'You are following' : 'Follow'}</button>
              </div>
              <div className={styles.data}>
                <span className={styles.value}>{data.followers_count}</span>
                <span className={styles.label}>Followers</span>
              </div>
              <div className={styles.data}>
                <span className={styles.value}>{data.links_count}</span>
                <span className={styles.label}>Public links</span>
              </div>
            </div>
          </div>
          <button className={styles.close} onClick={this.props.reduxActions.resetFilters} />
        </div>
      </Fragment>
    );
  }
}

export default Author;
