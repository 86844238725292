import React from 'react';
import ApiError from '../../errors/Api/Api.component';
import viewStyles from '../../account/View/View.module.scss';
import { Link } from 'react-router-dom';
import * as MODALS from '../../../models/Modals/Modals.types';
import { FOLLOW, INVITE } from '../../../models/Followers/Followers.constants';
import styles from './../styles/Common.module.scss';

/**
 * No Links Component.
 */
const NoLinks = ({ reduxActions, match, endpoint, errors = null }) => {
  return (
    <div className={styles.noLinks}>
      <h4>No links found</h4>

      <div className={styles.info}>
        <span>Try to use solutions:</span>

        <ul>
          {(endpoint === 'saved' || endpoint === 'grouped') && (
            <li>
              <button
                onClick={() => {
                  reduxActions.resetFilters();
                }}
              >
                Clear filters and go to basic list
              </button>
            </li>
          )}

          {(endpoint === 'saved' || endpoint === 'grouped') && (
            <li>
              <button
                onClick={() => {
                  reduxActions.openModal({
                    type: MODALS.LINK_ADD,
                  });
                }}
              >
                Maybe {endpoint === 'saved' ? 'you do' : 'group does'} not have any link, just add
                new one
              </button>
            </li>
          )}

          {endpoint === 'waitings' && (
            <li>
              <Link to="/download">Add out extension to your browser</Link>
            </li>
          )}

          {endpoint === 'for_you' && (
            <li>
              <button
                onClick={() => {
                  reduxActions.openModal({
                    type: MODALS.FOLLOWERS,
                    payload: {
                      type: INVITE,
                    },
                  });
                }}
              >
                Maybe you do not have fiends using <b>AS</b>, invite them
              </button>
            </li>
          )}

          {endpoint === 'social' && (
            <li>
              <button
                onClick={() => {
                  reduxActions.openModal({
                    type: MODALS.FOLLOWERS,
                    payload: {
                      type: FOLLOW,
                    },
                  });
                }}
              >
                Maybe you do not follow any user. Start it
              </button>
            </li>
          )}
        </ul>
      </div>

      {errors && (
        <div className={styles.info}>
          <div className={styles.tip}>
            <span>Error:</span>
          </div>
          <ApiError data={errors} className={viewStyles.error} />
        </div>
      )}
    </div>
  );
};

export default NoLinks;
