export const API = '@@API';
export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
export const API_ENDPOINT_NOT_FOUND = 'API_ENDPOINT_NOT_FOUND';

export const dictionary = {
  [SOMETHING_WENT_WRONG]: 'Something went wrong',
  [API_ENDPOINT_NOT_FOUND]: 'Ups... We are looking for what does not exist',
  //
  ACCOUNT_IS_ALREADY_ACTIVE: 'Account is already active',
  EMAIL_IS_ALREADY_TAKEN: 'Email is already taken',
  FIELD_IS_DIFFERENT: 'Retyped field should be the same as main field',
  FIELD_IS_NOT_VALID_URL: 'This is not valid URL',
  FIELD_REQUIRED: 'Field is required',
  FIELD_TO_SHORT: 'Field is to short',
  INCORRECT_EMAIL: 'This is not email address',
  INVALID_DATA: 'Data is incorrect',
  INVALID_TOKEN: 'Token is invalid',
  LOGIN_INVALID_DATA: 'Incorrect login or password',
  YOU_DO_NOT_HAVE_ANY_FOLLOWER: 'You do not have any followings',
  YOU_HAVE_ALREADY_REPORTED: 'You have already reported this link.',
};
