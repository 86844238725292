import React from 'react';
import styles from './../Main/Main.module.scss';
import Placeholder from './../Placeholder';

/**
 * Filters Phrase Component.
 */
const FiltersPhrase = ({
  phrase,
  handleClose,
  handleChange,
  handleClear,
  handleSetFilter,
  handleSetFiltersByEnter,
  handleSetRef,
}) => {
  return (
    <div className={styles.wrapper}>
      <Placeholder />
      <div className={styles.group}>
        <input
          type="text"
          name="phrase"
          placeholder="Phrase"
          value={phrase}
          onChange={handleChange}
          onKeyPress={handleSetFiltersByEnter}
          ref={handleSetRef}
        />
      </div>

      <div className={styles.controls}>
        <div className={styles.left}>
          <button onClick={handleClear}>Clear</button>
        </div>
        <div className={styles.right}>
          <button onClick={handleClose}>Cancel</button>
          <button onClick={handleSetFilter} className={styles.blue}>
            {phrase.length > 0 ? 'Filter' : 'Show all'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FiltersPhrase;
