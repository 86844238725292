import React, { Fragment, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from '../Details/Details.module.scss';
import Notes from '../Notes';
import Comments from '../../comments/Comments';
import Button from '../../../shared/Button';

/**
 * Extras Component
 */
const Extras = ({ enabled, type, reduxActions, groupId, snapId }) => {
  const [tab, setTab] = useState(
    enabled === 'grouped-comments' && type !== 'grouped' ? null : enabled
  );

  const change = useCallback(
    (newTab) => {
      setTab(tab === newTab ? null : newTab);
    },
    [tab, setTab]
  );

  useEffect(() => {
    if (tab === 'comments' || tab === 'grouped-comments') {
      reduxActions.set(tab, snapId, groupId);
    } else {
      reduxActions.unset();
    }
  }, [tab, groupId, snapId]);

  return (
    <Fragment>
      <div className={styles.more}>
        <div className={styles.moreMenu}>
          <ul>
            {type === 'saved' && (
              <li className={cn(tab === 'notes' && styles.selected)}>
                <Button onClick={() => change('notes')} icon="subject" hoverable={false}>
                  Private notes
                </Button>
              </li>
            )}
            {type === 'grouped' && (
              <li className={cn(tab === 'grouped-comments' && styles.selected)}>
                <Button onClick={() => change('grouped-comments')} icon="comment" hoverable={false}>
                  Grouped comments
                </Button>
              </li>
            )}
            <li className={cn(tab === 'comments' && styles.selected)}>
              <Button onClick={() => change('comments')} icon="comment" hoverable={false}>
                Public comments
              </Button>
            </li>
          </ul>
        </div>
      </div>

      {tab === 'notes' && <Notes />}
      {(tab === 'comments' || tab === 'grouped-comments') && <Comments />}
    </Fragment>
  );
};

export default Extras;
