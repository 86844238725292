import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as App from '../../../models/App/App.constants';
import * as MODALS from '../../../models/Modals/Modals.types';
import { openModal } from '../../../models/Modals/Modals.actions';
import MenuComponent from './Menu.component';

/**
 * Bar Container.
 */
const Bar = ({ sizes, reduxActions, ...rest }) => {
  return (
    <MenuComponent
      {...rest}
      small={sizes.height === App.SMALL || sizes.width === App.SMALL}
      handleAddGroup={() =>
        reduxActions.openModal({
          type: MODALS.GROUP_ADD,
        })
      }
      handleAddMember={() =>
        reduxActions.openModal({
          type: MODALS.GROUP_ADD_MEMBER,
        })
      }
    />
  );
};

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  sizes: state.App.sizes,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      openModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bar);
