import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindRoutines } from '../../../abstracts/Routine';
import * as Tags from '../../../models/Tags/Tags.routines';
import settings from '../../../config/settings';
import TagsComponent from './Tags.component';

/**
 * Tags Container.
 */
class TagsContainer extends Component {
  static normalizeTag(tag) {
    return tag.trim().replace(/[^a-z0-9]+/i, '');
  }

  constructor(props) {
    super(props);

    this.state = {
      usedTags: props.initialData || [],
      tag: '',
    };

    this.change = this.change.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.tryToAdd = this.tryToAdd.bind(this);
    this.setRef = this.setRef.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.prepareFilterFunction = this.prepareFilterFunction.bind(this);
  }

  componentDidMount() {
    if (this.props.shouldLoadTags) {
      this.props.reduxActions.getTags();

      this.props.markTagsAsLoaded();
    }
  }

  componentWillReceiveProps(props) {
    if (props.tagsIterator !== this.props.tagsIterator) {
      this.setState({
        usedTags: props.initialData || [],
        tag: '',
      });
    }
  }

  setRef(ref) {
    this.input = ref;
  }

  change(event) {
    const tag = this.constructor.normalizeTag(event.target.value);

    this.setState({
      tag,
    });
  }

  remove(tag) {
    const normalizedTag = tag.toLowerCase();

    this.setState(
      {
        usedTags: this.state.usedTags.filter((usedTag) => usedTag.toLowerCase() !== normalizedTag),
      },
      () => {
        this.propagateChanges();
      }
    );
  }

  tryToAdd() {
    this.add(this.state.tag);
  }

  keyPress(event) {
    if (event.key === ' ' || event.key === 'Enter') {
      this.tryToAdd();
    }
  }

  add(_tag) {
    if (this.state.usedTags.length >= settings.howMany.tags) {
      return;
    }

    const tag = this.constructor.normalizeTag(_tag);

    if (tag.length < 3) {
      return;
    }

    const findTag = tag.toLowerCase();

    const index = this.state.usedTags.findIndex((usedTag) => usedTag.toLowerCase() === findTag);

    // not found
    if (index < 0) {
      this.setState(
        {
          usedTags: [...this.state.usedTags, tag],
          tag: '',
        },
        () => {
          this.propagateChanges();
        }
      );
    } else {
      this.setState({
        tag: '',
      });
    }
  }

  keyDown(event) {
    if (event.key === 'ArrowRight') {
      const tagInput = this.constructor.normalizeTag(this.state.tag);

      if (tagInput.length > 0) {
        const re = new RegExp(`^${tagInput}`, 'i');

        const tags = this.props.tags.filter((tag) => {
          return tag.match(re);
        });

        if (tags.length === 1) {
          this.add(tags[0]);
        }
      }
    }
  }

  prepareFilterFunction() {
    if (this.state.tag.length < 1) {
      return () => true;
    }

    const re = new RegExp(`^${this.state.tag}`, 'i');

    return (_tag) => _tag.match(re);
  }

  propagateChanges() {
    this.props.change('tags', this.state.usedTags);
  }

  render() {
    return (
      <TagsComponent
        loading={this.props.loading}
        tags={this.props.tags}
        usedTags={this.state.usedTags}
        tag={this.state.tag}
        prepareFilterFunction={this.prepareFilterFunction}
        handleChange={this.change}
        handleTryToAdd={this.tryToAdd}
        handleKeyPress={this.keyPress}
        handleKeyDown={this.keyDown}
        handleAdd={this.add}
        handleRemove={this.remove}
        handleSetRef={this.setRef}
        small={this.props.small}
        is={this.props.is}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      getTags: Tags.getTags.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  // errors: state.Tags.errors,
  loading: state.Tags.loading,
  tags: state.Tags.data,
  added: state.Tags.added,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsContainer);
