import { connect } from 'react-redux';
import * as FiltersActions from '../../../models/Filters/Filters.actions';
import { bindActionCreators } from 'redux';
import { openModal } from '../../../models/Modals/Modals.actions';
import { withRouter } from 'react-router-dom';
import NoFollowersComponent from './NoFollowers.component';

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      resetFilters: FiltersActions.resetFilters,
      openModal,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  filters: state.Filters.selected,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NoFollowersComponent)
);
