/* global localStorage */
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const download = () => {
  let emails = [];

  try {
    const fromStorage = localStorage.getItem('emails') || '[]';

    const parsed = JSON.parse(fromStorage);

    if (Array.isArray(parsed)) {
      emails = parsed;
    }
  } catch (e) {
    //
  }

  return emails;
};

export const upload = (list) => {
  const keys = Object.keys(list);

  const toSave = keys.filter((key) => {
    const status = list[key].toLowerCase();

    return status === 'created' || status === 'existed';
  });

  const currentEmails = download();

  const unique = [...currentEmails, ...toSave].filter(onlyUnique);

  localStorage.setItem('emails', JSON.stringify(unique));
};
