import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthRoutes, PrivateRoute, PublicRoute } from '../abstracts/Routes';
import Settings from '../config/settings';

// Route Components
import Error404 from '../modules/errors/404';
import Login from '../modules/account/Login';
import Register from '../modules/account/Register';
import Reset from '../modules/account/Reset';
import Password from '../modules/account/Password';
import Confirm from '../modules/account/Confirm';
import Saved from '../modules/pages/Saved';
import WaitingRoom from '../modules/pages/WaitingRoom';
import Grouped from '../modules/pages/Grouped';
import ForYou from '../modules/pages/ForYou';
import Social from '../modules/pages/Social';
import Followers from '../modules/pages/Followers';
import Groups from '../modules/pages/Groups';
import SettingsComponent from '../modules/pages/Settings';
import UpgradeComponent from '../modules/pages/Upgrade';
import DownloadComponent from '../modules/pages/Download';
import DeckComponent from '../modules/pages/Deck';

/**
 * Main routes.
 */
const MainRoutes = ({ isAuthenticated }) => (
  <AuthRoutes isAuthenticated={isAuthenticated}>
    <PublicRoute exact path="/" component={Login} />
    <PublicRoute exact path="/account/register" component={Register} />
    <PublicRoute exact path="/account/reset" component={Reset} />
    <PublicRoute exact path="/account/password/:token" component={Password} />
    <PublicRoute exact path="/account/confirm/:token" component={Confirm} />

    <Redirect exact from={Settings.auth.mainRoute} to={`${Settings.auth.mainRoute}/saved`} />
    <PrivateRoute exact path={`${Settings.auth.mainRoute}/saved`} component={Saved} />
    <PrivateRoute exact path={`${Settings.auth.mainRoute}/waitings`} component={WaitingRoom} />
    <PrivateRoute exact path={`${Settings.auth.mainRoute}/grouped/:id(\\d+)`} component={Grouped} />
    <PrivateRoute exact path={`${Settings.auth.mainRoute}/for-you`} component={ForYou} />
    <PrivateRoute exact path={`${Settings.auth.mainRoute}/social`} component={Social} />

    <PrivateRoute exact path="/followers/:page(\d+)?" component={Followers} />

    <PrivateRoute exact path="/groups/:page(\d+)?" component={Groups} />

    <PrivateRoute exact path="/settings" component={SettingsComponent} />

    <PrivateRoute exact path="/upgrade" component={UpgradeComponent} />

    <PrivateRoute exact path="/get-app" component={DownloadComponent} />

    <PrivateRoute exact path="/deck" component={DeckComponent} />

    {/*
    <PublicRoute exact path="/" component={LandingPage} />
    <Route path="/contact" component={ContactPage} />
    <PrivateRoute path="/trading-view" component={TradingView} />
    */}

    {/* 404 */}
    <Route component={Error404} />
  </AuthRoutes>
);

export default MainRoutes;
