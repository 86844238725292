/**
 * Bind action creator.
 *
 * @param actionCreator
 * @param dispatch
 */
function bindActionCreator(actionCreator, dispatch) {
  return (...args) => dispatch(actionCreator(...args));
}

/**
 * Bind Routines.
 *
 * @param actionCreators
 * @param dispatch
 */
export default function bindRoutines(actionCreators, dispatch) {
  const keys = Object.keys(actionCreators);

  const boundActionCreators = {};

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];

    const actionCreator = actionCreators[key];

    if (typeof actionCreator === 'function') {
      boundActionCreators[key] = bindActionCreator(actionCreator, dispatch);
    }
  }
  return boundActionCreators;
}
