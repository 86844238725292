import React from 'react';
import styles from './PlayerInfo.module.scss';

/**
 * Player Info Component.
 */
const PlayerInfo = ({ videos, reduxActions }) => {
  return (
    <div className={styles.info}>
      <span>
        <span className={styles.text}>
          You have <strong>{videos}</strong> videos.
        </span>
        <button
          onClick={() => {
            reduxActions.addAll();
          }}
        >
          Add to default playlist
        </button>
        .
      </span>
    </div>
  );
};

export default PlayerInfo;
