import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as LinksActions from '../../../models/Links/Links.actions';
import * as LinksRoutines from '../../../models/Links/Links.routines';
import { bindActionCreators } from 'redux';
import { bindRoutines } from '../../../abstracts/Routine';
import NavigationComponent from './Navigation.component';

/**
 * Navigation Container.
 */
class Navigation extends Component {
  constructor(props) {
    super(props);

    this.previous = this.previous.bind(this);
    this.current = this.current.bind(this);
    this.next = this.next.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  next() {
    const next = this.props.index + 1;

    if (next < this.props.length) {
      this.props.reduxActions.selectByIndex(next);
    }
  }

  previous() {
    const prev = this.props.index - 1;

    if (prev >= 0) {
      this.props.reduxActions.selectByIndex(prev);
    }
  }

  current() {
    this.props.reduxActions.selectByIndex(this.props.index);

    this.props.handleScrollToTop();
  }

  loadMore() {
    this.props.reduxActions.getLinks({
      endpoint: this.props.endpoint,
      loadMore: true,
      withSelection: true,
    });
  }

  render() {
    return (
      <NavigationComponent
        className={this.props.className}
        showMore={this.props.showMore && this.props.index >= this.props.length - 1}
        loading={this.props.loading}
        disablePrevious={this.props.index < 1}
        disableNext={this.props.index >= this.props.length - 1 && !this.props.showMore}
        handlePrevious={this.previous}
        handleCurrent={this.current}
        handleNext={this.next}
        handleLoadMore={this.loadMore}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindActionCreators(
      {
        selectByIndex: LinksActions.selectByIndex,
      },
      dispatch
    ),

    ...bindRoutines(
      {
        getLinks: LinksRoutines.getLinks.trigger,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.Links.loading,
  length: state.Links.data.length,
  showMore: state.Links.showMore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
