import React from 'react';
import * as MODALS from '../../../models/Modals/Modals.types';
import styles from './../styles/Common.module.scss';

/**
 * No Group Component.
 */
const NoGroup = ({ reduxActions }) => {
  return (
    <div className={styles.noLinks}>
      <h4>No group found</h4>

      <div className={styles.info}>
        We cannot find group which you are looking for. Check given ID in Url or
      </div>

      <div className={styles.add}>
        <button
          onClick={() => {
            reduxActions.openModal({
              type: MODALS.GROUP_ADD,
            });
          }}
        >
          Add group
        </button>
      </div>
    </div>
  );
};

export default NoGroup;
