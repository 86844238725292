import * as SHARE from '../../models/Share/Share.constants';
import mapToArray from '../../globals/helpers/mapToArray';

export const prepareToPost = (data) => {
  if (data.is === 'public') {
    return {
      as: 'link',
      private: 0,
      ...mapToArray('tags', data.tags),
    };
  } else if (data.is === 'friend') {
    return {
      as: 'for_you',
      ...mapToArray('users', data.emails),
    };
  } else if (data.is === 'group') {
    return {
      as: 'group',
      ...mapToArray('tags', data.tags),
      ...mapToArray('groups', data.groups),
    };
  }
  return {
    as: 'link',
    private: 1,
    ...mapToArray('tags', data.tags),
  };
};

export const prepareToShare = (link, data) => {
  const save = {};
  let extra = {};

  const map = {
    [SHARE.TYPE_SAVE]: 'link',
    [SHARE.TYPE_WITH_USERS]: 'for_you',
    [SHARE.TYPE_TO_GROUPS]: 'group',
  };

  if (Object.hasOwnProperty.call(link, 'is')) {
    save.from = link.is.toLowerCase();
    save.link_id = link.id;
  } else {
    save.from = 'snap';
    save.hash = link.hash;
  }

  save.as = map[data.type];

  if (save.as === 'link') {
    save.private = data.is === 'private' ? 1 : 0;

    if (data.title.length > 0) {
      save.title = data.title;
    }
  }

  if (save.as === 'link' || save.as === 'group') {
    extra = {
      ...extra,
      ...mapToArray('tags', data.tags),
    };
  }

  if (save.as === 'group') {
    extra = {
      ...extra,
      ...mapToArray('groups', data.groups),
    };
  }

  if (save.as === 'for_you') {
    extra = {
      ...extra,
      ...mapToArray('users', data.emails),
    };
  }

  return {
    ...save,
    ...extra,
  };
};

export const mapTypeToIs = (props) => {
  if (props.type) {
    switch (props.type) {
      case SHARE.TYPE_WITH_USERS:
        return 'friend';
      case SHARE.TYPE_TO_GROUPS:
        return 'group';
      default:
        return 'private';
    }
  }

  if (props.originSnap && props.originSnap.private === false) {
    return 'public';
  }

  return 'private';
};
