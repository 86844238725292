import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import settings from '../../config/settings';

/**
 * When timeout.
 *
 * @param routine
 * @param time
 */
const whenTimeout = (routine, time = null) =>
  of(routine.fulfill()).pipe(delay(time || settings.api.timeout));

export default whenTimeout;
