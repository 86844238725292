import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { playerClose } from '../../../models/App/App.actions';
import {
  changeRepeatMode,
  changeShuffleMode,
  clear,
  next,
  play,
  prev,
  remove,
  setDefaultPlaylist,
  setTag,
  toggleHeight,
} from '../../../models/Player/Player.actions';
import MainComponent from './Main.component';

/*
 * Connection
 */
const mapStateToProps = (state) => {
  const { defaultList, ...rest } = state.Player;

  return {
    ...rest,
    length: defaultList.length,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      changeRepeatMode,
      changeShuffleMode,
      clear,
      next,
      play,
      playerClose,
      prev,
      remove,
      setDefaultPlaylist,
      setTag,
      toggleHeight,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);
