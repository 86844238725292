import React, { Component } from 'react';
import styles from './Loader.module.scss';

/**
 * Loader Row Component.
 */
const LoaderRow = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.checkbox} />
      <div className={styles.avatar} />
      <div className={styles.info}>
        <div className={styles.userName}>
          <div style={{ width: 100 + Math.random() * 200 }} />
        </div>
        <div className={styles.date} />
      </div>
    </div>
  );
};

/**
 * Loader Component.
 */
class Loader extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <LoaderRow />
        <LoaderRow />
        <LoaderRow />
      </div>
    );
  }
}

export default Loader;
