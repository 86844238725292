import React from 'react';
import cn from 'classnames';
import settings from '../../../config/settings';
import styles from './../PlayerInfo/PlayerInfo.module.scss';

/**
 * Selected Component.
 */
const Selected = ({ selected, handleClear, reduxActions }) => {
  return (
    <div className={cn(styles.info, styles.selected)}>
      <span className={styles.text}>
        You have{' '}
        <strong>
          {selected.length}/{settings.howMany.waitings}
        </strong>{' '}
        selected.
      </span>
      <button
        onClick={(event) => {
          reduxActions.remove(event, true, {
            ids: selected,
          });
        }}
      >
        Remove
      </button>
      <button className={styles.clear} onClick={handleClear}>
        Clear
      </button>
    </div>
  );
};

export default Selected;
