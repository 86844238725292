import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Account from '../../../models/Account/Account.routines';
import { bindRoutines } from '../../../abstracts/Routine';
import ConfirmComponent from './Confirm.component';

/**
 * Confirm Container.
 */
class Confirm extends Component {
  componentDidMount() {
    const { token } = this.props.match && this.props.match.params;

    if (token) {
      setTimeout(() => {
        this.props.reduxActions.confirm({ token });
      }, 0);
    }
  }

  render() {
    switch (this.props.window) {
      case 'success':
        return <ConfirmComponent success />;

      case 'failure':
        return <ConfirmComponent success={false} errors={this.props.errors} />;

      default:
        return null;
    }
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindRoutines(
    {
      confirm: Account.confirm.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  window: state.Account.confirm.window,
  errors: state.Account.confirm.errors,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Confirm)
);
