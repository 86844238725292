/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import cn from 'classnames';
import Link from '../Link';
import Loader from '../Loader';
import End from '../End';
import When from '../When';
import PlayerInfo from '../PlayerInfo';
import More from '../More';
import NoLinks from '../../noItems/NoLinks';
import FirstTime from '../../template/FirstTime';
import NoGroup from '../../noItems/NoGroup';
import Selected from '../Selected';
import Author from '../Author';
import Group from '../Group';
import Tags from '../../Tags';
import styles from './Links.module.scss';
import Upgrade from '../Upgrade';

/**
 * Links Component.
 */
const Links = ({
  added,
  displayOptions = {},
  endpoint,
  errors,
  filtersLength,
  firstTime,
  followerEmail,
  followerError,
  group,
  groupAdded,
  handleChangeSelection,
  handleClear,
  handleMarkAsSeen,
  handleSelect,
  handleSetScrollRef,
  handleSetSelectedRef,
  links,
  loading,
  refresh,
  selected,
  selectedLinks,
  showMore,
}) => {
  let videos = 0;

  links.forEach((link) => {
    if (link.type === 'video') {
      videos += 1;
    }
  });

  const showHello = endpoint === 'saved' && firstTime && filtersLength < 1;

  if (endpoint === 'waitings') {
    displayOptions.showCheckbox = true;
  }

  const hideLinks = endpoint === 'social' && followerEmail && followerError;

  return (
    <Fragment>
      <div
        className={cn(styles.linksWrapper, 'T', videos > 0 && styles.withVideos)}
        ref={handleSetScrollRef}
      >
        {endpoint === 'social' && <Author email={followerEmail} error={followerError} />}

        {endpoint === 'grouped' && <Group />}

        {endpoint === 'saved' && <Tags />}

        {links.length < 1 && !loading && showHello && added && <FirstTime />}

        {links.length < 1 && !loading && !showHello && added && !showMore && !hideLinks && (
          <NoLinks errors={errors} endpoint={endpoint} />
        )}

        {endpoint === 'grouped' && !loading && groupAdded && !group && <NoGroup />}

        {links.length > 0 && (
          <ul className={styles.links}>
            {links.map((link, index) => {
              return (
                <Fragment>
                  <li key={index}>
                    <When
                      now={link.created_at}
                      last={index > 0 ? links[index - 1].created_at : null}
                      index={index}
                      refresh={refresh}
                    />
                    <button
                      className={styles.linkWrapper}
                      onClick={() => {
                        handleSelect(link.id);
                      }}
                      ref={(ref) => {
                        if (link.id === selected) {
                          handleSetSelectedRef(ref);
                        }
                      }}
                    >
                      <Link
                        key={link.id}
                        data={link}
                        selected={selected}
                        displayOptions={displayOptions}
                        handleMarkAsSeen={handleMarkAsSeen}
                        handleChangeSelection={handleChangeSelection}
                        checked={
                          selectedLinks.findIndex((selectedId) => selectedId === link.id) >= 0
                        }
                      />
                    </button>
                  </li>
                  {endpoint === 'waitings' && index === 16 && (
                    <li>
                      <Upgrade />
                    </li>
                  )}
                </Fragment>
              );
            })}
          </ul>
        )}

        {loading && <Loader one={links.length > 0} />}

        {!loading && showMore && <More endpoint={endpoint} />}

        {!loading && !showMore && links.length > 20 && <End />}
      </div>

      {endpoint === 'saved' && videos > 0 && <PlayerInfo videos={videos} />}

      {selectedLinks.length > 0 && <Selected selected={selectedLinks} handleClear={handleClear} />}
    </Fragment>
  );
};

export default Links;
