/* eslint-disable no-restricted-globals */
import withValue from '../helpers/withValue';
import queryString from 'query-string';

const mapLocationToObject = (location, withReload = false) => {
  const query = location.search ? queryString.parse(location.search) : {};

  let page = parseInt(location.pathname.replace(/[^\d]+/i, ''), 10);
  page = isNaN(page) ? null : page;

  const obj = {
    ...query,
    page,
    withReload,
  };

  return withValue(obj);
};

export default mapLocationToObject;
