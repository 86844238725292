import React from 'react';
import cn from 'classnames';
import { Modal, Title, Body } from '../../Modal';
import styles from '../../Modal/Modal.module.scss';
import viewStyles from '../../../account/View/View.module.scss';

const PADDING_X = 100;
const PADDING_Y = 30;

/**
 * Confirm Component.
 */
const Confirm = ({ title, click, sizes, handleCloseModal }) => {
  const cssStyles = {};

  if (click) {
    cssStyles.top = Math.min(Math.max(PADDING_Y, click.y), sizes.realHeight - PADDING_Y);
    cssStyles.left = Math.min(Math.max(PADDING_X, click.x), sizes.realWidth - PADDING_X);
  }

  return (
    <Modal>
      <Title>Confirm action</Title>

      <Body>
        <div className={viewStyles.form}>
          <div className={styles.paragraph}>{title}</div>

          <div
            className={cn(styles.controls, cssStyles.top && styles.fixedControls)}
            style={cssStyles}
          >
            <button className={styles.success} onClick={() => handleCloseModal(true)}>
              Yes
            </button>
            <button className={styles.button} onClick={() => handleCloseModal(false)}>
              No
            </button>
          </div>
        </div>
      </Body>
    </Modal>
  );
};

export default Confirm;
