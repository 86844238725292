import React, { Component } from 'react';
import randomWidth from '../../../globals/helpers/randomWidth';
import styles from './Loading.module.scss';

const Comment = () => (
  <div className={styles.comment}>
    <div className={styles.left}>
      <div className={styles.avatar} />
    </div>
    <div className={styles.right}>
      <div className={styles.row} style={randomWidth(30, 70)} />
      <div className={styles.row} style={randomWidth(50, 90)} />
      <div className={styles.row} style={randomWidth(15, 50)} />
    </div>
  </div>
);

class Loading extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className={styles.main}>
        <Comment />
        <Comment />
        <Comment />
      </div>
    );
  }
}

export default Loading;
