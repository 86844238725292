import React from 'react';
import { get, prepare } from '../../../abstracts/Sections';
import cn from 'classnames';
import TemplateMenu, { Button, Content } from '../../template/Menu';
import FiltersList from '../../filters/List';
import styles from './Bar.module.scss';

/*
 * Export
 */
export const { Menu, Filters } = prepare('Menu', 'Filters');

const Empty = ({ name }) => (
  <div className={styles.bar}>
    <div className={styles.info}>
      <div className={cn(styles.icon, styles.greyIcon)}>
        <span className="icomoon icon-filter_list" />
      </div>
      <div className={styles.name}>You are in {name}</div>
    </div>
  </div>
);

/**
 * Bar Component.
 */
const Bar = ({ children, showPlaceholder, name }) => {
  const { MenuSection, FiltersSection } = get(children, Menu, Filters);

  const blue = name === 'Followers' || name === 'Groups';

  return (
    <div className={styles.wrapper}>
      {MenuSection}

      <div className={styles.filters}>
        {FiltersSection && (
          <TemplateMenu close={false} withoutLinks>
            <Button className={cn(styles.bar, styles.withPointer)}>
              <div className={styles.info}>
                <div className={styles.icon}>
                  <span className="icomoon icon-filter_list" />
                </div>
                {showPlaceholder && (
                  <div className={styles.name}>
                    Start filtering in{' '}
                    <span className={cn(!blue && styles.pink, blue && styles.blue)}>{name}</span>
                  </div>
                )}
              </div>
              <div className={styles.data}>
                <FiltersList />
              </div>
            </Button>
            <Content className={cn(styles.menu, 'T')}>{FiltersSection}</Content>
          </TemplateMenu>
        )}
      </div>
    </div>
  );
};

// {!FiltersSection && <Empty name={name} />}

export default Bar;
