import React, { Component } from 'react';
import { connect } from 'react-redux';
import settings from '../../config/settings';
import { withRouter } from 'react-router-dom';
import { bindRoutines } from '../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import { getDetails } from '../../models/User/User.routines';
import { setAuthToken } from '../../globals/helpers/headers';

/**
 * Auto Auth Container.
 */
class AutoAuth extends Component {
  constructor(props) {
    super(props);

    /* global localStorage */
    const token = localStorage.getItem(settings.auth.tokenName);

    if (token) {
      // Prepare headers object
      setAuthToken(token);
    }

    this.state = {
      loading: !!token,
      token,
    };
  }

  componentDidMount() {
    if (this.state.token) {
      setTimeout(() => {
        this.props.reduxActions.checkToken();
      }, 0);
    }
  }

  componentWillReceiveProps(props) {
    // logged
    if (props.logged !== this.props.logged && props.logged) {
      this.setState({
        loading: false,
      });
    } else if (props.called !== this.props.called && props.called) {
      this.setState({
        loading: false,
      });
    } else if (props.logout !== this.props.logout && props.logout) {
      this.setState(
        {
          loading: false,
        },
        () => {
          this.props.reduxActions.logout();
        }
      );
    }
  }

  shouldComponentUpdate(props, state) {
    return props !== this.props || state !== this.state;
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return React.cloneElement(this.props.children, {
      isAuthenticated: this.props.logged,
    });
  }
}

/*
 * Connection
 */
const mapStateToProps = (state) => ({
  logged: state.User.logged,
  called: state.User.called,
  logout: state.User.logout,
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        checkToken: getDetails.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        /*
         * Redux action
         */
        logout: () => ({ type: '@@INIT' }),
      },
      dispatch
    ),
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AutoAuth)
);
