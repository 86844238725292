import React from 'react';
import cn from 'classnames';
import Loader from '../TagsLoader';
import styles from '../styles/Common.module.scss';

/**
 * Groups Component.
 */
const Groups = ({ loading, groups, usedGroups, added, handleChangeGroup, isGroupSelected }) => (
  <div className={styles.groups}>
    {added && groups.length > 0 && (
      <div className={styles.selectedInfo}>
        You have selected <strong>{usedGroups.length}</strong>/5 groups.
      </div>
    )}
    <ul>
      {groups.map((group) => (
        <li key={group.id}>
          <button
            className={cn({
              [styles.selected]: isGroupSelected(group.id),
            })}
            onClick={() => {
              handleChangeGroup(group.id);
            }}
          >
            {group.name}
          </button>
        </li>
      ))}
    </ul>
    <Loader loading={loading} />
    {added && groups.length < 1 && (
      <div className={styles.noGroup}>You {"don't"} have any group.</div>
    )}
  </div>
);

export default Groups;
