/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import config from '../../config/config';
import * as SpeedDial from './SpeedDial.routines';

export const getSpeedDial = epic(
  SpeedDial.getSpeedDial,
  `${config.apiHost}speeddial`,
  false,
  'SpeedDial'
);
