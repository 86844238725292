const gravatar = (hash, size = undefined) => {
  let url = `https://www.gravatar.com/avatar/${hash}?d=identicon`;

  if (size) {
    url += `&size=${size}`;
  }

  return url;
};

export default gravatar;
