import React from 'react';
import { Link } from 'react-router-dom';
import View, { Body, Menu } from '../../../modules/account/View';
import FieldError from '../../../modules/errors/Field';
import ApiError from '../../../modules/errors/Api';
import { formClassName } from '../../../abstracts/Form/Utils';
import cn from 'classnames';
import viewStyles from '../../../modules/account/View/View.module.scss';
import styles from './../styles/Common.module.scss';

/**
 * Email Component.
 */
const Email = ({ handlers, fields: { email }, errors, loading }) => {
  return (
    <View>
      <Menu>
        <li>
          <Link to="/">Log in</Link>
        </li>
      </Menu>
      <Body>
        <div className={viewStyles.form}>
          <h1>Get your free account now</h1>

          <div className={styles.description}>
            This is <span>free</span> and always be. Use for your pleasure!
          </div>

          {/*
                      <div className={styles.other}>
            <div className={styles.facebook}>Connect with Facebook</div>
          </div>
             */}

          <form onSubmit={handlers.submit}>
            <div className={viewStyles.form}>
              <div className={formClassName(email, errors, viewStyles)}>
                <input
                  type="text"
                  className={viewStyles.input}
                  placeholder="Email"
                  autoComplete="off"
                  name={email.name}
                  onChange={handlers.change}
                  value={email.value}
                />
                <FieldError
                  data={email}
                  apiData={errors}
                  names="Email address"
                  className={viewStyles.error}
                />
              </div>

              <div className={cn(viewStyles.row, viewStyles.lastRow)}>
                <button onClick={handlers.submit} className={viewStyles.button} disabled={loading}>
                  Get started
                </button>
                <ApiError data={errors} className={viewStyles.error} />
              </div>
            </div>
          </form>

          <div className={styles.singUp}>
            Already have an account? <Link to="/">Login now!</Link>
          </div>
        </div>
      </Body>
    </View>
  );
};

export default Email;
