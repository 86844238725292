import React from 'react';
import styles from './More.module.scss';

/**
 * More Component.
 */
const More = ({ handleClick }) => {
  return (
    <div className={styles.more}>
      <button onClick={handleClick}>Load more</button>
    </div>
  );
};

export default More;
