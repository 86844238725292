import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindRoutines } from '../../../../abstracts/Routine';
import { bindActionCreators } from 'redux';
import * as SpeedDialRoutines from '../../../../models/SpeedDial/SpeedDial.routines';
import { closeModal } from '../../../../models/Modals/Modals.actions';
import SpeedDialComponent from './SpeedDial.component';

/**
 * SpeedDial Component.
 */
class SpeedDial extends Component {
  componentDidMount() {
    this.props.reduxActions.getSpeedDial();
  }

  render() {
    return (
      <SpeedDialComponent
        links={this.props.links}
        added={this.props.added}
        handleCloseModal={this.props.reduxActions.closeModal}
      />
    );
  }
}

/*
 * Connection.
 */
const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    ...bindRoutines(
      {
        getSpeedDial: SpeedDialRoutines.getSpeedDial.trigger,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        closeModal,
      },
      dispatch
    ),
  },
});

const mapStateToProps = (state) => ({
  loading: state.SpeedDial.loading,
  links: state.SpeedDial.data,
  added: state.SpeedDial.added,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeedDial);
