import React, { Fragment } from 'react';
import gravatar from '../../../globals/helpers/gravatar';
import styles from './../RightBox/RightBox.module.scss';

/**
 * User Component.
 */
const User = ({ user, notifications }) => {
  return (
    <Fragment>
      <div className={styles.avatar}>
        {notifications !== undefined && notifications > 0 && (
          <div className={styles.noti}>{notifications}</div>
        )}
        {user.avatar && <img key={user.id} src={user.avatar} alt={user.full_name} />}
        {!user.avatar && user.email_hash && (
          <img key={user.id} src={gravatar(user.email_hash)} alt={user.full_name} />
        )}
      </div>
    </Fragment>
  );
};

export default User;
