import React from 'react';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';

/*
ASCENDING  - /// (up)
DESCENDING - \\\ (down)
*/

const reversed = {
  asc: 'desc',
  desc: 'asc',
};

/**
 * Sort Component.
 */
const Sort = ({ children, name, url, location }) => {
  let type = null;

  const parsed = queryString.parse(location.search);

  if (Object.hasOwnProperty.call(parsed, 'sortKey')) {
    const sortKey = parsed.sortKey;

    if (sortKey === name) {
      type =
        Object.hasOwnProperty.call(parsed, 'sortType') && parsed.sortType === 'desc'
          ? 'desc'
          : 'asc';
    }
  }

  const obj = {
    sortKey: name,
    sortType: type ? reversed[type] : 'desc',
  };

  return (
    <Link to={`${url}?${queryString.stringify(obj)}`} className={type}>
      {children}
    </Link>
  );
};

export default withRouter(Sort);
