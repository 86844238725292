import React from 'react';
import moment from 'moment';
import styles from './Item.module.scss';

const Item = ({ data }) => {
  const time = moment.utc(data.created_at).local();

  return (
    <div className={styles.thanks}>
      <div className={styles.header}>
        User <strong>@{data.user.full_name}</strong> said thank you &bull; {time.fromNow()}:
      </div>
      <div className={styles.details}>
        <div className={styles.title}>{data.title}</div>
        {data.image && (
          <div className={styles.image}>
            <img src={data.image.url} alt={data.title} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
