/* eslint-disable import/prefer-default-export */
import epic from '../../abstracts/Epic';
import config from '../../config/config';
import { ofType } from 'redux-observable';
import { filter, first, map } from 'rxjs/operators';
import { getDetails } from '../User/User.routines';
import * as Tags from './Tags.routines';

export const getTags = epic(Tags.getTags, `${config.apiHost}tags`, false, 'Tags');

export const loadTagsOnStart = (action$, state$) => {
  const loading = !state$.value.Tags.loading;

  return action$.pipe(
    ofType(getDetails.SUCCESS),
    filter(() => loading),
    first(),
    map(() => {
      return Tags.getTags.trigger();
    })
  );
};
